import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import TimePicker from "react-bootstrap-time-picker";
import { Switch } from "antd";
import { setData, setForm } from "../../../redux/slices/StaffFormsSlices";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import SelectComponent from "../../SharedComponents/SelectComponent";
import { requiredFieldValidation } from "../../../helperFunctions/validation";
import { SESSION_CURRENT_STATE } from "./PatientDetails";
import { convertToReverseDefaultDate } from "../../../helperFunctions/utils";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateInput from "../../SharedComponents/DateInput";
import AsyncSelect from "../../SharedComponents/AsyncSelect";
import {
  loadOptionPatients,
  loadSelectedPatient,
} from "../../../apis/patients";
import moment from "moment";
import { getEditBy } from "../../../apis/appointment";
import { setFormData } from "../../../redux/slices/commonSlice";

export default function AddEditSession() {
  const dispatch = useDispatch();
  const location = useLocation();
  const formDataChangedRef = useRef();
  const { state } = location;

  const pathname = location.pathname;
  const { formData } = useSelector((state) => state.commonData);
  const { sessionForm } = useSelector((state) => state.staffForm);
  // console.log(
  // 	"🚀 ~ file: AddEditSession.jsx:25 ~ AddEditSession ~ sessionForm",
  // 	sessionForm,
  // );
  const [localState, setLocalStates] = useState({
    staff: sessionForm.staff || [],
    consents: sessionForm.consents || [],
  });
  const [showED, setShowED] = useState(false);
  const handleClickAway1 = () => {
    setShowED(false);
  };
  const [tags, setTags] = useState([]);
  const [disabledRegister, setDisabledRegister] = useState(false);
  const [holidayDates, setHolidayDates] = useState([]);
  const [options, setOptions] = useState({
    appointments: [],
    locations: [],
    rooms: [],
    branches: [],
    consents: [],
    staff: [],
    statuses: [
      {
        label: "Pending",
        value: "Pending",
      },
      {
        label: "Completed",
        value: "Completed",
      },

      {
        label: "Cancelled",
        value: "cancelled",
      },
    ],
  });
  const [withoutAppt, setWithoutAppt] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!state?.valueObj) {
      formDataChangedRef.current = {
        ...sessionForm,
        staff: localState.staff,
      };
    }
  }, [sessionForm, localState.staff]);

  useEffect(() => {
    return () => {
      if (!state?.valueObj && formDataChangedRef.current) {
        dispatch(
          setFormData({
            ...formData,
            [pathname]: formDataChangedRef.current,
          })
        );
      }
    };
  }, []);

  useEffect(() => {
    if (!state?.valueObj) {
      const reduxData = formData?.[pathname];
      if (reduxData && reduxData?.startTime) {
        dispatch(
          setData({
            parent: "sessionForm",
            value: {
              ...reduxData,
              formStatus: "Add",
            },
          })
        );
        setLocalStates({
          ...localState,
          staff: reduxData.staff,
        });
      }
    }
  }, []);

  const getTagsList = async () => {
    const { result, error } = await authorizedRequests({
      url: "tags/listOfTags",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "", "error");
      return;
    }

    const tempArr = result?.data?.map((o) => {
      return {
        value: o?._id,
        label: o.name,
      };
    });

    setTags(tempArr);
  };

  /////////////////functions\\\\\\\\\\\\\\\\\\\\
  const onFormChange = (child, value) => {
    // const { name, value } = e.target;
    dispatch(setForm({ parent: "sessionForm", child, value }));
  };
  const onLocalChange = (state, value) => {
    var tempArr = [...localState[state]];
    tempArr = value;

    setLocalStates({ ...localState, [state]: [...tempArr] });
  };
  // const onObjChange = (state, name, value, index) => {
  // 	var tempObj = [...localState[state]][index];
  // 	tempObj = { ...tempObj, [name]: value };
  // 	onLocalChange(state, tempObj, index);
  // };

  const submitForm = async (toBill) => {
    const formObj = {
      ...sessionForm,
      status: "Pending",
      staff: localState.staff.filter((o) => o),
      date: convertToReverseDefaultDate(sessionForm.date),
      // consents: localState.consents.filter((o) => o),
    };

    delete formObj.formStatus;
    // delete formObj.endTime;
    delete formObj.room;

    // if (formObj.startTime == formObj.endTime) {
    // 	toastify("Time in can't be equal to Time out", "error");
    // 	return;
    // }
    // if (formObj.consents.length < 1) {
    // 	toastify("Consents are missing", "error");
    // 	return;
    // }
    if (formObj.staff.length < 1) {
      toastify("Staff are missing", "error");
      setDisabledRegister(false);
      return;
    }

    const validation = requiredFieldValidation(
      {
        name: "Appointment",
        value: formObj.appointment || formObj.patientID,
      },
      {
        name: "Location",
        value: formObj.location,
      },
      // {
      // 	name: "Room",
      // 	value: formObj.room,
      // },
      {
        name: "Time In",
        value: formObj.startTime,
      },
      // {
      // 	name: "Time out",
      // 	value: formObj.endTime,
      // },
      // {
      // 	name: "Status",
      // 	value: formObj.status,
      // },
      withoutAppt
      ? {
          name: "Main Treatment Type",
          value: formObj.tag,
        }
      : undefined,
    );

    console.log("debug", validation)

    if (!validation.status) {
      toastify(validation.message, "error");
      setDisabledRegister(false);
      return;
    }

    if (sessionForm.dataId) {
      const { result, error } = await authorizedRequests({
        url: "session/updateSession",
        method: "post",
        data: formObj,
      });
      if (error) {
        setDisabledRegister(false);
        toastify(error.message ?? "unknown error occured", "error");
        return;
      }
      setDisabledRegister(false);
      formDataChangedRef.current = null;
      dispatch(
        setFormData({
          ...formData,
          [pathname]: null,
        })
      );

      localStorage.setItem(
        "session_current_state",
        SESSION_CURRENT_STATE.patientMedicalHistory
      );
      if (toBill) {
        navigate("/add-billing", {
          state: {
            session: location?.state?.session,
          },
        });
        return;
      }
      navigate(-1);

      return;
    }
    delete formObj.dataId;

    if (formObj.patientID) {
      formObj.tag = formObj.tag.map((o) => o.value);
    }
    const { result, error } = await authorizedRequests({
      url: formObj.patientID
        ? "session/createSessionWithoutAppt"
        : "session/createSession",
      method: "post",
      data: formObj,
    });
    if (error) {
      toastify(error.message ?? "unknown error occured", "error");
      setDisabledRegister(false);
      return;
    }

    setDisabledRegister(false);

    formDataChangedRef.current = null;
    dispatch(
      setFormData({
        ...formData,
        [pathname]: null,
      })
    );

    if (toBill) {
      navigate("/add-billing", {
        state: {
          session: result?.data,
        },
      });
      return;
    }

    dispatch(
      setData({
        parent: "sessionForm",
        value: {},
      })
    );
    // dispatch(
    //   setForm({
    //     parent: "sessionForm",
    //     child: "dataId",
    //     value: result.data._id,
    //   })
    // );
    localStorage.setItem(
      "session_current_state",
      SESSION_CURRENT_STATE.patientMedicalHistory
    );

    // dispatch(
    //   setForm({
    //     parent: "sessionForm",
    //     child: "consents",
    //     value: result.data.consents,
    //   })
    // );

    navigate(-1);
    toastify("Session added succesfully", "success");
    return;
  };

  const getAppointmentsList = async () => {
    const { result, error } = await authorizedRequests({
      url: "appointment/listOfTodayValidAppointments",
      method: "get",
      params: {
        date: location?.state?.stateDate,
      },
    });
    if (error) {
      toastify(error.message ?? "");
      return;
    }
    const tempArr = result?.data
      ?.filter((app) => app.status !== "Not Show")
      ?.map((o) => {
        let [startTimeHour, startTimeMinutes] = o.startTime.split(":");
        if (startTimeMinutes.length === 1) {
          if (startTimeMinutes[0] === "0") {
            startTimeMinutes = "00";
          }
        }

        const startTime = `${startTimeHour}:${startTimeMinutes}`;

        return {
          name:
            o?.patient?.patientProfile?.fullName +
            ` (${startTime + "-" + o.endTime})`,
          id: o?._id,
          value: o?._id,
          label:
            o?.patient?.patientProfile?.fullName +
            ` (${startTime + "-" + o.endTime})`,
          branchId: o.branch._id,
          date: o.date,
          startTime: startTime,
          endTime: o.endTime,
          staff: o.staff.map((k) => k._id),
          branchName: o.branch.name,
          locationId: o.location,
          location: o.branch.location.find((k) => {
            return k._id == o.location;
          }),
        };
      });
    setOptions((val) => {
      return { ...val, appointments: tempArr };
    });
  };

  const getConsentsList = async () => {
    const { result, error } = await authorizedRequests({
      url: "consents/listOfConsents",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "");
      return;
    }
    const tempArr = result.data.map((o) => {
      return {
        value: o._id,
        label: o.title,
      };
    });
    setOptions((val) => {
      return { ...val, consents: tempArr };
    });
  };
  const getStaffList = async () => {
    const { result, error } = await authorizedRequests({
      url: "users/staffList",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "");
      return;
    }

    const tempArr = result?.data?.map((o) => {
      return {
        value: o?._id,
        label: `${o.username} (${o.role})`,
      };
    });

    setOptions((val) => {
      return { ...val, staff: tempArr };
    });
  };

  const getListOfHolidays = async () => {
    const { result, error } = await authorizedRequests({
      url: "holidays/listOfHolidays",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "", "error");
      return;
    }
    const dates = result.data?.map((d) => {
      return new Date(d.eventDate);
    });
    setHolidayDates(dates);
  };

  const shouldDisabledDate = (date) => {
    if (!date?.$d) {
      return true;
    }
    if (!(date?.$d instanceof Date && !isNaN(date?.$d))) {
      return true;
    }
    const dates = holidayDates?.some(
      (disabledDate) =>
        disabledDate.getDate() === date?.$d?.getDate() &&
        disabledDate.getMonth() === date?.$d?.getMonth() &&
        disabledDate.getFullYear() === date?.$d?.getFullYear()
    );
    return dates;
  };

  // };
  ////////////////////hooks\\\\\\\\\\\\\\\\\\\\
  useEffect(() => {
    getAppointmentsList();
    getStaffList();
    getTagsList();
    getConsentsList();
    getListOfHolidays();
    getLocationsList();
  }, []);
  useEffect(() => {
    if (location?.state?.valueObj) {
      setLocalStates((val) => {
        return {
          ...localState,
          // consents: location.state.valueObj?.consents,
          staff: location.state.valueObj?.staff,
        };
      });
      dispatch(
        setData({
          parent: "sessionForm",
          value: location.state.valueObj,
        })
      );
    }
  }, [location?.state?.valueObj]);

  const onAppointmentChange = (id) => {
    var tempArr = options.appointments.find((o) => o.value == id);
    if (tempArr) {
      setOptions((val) => {
        return {
          ...val,
          locations: [
            {
              label: tempArr.location.name,
              value: tempArr.location._id,
            },
          ],
        };
      });
      onFormChange("location", tempArr.location._id);
      onFormChange("branch", tempArr.branchId);
      onFormChange("date", tempArr.date);
      onFormChange("startTime", tempArr.startTime);
      onFormChange("endTime", tempArr.endTime);
      setLocalStates((val) => {
        return { ...val, staff: tempArr.staff };
      });

      setOptions((val) => {
        return {
          ...val,
          rooms: tempArr.location.rooms.map((o) => {
            return {
              value: o,
              label: o,
            };
          }),
        };
      });
    }
  };
  useEffect(() => {
    if (sessionForm.appointment) {
      var tempArr = options.appointments.find(
        (o) => o.id == sessionForm.appointment
      );
      if (tempArr) {
        setOptions((val) => {
          return {
            ...val,
            locations: [
              {
                label: tempArr.location.name,
                value: tempArr.location._id,
              },
            ],
          };
        });
        onFormChange("location", tempArr.location._id);

        setOptions((val) => {
          return {
            ...val,
            rooms: tempArr.location.rooms.map((o) => {
              return {
                value: o,
                label: o,
              };
            }),
          };
        });
      }
    }
  }, [sessionForm.appointment, options.appointments]);

  const getLocationsList = async () => {
    const { result, error } = await authorizedRequests({
      url: "branch/listOfBranch",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "");
      return;
    }
    var tempArr = result.data.map((o) => {
      return {
        value: o._id,
        label: o.name,
        location: o.location,
      };
    });

    let tempLocations = [];
    if (tempArr.length > 0) {
      tempLocations = tempArr[0].location.map((o) => ({
        label: o.name,
        value: o._id,
      }));
    }

    setOptions((val) => {
      return { ...val, branches: tempArr, locations: tempLocations };
    });
  };

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: sessionForm.dataId,
      type: "Session",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);

  return (
    <>
      <div className="mainHeading px-4">
        <div>
          {" "}
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => {
                navigate(-1);
              }}
            ></i>
            {`${
              (sessionForm.formStatus ? sessionForm.formStatus : "Add") + " "
            }`}
            Session
          </h1>
          {sessionForm.formStatus == "Edit" && (
            <div style={{ marginLeft: "5px" }}>
              {" "}
              By {editLogs?.userName} At{" "}
              {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
            </div>
          )}
        </div>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div>
                <div className="d-flex w-100" style={{ marginBottom: "15px" }}>
                  <Form.Group className="col-md-12">
                    <Form.Label className="headings2 d-flex">
                      Without Appointment
                    </Form.Label>
                    <Switch
                      checked={withoutAppt}
                      onChange={(e) => {
                        setWithoutAppt(!withoutAppt);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="d-flex w-100">
                  {!withoutAppt ? (
                    <Form.Group className="col-md-5">
                      <Form.Label className="black-label-text required">
                        Appointments
                      </Form.Label>
                      <SelectComponent
                        singleSelect
                        searchEnabled
                        // isNullEnabled
                        // nullNotHidden
                        selectedValue={sessionForm.appointment}
                        options={options.appointments}
                        onChange={(e) => {
                          onAppointmentChange(e.value);
                          onFormChange("room", null);

                          onFormChange("appointment", e.value);
                        }}
                        name={"appointment"}
                      />
                    </Form.Group>
                  ) : (
                    <React.Fragment>
                      <Form.Group className="col-md-5">
                        <Form.Label className="black-label-text required">
                          Patient
                        </Form.Label>
                        <AsyncSelect
                          value={sessionForm.patientID}
                          setValue={(value) => {
                            onFormChange("room", null);
                            onFormChange("patientID", value._id);
                            if (options.branches.length > 0) {
                              onFormChange("branch", options.branches[0].value);
                            }
                          }}
                          loadOptions={(search, loadedOptions, { page }) =>
                            loadOptionPatients(search, loadedOptions, { page })
                          }
                        />
                      </Form.Group>
                    </React.Fragment>
                  )}
                  <Form.Group className="col-md-5 ms-3">
                    <Form.Label className="black-label-text required">
                      Staff
                    </Form.Label>
                    <div className="d-flex w-100">
                      <div
                        className="w-100 "
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <>
                          <SelectComponent
                            isNullEnabled
                            searchEnabled
                            options={options.staff}
                            onChange={(value) => {
                              onLocalChange("staff", value);
                            }}
                            array={localState.staff}
                            name={"staff"}
                          />
                        </>
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className="d-flex w-100">
                  <Form.Group className="col-md-5">
                    <Form.Label className="black-label-text required">
                      Location
                    </Form.Label>
                    <SelectComponent
                      singleSelect
                      searchEnabled
                      // nullDisabled
                      // isNullEnabled
                      // isDisabled
                      selectedValue={sessionForm?.location}
                      options={options?.locations}
                      onChange={(e) => {
                        onFormChange("location", e.value);
                      }}
                      name={"location"}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-5 ms-3">
                    <Form.Label className="black-label-text required">
                      Time In
                    </Form.Label>
                    <TimePicker
                      start="09:00"
                      end="21:00"
                      step={30}
                      selectedValue={sessionForm.startTime}
                      style={{ height: "47px" }}
                      className="modalTextField"
                      value={sessionForm.startTime}
                      onChange={(e) => {
                        let h = Math.floor(e / 3600);
                        h = h < 10 ? "0" + h : h;

                        let m = Math.floor((e % 3600) / 60);
                        m = m < 10 ? "0" + m : m;

                        onFormChange("startTime", `${h}:${m}`);
                      }}
                    />
                  </Form.Group>
                  {/* <Form.Group className="col-md-5 ms-3">
										<Form.Label className="black-label-text required">
											Rooms
										</Form.Label>
										<SelectComponent
											singleSelect
											searchEnabled
											// isDisabled
											selectedValue={sessionForm.room}
											options={options.rooms}
											onChange={(e) => {
												onFormChange("room", e.value);
											}}
											name={"room"}
										/>
									</Form.Group> */}
                </div>
                <div className="d-flex">
                  {/* <Form.Group className="col-md-5">
										<Form.Label className="black-label-text required">
											Time In
										</Form.Label>
										<TimePicker
											step={30}
											selectedValue={
												sessionForm.startTime
											}
											style={{ height: "47px" }}
											className="modalTextField"
											value={sessionForm.startTime}
											onChange={(e) => {
												let h = Math.floor(e / 3600);
												h = h < 10 ? "0" + h : h;

												let m = Math.floor(
													(e % 3600) / 60,
												);
												m = m < 10 ? "0" + m : m;

												onFormChange(
													"startTime",
													`${h}:${m}`,
												);
											}}
										/>
									</Form.Group> */}
                  {/* <Form.Group className="col-md-5 ms-3">
										<Form.Label className="black-label-text required">
											Time Out
										</Form.Label>
										<TimePicker
											step={30}
											selectedValue={sessionForm.endTime}
											className="modalTextField "
											style={{ height: "47px" }}
											value={sessionForm.endTime}
											onChange={(e) => {
												let h = Math.floor(e / 3600);
												h = h < 10 ? "0" + h : h;
												let m = Math.floor(
													(e % 3600) / 60,
												);
												m = m < 10 ? "0" + m : m;
												onFormChange(
													"endTime",
													`${h}:${m}`,
												);
											}}
										/>
									</Form.Group> */}
                </div>

                <div className="d-flex">
                  <Form.Group className="col-md-5">
                    <label>Date *</label>
                    <div className="date align-self-center white cursor-pointer mb-3">
                      <div>
                        <DateInput
                          // background={"#34556f"}
                          // calenderClass={"whiteCalender"}
                          inputClassName="themeInput"
                          value={sessionForm.date || new Date()}
                          onChange={(newValue) => {
                            onFormChange("date", newValue);
                            setShowED(false);
                          }}
                          shouldDisableDates={true}
                        />
                      </div>
                    </div>
                  </Form.Group>

                  {withoutAppt && (
                    <Form.Group className="col-md-5 ms-3">
                      <Form.Label className="black-label-text required">
                        Main Treatment Types
                      </Form.Label>
                      <SelectComponent
                        // isNullEnabled
                        singleSelect
                        searchEnabled
                        className=""
                        selectedValue={sessionForm.tag}
                        options={tags}
                        onChange={(value) => {
                          onFormChange("tag", [value]);
                        }}
                        name={"tag"}
                      />
                    </Form.Group>
                  )}
                  {/* <Form.Group className="col-md-5 ms-3">
										<Form.Label className="black-label-text required">
											Consents
										</Form.Label>
										<div className="d-flex w-100">
											<div
												className="w-100 "
												style={{
													flexDirection: "column",
												}}
											>
												<>
													<SelectComponent
														isNullEnabled
														searchEnabled
														options={
															options.consents
														}
														onChange={(value) => {
															onLocalChange(
																"consents",
																value,
															);
														}}
														array={
															localState.consents
														}
														name={"consents"}
													/>
												</>
											</div>
										</div>
									</Form.Group> */}
                </div>
                {/* <Form.Group className="col-md-5">
									<Form.Label className="black-label-text required">
										Status
									</Form.Label>
									<SelectComponent
										singleSelect
										searchEnabled
										options={options.statuses}
										onChange={(e) => {
											onFormChange("status", e.value);
										}}
										name={"status"}
										selectedValue={sessionForm.status}
									/>
								</Form.Group> */}
              </div>
            </div>
            <div className="mt-3 d-flex align-self-center">
              <Button
                style={{ width: "250px" }}
                className="addItem"
                disabled={disabledRegister}
                onClick={() => {
                  setDisabledRegister(true);
                  submitForm();
                }}
              >
                Save
              </Button>
              {/* <Button
								style={{ width: "250px" }}
								className="addItem ms-3"
								onClick={() => {
									submitForm(true);
								}}
							>
								Process to Bill
							</Button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
