import React, { useEffect } from "react";
import {
  getBillingByPatient,
  updateBilling,
  createTranserProduct,
  getTransferByPatientId,
} from "../../../apis/billing";
import { toastify } from "../../../helperFunctions/toastify";
import _ from "lodash";
import { useState } from "react";
import FilterComponent from "../../SharedComponents/FilterComponent";
import DataTable from "react-data-table-component";
import {
  Form,
  Button,
  Modal,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DateInput from "../../SharedComponents/DateInput";
import { customArrayDateSort } from "../../../helperFunctions/utils";
import { thousandAndDecimalFormat } from "../../../utils/common";
import { ExpandableIcon } from "../../../assets/images/index";
import AsyncSelect from "../../SharedComponents/AsyncSelect";
import { loadOptionPatients } from "../../../apis/patients";
import moment from "moment";
export default function PackageDropdown({ patientId }) {
  const numberFormatter = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  
  const [packagesdata, setPackagesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeIndex, setActiveIndex] = useState();
  const [itemToEdit, setItemToEdit] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isTransfer, setisTransfer] = useState(false);
  const [transferData, settransferData] = useState();

  const [expiry, setExpiry] = useState();
  const [refetch, setRefetch] = useState(false);

  const onExpiryChange = async () => {
    if (!itemToEdit.billingId) {
      toastify("billing doen't exists", "error");
      return;
    }
    if (!expiry) {
      toastify("Expiry date is missing", "error");
      return;
    }

    const selectedPackages = itemToEdit?.billingPackages?.find(
      (pack) => pack.package_id?._id === itemToEdit.id
    );
    if (selectedPackages) {
      const listExpiryDate = JSON.parse(
        JSON.stringify(selectedPackages.package_expiry)
      );
      const listFormattedDate = listExpiryDate.map((x) => {
        return new Date(x);
      });

      const dateExists = listFormattedDate.some(
        (date) => date.getTime() === expiry.getTime()
      );
      if (dateExists) {
        toastify(
          "extend date cannot be same with existing expiry date",
          "error"
        );
        return;
      }
    }

    const updatedPackages = itemToEdit?.billingPackages?.map((pack) => {
      if (pack?.package_id?._id == itemToEdit.id) {
        const currentExpiry =
          pack?.package_expiry?.length > 0 ? [...pack?.package_expiry] : [];
        currentExpiry.push(expiry);
        return {
          ...pack,
          package_expiry: currentExpiry,
        };
      }
      return pack;
    });

    const data = {
      patient: patientId,
      dataId: itemToEdit.billingId,
      package: updatedPackages,
    };
    const { result, error } = await updateBilling(data);
    if (error) {
      toastify("Failed to extend Package", "error");
      return;
    }
    toastify("Package extended successfuly", "success");
    setRefetch(!refetch);
    setItemToEdit();
    setExpiry();
    setOpenModal(false);
    setFilteredData([]);
    return;
  };

  const handleTransferSubmit = async () => {
    const { result, error } = await createTranserProduct(transferData);
    if (error) {
      toastify("Failed to transfer", "error");
      return;
    }
    toastify("Transfered Successfully", "success");
    handleTransferData();
    setisTransfer(!isTransfer);
    settransferData();
  };

  const getStatusPackage = (item, expiry, isConversion) => {
    if (isConversion) {
      return "Conversion";
    }

    const totalLimitProduct = item?.package_id?.products?.reduce(
      (acc, obj) => acc + obj.qty * item.package_qty,
      0
    );
    const totalLimitTreatment = item?.package_id?.treatments?.reduce(
      (acc, obj) => acc + obj.limit * item.package_qty,
      0
    );
    const totalUsedProduct = item?.linkedProducts?.reduce(
      (acc, obj) => acc + obj.used,
      0
    );
    const totalUsedTreatment = item?.linkedTreatments?.reduce(
      (acc, obj) => acc + obj.used,
      0
    );

    if (
      totalLimitProduct + totalLimitTreatment ===
      totalUsedProduct + totalUsedTreatment
    ) {
      return "Completed";
    } else if (
      new Date(expiry[expiry.length - 1]).getTime() < new Date().getTime()
    ) {
      return "Expired";
    } else {
      return "Ongoing";
    }
  };

  const [patientTransfer, setPatientTransfer] = useState([]);
  const [patientTransferLogs, setPatientTransferLogs] = useState([]);

  const handleTransferData = async () => {
    const { result, error } = await getTransferByPatientId({
      id: patientId,
    });
    if (error) {
      toastify(error.message, "error");
      return;
    }
    let transferPackage = result?.data?.transfer?.map((a) => {
      return Object.values(a?.packages);
    });

    transferPackage = transferPackage.flat();

    let mapTransferPackage = transferPackage?.map((a) => ({
      name: a?.packageName,
      date: new Date(a?.date).toLocaleDateString()?? "", 
      expiredDate: a?.expiredDate ?? "",
      effectiveDate: a?.effectiveDate ?? "",
      products: a?.products,
      isTransfer: true,
      packageQty: 1,
      statusPackage: "Transfer",
    }));

    const summedData = result?.data?.transferLogs?.reduce((acc, curr) => {
      const key = `${curr.packageId}-${curr.productId}-${curr.invoiceId}`;

      if (!acc[key]) {
        acc[key] = { ...curr }; // Initialize with the current object
      } else {
        acc[key].qty += curr.qty; // Sum the qty
      }

      return acc;
    }, {});

    // Convert the map back to an array
    const logsSum = Object.values(summedData);

    setPatientTransferLogs(logsSum);

    setPatientTransfer(mapTransferPackage ?? []);
  };

  const calculateBalancePrice = (totalPrice, balanceQty, totalQty) => {
    const balance_price = (totalPrice / (totalQty === 0 ? 1 : totalQty)) * (balanceQty * -1);
    return numberFormatter.format(Math.abs(balance_price));
  }

  useEffect(() => {
    if (patientId) {
      (async () => {
        const { result, error } = await getBillingByPatient(patientId);

        if (error) {
          toastify(error.message, "error");
          return;
        }
        handleTransferData();
        let tempArr = result.map((pack) => {
          const loc = pack?.branch?.location?.find(
            (o) => o._id === pack?.location
          )?.name;

          let lastLinkedPayment = null;
          if (pack?.linkedPayments?.length > 0) {
            lastLinkedPayment =
              pack?.linkedPayments[pack?.linkedPayments?.length - 1];
          }

          const packageDetails = pack?.package
            ?.filter((p) => p.isLinked !== true)
            ?.map((item) => {
              const expiry = customArrayDateSort(
                item?.package_expiry.length > 0
                  ? [
                      // item?.package_id?.expiredDate,
                      ...item?.package_expiry,
                    ]
                  : [item?.package_id?.expiredDate]
              );
              const getMonthDif = (date2, date1) => {
                const dt_date1 = new Date(date1);
                const dt_date2 = new Date(date2);

                //Get the Timestamp
                const date1_time_stamp = dt_date1.getTime();
                const date2_time_stamp = dt_date2.getTime();

                let calc;

                //Check which timestamp is greater
                if (date1_time_stamp > date2_time_stamp) {
                  calc = new Date(date1_time_stamp - date2_time_stamp);
                } else {
                  calc = new Date(date2_time_stamp - date1_time_stamp);
                }
                //Retrieve the date, month and year
                const calcFormatTmp =
                  calc.getDate() +
                  "-" +
                  (calc.getMonth() + 1) +
                  "-" +
                  calc.getFullYear();
                //Convert to an array and store
                const calcFormat = calcFormatTmp.split("-");
                //Subtract each member of our array from the default date
                const days_passed = Number(Math.abs(calcFormat[0]) - 1);
                const months_passed = Number(Math.abs(calcFormat[1]) - 1);
                const years_passed = Number(Math.abs(calcFormat[2]) - 1970);

                //Set up custom text
                const yrsTxt = ["year", "years"];
                const mnthsTxt = ["month", "months"];
                const daysTxt = ["day", "days"];

                //Convert to days and sum together
                const total_days =
                  years_passed * 365 + months_passed * 30.417 + days_passed;

                //display result with custom text
                const result =
                  (years_passed == 1
                    ? years_passed + " " + yrsTxt[0] + " "
                    : years_passed > 1
                    ? years_passed + " " + yrsTxt[1] + " "
                    : "") +
                  (months_passed == 1
                    ? months_passed + " " + mnthsTxt[0]
                    : months_passed > 1
                    ? months_passed + " " + mnthsTxt[1] + " "
                    : "") +
                  (days_passed == 1
                    ? days_passed + " " + daysTxt[0]
                    : days_passed > 1
                    ? days_passed + " " + daysTxt[1]
                    : "");

                //return the result
                return result.trim();
              };

              let arrProduct = [];
              const packageProducts = item?.package_id?.products
                ? [...item?.package_id?.products]
                : [];
              packageProducts.map((packageProduct, pIndex) => {
                let productUsed = item?.products_used?.find(
                  (x) => x.id && x.id === packageProduct?.product?._id
                );
                if (!productUsed) {
                  productUsed = item?.products_used?.[pIndex];
                }
                if (lastLinkedPayment) {
                  if (lastLinkedPayment?.package?.length > 0) {
                    const linkedPackage = lastLinkedPayment?.package?.find(
                      (p) => p.package_id === item?.package_id?._id
                    );
                    productUsed = linkedPackage?.products_used?.find(
                      (x) => x.id && x.id === packageProduct?.product?._id
                    );
                    if (!productUsed) {
                      productUsed = linkedPackage?.products_used?.[pIndex];
                    }
                  }
                }

                arrProduct.push({
                  name: packageProduct.product?.name,
                  qty: packageProduct.qty,
                  unit_price: packageProduct?.price,
                  total_price: productUsed?.amount ? productUsed?.amount : packageProduct?.totalPrice,
                  used: productUsed?.used ? productUsed.used : 0,
                  converted: productUsed?.converted ? productUsed.converted : 0,
                  id: packageProduct.product?._id,
                  code: packageProduct.product?.code,
                });
              });

              let arrTreatment = [];
              const packageTreatments = item?.package_id?.treatments
                ? [...item?.package_id?.treatments]
                : [];
              packageTreatments.map((packageTreatment, tIndex) => {
                let treatmentUsed = item?.treatments_used?.find(
                  (x) => x.id && x.id === packageTreatment?.treatment?._id
                );
                if (!treatmentUsed) {
                  treatmentUsed = item?.treatments_used?.[tIndex];
                }
                if (lastLinkedPayment) {
                  if (lastLinkedPayment?.package?.length > 0) {
                    const linkedPackage = lastLinkedPayment?.package?.find(
                      (p) => p.package_id === item?.package_id?._id
                    );
                    treatmentUsed = linkedPackage?.treatments_used?.find(
                      (x) => x.id && x.id === packageTreatment?.treatment?._id
                    );
                    if (!treatmentUsed) {
                      treatmentUsed = linkedPackage?.treatments_used?.[tIndex];
                    }
                  }
                }

                arrTreatment.push({
                  name: packageTreatment?.name,
                  qty: packageTreatment.limit,
                  unit_price: packageTreatment.treatment?.price,
                  total_price: treatmentUsed?.amount ? treatmentUsed?.amount : packageTreatment?.disc_price,
                  used: treatmentUsed ? treatmentUsed.used : 0,
                  converted: treatmentUsed ? treatmentUsed.converted : 0,
                  code: packageTreatment.code,
                  id: packageTreatment.treatment?._id,
                });
              });

              item.linkedProducts = [...arrProduct];
              item.linkedTreatments = [...arrTreatment];

              let packageQty = item?.package_qty;
              let originalPackageQty = item?.package_qty;
              let packagePrice = item?.package_price;
              let packageConversionQty = 0;
              let isConversion = false;

              if (pack?.isConversion) {
                const conversionPayment = result.find(p => p._id === pack.conversionId);
                if (conversionPayment) {
                  const conversionPackage = conversionPayment.package.find(p => p.package_id?._id === item?.package_id?._id);
                  if (conversionPackage) {
                    isConversion = true;
                    arrProduct.map((product, pIndex) => {
                      let conversionProduct = conversionPackage.products_used.find(p => p.id === product.id);
                      if (!conversionProduct) {
                        conversionProduct = conversionPackage.products_used?.[pIndex];
                      }
                      if (conversionProduct) {
                        product.used  = Number(conversionProduct.used);
                        product.converted  = Number(conversionProduct.converted);
                        // product.total_price = Math.abs(Number(product.total_price)) + Math.abs(Number(conversionProduct.amount));
                        product.conversionAmount = conversionProduct.amount;
                        product.isConversion = true;
                      }
                    }); 
  
                    arrTreatment.map((treatment, tIndex) => {
                      let conversionProduct = conversionPackage.treatments_used.find(p => p.id === treatment.id);
                      if (!conversionProduct) {
                        conversionProduct = conversionPackage.treatments_used?.[tIndex];
                      }
                      if (conversionProduct) {
                        treatment.used  = Number(conversionProduct.used);
                        treatment.converted  = Number(conversionProduct.converted);
                        // treatment.total_price = Number(treatment.total_price) + Number(conversionProduct.amount);
                        treatment.conversionAmount = conversionProduct.amount;
                        treatment.isConversion = true;
                      }
                    });
  
                    packageQty = packageQty + conversionPackage.package_qty;
                    packageConversionQty = Math.abs(conversionPackage.package_qty);
                    packagePrice = packagePrice + conversionPackage.package_price;

                    conversionPayment.package = conversionPayment.package.filter(p => p._id !== conversionPackage._id);
                  }
                }
              }

              return {
                id: item?.package_id?._id,
                billingId: pack?._id,
                billingPackages: pack?.package,
                name: item?.promoName
                  ? `${item?.name ? item?.name : item?.package_id?.name} (${
                      item?.promoName
                    })`
                  : `${item?.name ? item?.name : item?.package_id?.name}`,
                promoDiscountType: item?.promoDiscountType,
                promoDiscountValue: item?.promoDiscountValue,
                discountType: item?.package_discountType,
                discount: item?.package_discount,
                products: arrProduct,
                packageQty: packageQty,
                originalPackageQty: originalPackageQty,
                packageConversionQty: packageConversionQty,
                packageRemark: item?.package_remark,
                treatments: arrTreatment,
                date: new Date(pack?.date).toLocaleDateString(),
                effectiveDate: item?.package_effective
                  ? new Date(item?.package_effective).toLocaleDateString()
                  : new Date(
                      item?.package_id?.effectiveDate
                    ).toLocaleDateString(),
                expiryDuration: getMonthDif(
                  new Date(item?.package_id?.effectiveDate),
                  new Date(expiry[expiry.length - 1])
                ),
                totalItemPrice: item?.package_id?.totalCost,
                totalSellingPrice: packagePrice,
                code: item?.package_id?.code,
                location: loc,
                purchaseDate: new Date(pack?.created_at).toLocaleDateString(),
                allExpiry: expiry,
                expiredDate: (
                  <>
                    {expiry.length > 0 && (
                      <div>{new Date(expiry[0]).toLocaleDateString()}</div>
                    )}
                  </>
                ),
                expiry:
                  expiry.length > 0 && new Date(expiry[0]).toLocaleDateString(),
                status:
                  new Date(expiry[expiry.length - 1]).getTime() <
                  new Date().getTime()
                    ? false
                    : true,
                statusPackage: getStatusPackage(item, expiry, isConversion),
              };
            });
          return [...packageDetails];
        });

        tempArr = _.flattenDeep(tempArr);

        tempArr = tempArr.map((o, idx) => {
          const otherPurchases = tempArr.filter(
            (k, i) => k.id == o.id && idx !== i
          );
          return {
            ...o,
            otherPurchases,
          };
        });

        setPackagesData([...tempArr]);
      })();
    }
  }, [patientId, refetch]);

  return (
    <>
      <div className="p-0">
        <div className="grey mb-2 ps-3" style={{ fontSize: "16px" }}>
          Total Count:{packagesdata?.length + patientTransfer?.length}
        </div>
        <div className="billing-data-table">
          <Table striped>
            <thead
              style={{
                background: "#34556F",
                color: "white",
              }}
            >
              <tr
                style={{
                  height: "45px",
                }}
                className="align-items-center
                "
              >
                <th className="wd-150 ">
                  <span className="me-2 ps-2">Name</span>
                  <FilterComponent
                    type="search"
                    array={packagesdata}
                    setArray={setFilteredData}
                    value={"name"}
                  />{" "}
                </th>
                <th className="wd-100">
                  <span className="me-2">Treatment Date</span>
                </th>
                <th className="wd-100">
                  <span>Effective Date</span>
                </th>
                {/* <th className="wd-100">Expiry Date</th> */}
                {/* <th className="wd-100">Total Item Price</th> */}
                <th className="wd-100">Qty</th>
                {/* <th className="wd-100">Promo</th>
                <th className="wd-100">Discount</th> */}
                <th className="wd-100">Amount</th>
                <th className="wd-150">Remark</th>
                <th className="wd-100">Status</th>
                <th className="wd-100">Action</th>
              </tr>
            </thead>
            <tbody>
              {(filteredData.length === 0
                ? [...packagesdata, ...patientTransfer]
                : [...filteredData, ...patientTransfer]
              )?.map((e, index) => {
                return (
                  <>
                    <tr
                      style={
                        e?.isTransfer == true
                          ? { backgroundColor: "#e9eafe" }
                          : {}
                      }
                    >
                      <td className="wd-150 ps-3">{e.name}</td>
                      <td className="wd-150 ps-3">{e.date ?? "-"}</td>
                      <td className="wd-150 ps-3">
                        {e?.effectiveDate} - {e?.expiredDate}
                      </td>

                      <td className="wd-100">{e.packageQty ?? "-"}</td>

                      <td className="wd-100">
                        {thousandAndDecimalFormat(e?.totalSellingPrice, true, 2)}
                      </td>
                      <td className="wd-150">{e.packageRemark ?? "-"}</td>
                      <td className="wd-100">
                        {e.statusPackage === "Completed" && (
                          <div
                            className="active-status"
                            style={{ maxWidth: "50px" }}
                          >
                            Completed
                          </div>
                        )}

                        {e.statusPackage === "Ongoing" && (
                          <div
                            className="ongoing-status"
                            style={{ maxWidth: "50px" }}
                          >
                            On Going
                          </div>
                        )}

                        {e.statusPackage === "Expired" && (
                          <div
                            className="inactive-status"
                            style={{ maxWidth: "50px" }}
                          >
                            Expired
                          </div>
                        )}

                        {e.statusPackage === "Conversion" && (
                          <div
                            className="conversion-status"
                            style={{ maxWidth: "50px" }}
                          >
                            Conversion
                          </div>
                        )}

                        {e.statusPackage === "Transfer" && (
                          <div
                            className="transfer-status"
                            style={{ maxWidth: "50px" }}
                          >
                            Transfer
                          </div>
                        )}
                      </td>
                      <td className="wd-100 ">
                        <div className="d-flex" style={{ alignItems: "center" }}>
                          <div className="cursor-pointer icon-width">
                            <img
                              src={ExpandableIcon}
                              alt="expand-icon"
                              style={{ height: "25px", marginRight: "10px" }}
                              className="expand-icon"
                              onClick={() => {
                                if (activeIndex === index) {
                                  setActiveIndex();
                                  return;
                                }
                                setActiveIndex(index);
                              }}
                            />
                          </div>
                          {e?.isTransfer == true ? (
                            ""
                          ) : (
                            <Button
                              className="addItem"
                              style={{
                                maxHeight: "35px",
                                width: "80px",
                                fontWeight: 400,
                                fontSize: "14px",
                              }}
                              onClick={() => {
                                setItemToEdit(e);
                                setOpenModal(true);
                                setExpiry(new Date(e?.allExpiry[0]));
                              }}
                            >
                              Extend
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                    {activeIndex === index && (
                      <React.Fragment>
                        {e.allExpiry?.map((expiry, expiryIndex) => {
                          return expiryIndex !== 0 ? (
                            <tr key={expiryIndex}>
                              <td className="wd-150 ps-3"></td>
                              <td className="wd-150 ps-3"></td>
                              <td className="wd-100">
                                {new Date(expiry).toLocaleDateString()}
                              </td>
                              <td className="wd-100"></td>
                              <td className="wd-100"></td>
                              <td className="wd-100"></td>
                              <td className="wd-150 pe-5"></td>
                              <td className="wd-100 "></td>
                            </tr>
                          ) : (
                            ""
                          );
                        })}
                        <tr>
                          <td
                            colSpan="12"
                            style={{
                              backgroundColor: "#f2f2f2",
                              padding: "20px",
                            }}
                          >
                            <Table>
                              <thead
                                style={{
                                  background: "#34556F",
                                  color: "white",
                                  width: "100%",
                                }}
                              >
                                <tr
                                  style={{
                                    height: "45px",
                                  }}
                                  className="align-items-center"
                                >
                                  <th className="wd-150">
                                    <span className="me-2 ps-2">Type</span>
                                  </th>
                                  {/* <th className="wd-150">
                                    Code
                                </th> */}
                                  <th className="wd-150">Name</th>
                                  <th className="wd-150">Quantity</th>
                                  <th className="wd-150">Used/Converted</th>
                                  <th className="wd-150">Balance</th>
                                  <th className="wd-150">Amount</th>
                                  {e?.isTransfer == true ? (
                                    ""
                                  ) : (
                                    <th className="wd-150">Action</th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {e.products?.length > 0 &&
                                  e?.products?.map((product, productIndex) => {
                                    const transferProduct =
                                      patientTransferLogs?.find(
                                        (t) =>
                                          t?.invoiceId == e?.billingId &&
                                          t?.packageId == e?.id &&
                                          t?.productId == product?.id
                                      );

                                    const transferQty = transferProduct?.qty;

                                    const finalQty = Number(product.qty) * Number(product.isConversion ? e.packageConversionQty : e.packageQty);
                                    const balance = finalQty -
                                      (transferQty ? transferQty : 0) -
                                      (product?.used ? product?.used : 0) - 
                                      (product?.converted ? product?.converted : 0);
                                    

                                    return (
                                      <tr key={productIndex}>
                                        <td>{product?.type ?? "Product"}</td>
                                        <td>{product.name} </td>
                                        <td>{finalQty} </td>

                                        <td>
                                          {
                                            `${product.used} / ${product.converted ? product.converted : 0}`
                                          }
                                        </td>
                                        <td>
                                          {balance}
                                          {transferQty && (
                                            <OverlayTrigger
                                              placement="bottom"
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${productIndex}`}
                                                >
                                                  Qty: {transferQty} is transferred
                                                  to other Patient
                                                </Tooltip>
                                              }
                                            >
                                              <i
                                                class="fa fa-info-circle"
                                                style={{
                                                  position: "relative",
                                                  left: "10px",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </OverlayTrigger>
                                          )}
                                        </td>
                                        <td>
                                        {`$${calculateBalancePrice(product.total_price, balance, e.originalPackageQty * product.qty)}`}
                                        {/* {`$${balance * Math.abs(product.unit_price ? product.unit_price : product.total_price)}`} */}
                                        </td>

                                        {e?.isTransfer == true ? (
                                          ""
                                        ) : (
                                          <td>
                                            <i
                                              class="fa fa-share cursor-pointer"
                                              aria-hidden="true"
                                              onClick={() => {
                                                setisTransfer(!isTransfer);
                                                settransferData({
                                                  product: product,
                                                  prevPatientId: patientId,
                                                  prevQuantity:
                                                    finalQty -
                                                    product?.used -
                                                    (transferQty ?? 0),
                                                  packages: {
                                                    packageId: e?.id,
                                                    packageName: e?.name,
                                                    effectiveDate:
                                                      e.effectiveDate,
                                                    expiredDate: e?.expiry ?? "",
                                                    date: new Date(),
                                                    products: {
                                                      id: product?.id,
                                                      name: product?.name,
                                                      code: product?.code,
                                                      qty:
                                                        finalQty -
                                                          product.used -
                                                          (transferQty ?? 0) >
                                                        0
                                                          ? 1
                                                          : 0,
                                                      type: "Product",
                                                    },
                                                  },
                                                  invoiceId: e?.billingId,
                                                });
                                              }}
                                            ></i>
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  })}
                                {e.treatments?.length > 0 &&
                                  e?.treatments?.map((product, productIndex) => {
                                    const transferQty =
                                      patientTransferLogs?.find(
                                        (t) =>
                                          t?.invoiceId == e?.billingId &&
                                          t?.packageId == e?.id &&
                                          t?.productId == product?.id
                                      )?.qty ?? 0;

                                    const finalQty = Number(product.qty) * Number(product.isConversion ? e.packageConversionQty : e.packageQty);
                                    const balance = finalQty -
                                      (transferQty ? transferQty : 0) -
                                      (product?.used ? product?.used : 0) - 
                                      (product?.converted ? product?.converted : 0);

                                    return (
                                      <tr key={productIndex}>
                                        <td>Treatment</td>
                                        <td>{product.name}</td>
                                        <td>{finalQty} </td>
                                        <td>
                                          {
                                            `${product.used} / ${product.converted ? product.converted : 0}`
                                          }
                                        </td>
                                        <td>
                                          {balance}
                                          {transferQty ? (
                                            <OverlayTrigger
                                              placement="bottom"
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${productIndex}`}
                                                >
                                                  Qty: {transferQty} is transferred
                                                  to other Patient
                                                </Tooltip>
                                              }
                                            >
                                              <i
                                                class="fa fa-info-circle"
                                                style={{
                                                  position: "relative",
                                                  left: "10px",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </OverlayTrigger>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td>
                                          {`$${calculateBalancePrice(product.total_price, balance, e.originalPackageQty * product.qty)}`}
                                          {/* ${balance * Math.abs(product?.unit_price ? product?.unit_price : product?.total_price)} */}
                                        </td>
                                        <td>
                                          <i
                                            class="fa fa-share cursor-pointer"
                                            aria-hidden="true"
                                            onClick={() => {
                                              setisTransfer(!isTransfer);
                                              settransferData({
                                                product: product,
                                                prevPatientId: patientId,
                                                prevQuantity:
                                                  finalQty -
                                                  product?.used -
                                                  (transferQty ?? 0),
                                                packages: {
                                                  packageId: e?.id,
                                                  packageName: e?.name,
                                                  effectiveDate: e?.effectiveDate,
                                                  expiredDate: e?.expiry ?? "",
                                                  date: new Date(),
                                                  products: {
                                                    id: product?.id,
                                                    name: product?.name,
                                                    code: product?.code,
                                                    qty:
                                                      finalQty -
                                                        product.used -
                                                        (transferQty ?? 0) >
                                                      0
                                                        ? 1
                                                        : 0,
                                                    type: "Product",
                                                  },
                                                },
                                                invoiceId: e?.billingId,
                                              });
                                            }}
                                          ></i>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </React.Fragment>
                    )}
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        centered
        show={openModal}
        onHide={() => {
          setOpenModal(!openModal);
        }}
      >
        <Modal.Header closeButton>
          <b>Extend Package Expiry Date</b>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span>
              <b> Expiry *</b>
            </span>
            <DateInput
              // label={"Visit Date:"}
              className="mt-3"
              value={expiry}
              placeholder="Expiry Date"
              onChange={(e) => {
                setExpiry(e);
              }}
            />
            <Button
              className="addItem"
              onClick={() => {
                onExpiryChange();
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={isTransfer}
        onHide={() => {
          setisTransfer(!isTransfer);
          settransferData();
        }}
      >
        <Modal.Header closeButton>
          <b>Transfer Package Item</b>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <span>
                <b>
                  {" "}
                  Transfer Item from Package:{" "}
                  <span>{transferData?.packages?.packageName}</span>
                </b>
              </span>
            </div>
            {transferData?.product?.code && (
              <span>
                <b>
                  {" "}
                  Code: <span>{transferData?.product?.code}</span>
                </b>
              </span>
            )}

            {transferData?.product?.name && (
              <span>
                <b>
                  {" "}
                  Name: <span>{transferData?.product?.name}</span>
                </b>
              </span>
            )}

            <span style={{ marginTop: "5px" }}>
              <b>
                {" "}
                Quantity:{" "}
                <input
                  type="number"
                  style={{
                    borderRadius: "5px",
                    width: "50px",
                    marginRight: "5px",
                  }}
                  value={transferData?.packages?.products?.qty ?? 1}
                  onChange={(e) => {
                    if (
                      e?.target?.value > 0 &&
                      e?.target?.value <= transferData?.prevQuantity
                    ) {
                      settransferData((prevData) => ({
                        ...prevData,
                        packages: {
                          ...prevData.packages,
                          products: {
                            ...prevData.packages.products,
                            qty: e.target.value,
                          },
                        },
                      }));
                    }
                  }}
                />
                (Remaining: {transferData?.prevQuantity})
              </b>
            </span>

            <React.Fragment>
              <Form.Group className="col-md-12 mb-2 mt-3">
                <Form.Label className="black-label-text required">
                  Select Patient
                </Form.Label>
                <AsyncSelect
                  value={transferData?.currentPatiendId ?? ""}
                  setValue={(value) => {
                    settransferData((prevData) => ({
                      ...prevData,
                      currentPatiendId: value?._id,
                    }));
                  }}
                  loadOptions={(search, loadedOptions, { page }) =>
                    loadOptionPatients(search, loadedOptions, { page })
                  }
                />
              </Form.Group>
            </React.Fragment>
            <Button
              className="addItem"
              onClick={() => {
                if (!transferData?.currentPatiendId) {
                  toastify("Please Select Patient", "error");
                } else if (transferData?.packages?.products?.qty === 0) {
                  toastify("Quantity can not be 0", "error");
                } else {
                  handleTransferSubmit();
                }
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
