import React, { useState, useEffect } from "react";
import { Accordion, Form, Modal } from "react-bootstrap";
import { PDFViewer } from "@react-pdf/renderer";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { getBillingByPatient, getBillings } from "../../../apis/billing";
import { getSetting } from "../../../apis/generalSetting";
import { toastify } from "../../../helperFunctions/toastify";
import InvoicePDF from "../../SharedComponents/InvoicePDF";
import Table from "react-bootstrap/Table";
import NoteRenderer from "../NoteTaking/NoteRenderer";
import { getMembershipCategories } from "../../../apis/membership";
import { getInvoicebyId, getInvoiceId } from "../../../helperFunctions/utils";
import { authorizedRequests } from "../../../axios/axios-config";
import { thousandAndDecimalFormat } from "../../../utils/common";

export default function TabBillingHistory() {
  const patientData = useSelector((state) => state.patientData.value);
  const [billings, setBillings] = useState([]);
  // const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const [activeIndex, setActiveIndex] = useState();
  const [activeChildIndex, setActiveChildIndex] = useState();
  const [setting, setSetting] = useState({});
  const [usePointVal, setUsePointVal] = useState(0);
  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedBilling, setSelectedBilling] = useState({});
  const [oriBillings, setOriBillings] = useState([]);
  // const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const calcOustandingAmount = (e, type) => {
    const remainingAmount = getLastRemainingAmount(e);
    if (e.linkedInvoice && e.linkedInvoice?.status === "Refund") {
      if (remainingAmount === 0) {
        return 0;
      }

      return (
        Number(remainingAmount === 0 ? e?.gstAmount : remainingAmount) + Number(getLastPaidAmount(e?.linkedInvoice) - (Number(e?.linkedInvoice?.outstandingPaid) || 0))
      )
    } else if (e.linkedInvoice && e.linkedInvoice?.status === "Conversion Top Up") {
      return (
        Number(remainingAmount === 0 ? e?.gstAmount : remainingAmount) + Number(e?.linkedInvoice?.gstAmount - (Number(getLastPaidAmount(e?.linkedInvoice)) || 0))
      )
    }

    const payedAmount = Number(e.gstAmount) - Number(getLastPaidAmount(e));
    return payedAmount ? payedAmount : 0;
  }

  const getStatusPayment = (o) => {
    const paidAmount =
      o?.paymentRecord && o?.paymentRecord?.length > 0
        ? o?.paymentRecord[o?.paymentRecord?.length - 1].payed
        : 0;
    const gstAmount = o?.gst;

    if (o?.isRefund) {
      if (o?.gst < 0) {
        return "Refund";
      } else if (o?.gst === 0) {
        return "Conversion";
      } else {
        return "Conversion Top Up";
      }
    }

    if (o.linkedInvoice) {
      const outstandingAmount = calcOustandingAmount(o);
      if (Number(outstandingAmount) === 0 || Number(outstandingAmount) < 0) {
        return "Refund";
      } else {
        return "Outstanding";
      }
    }

    if (paidAmount < gstAmount && paidAmount !== 0) {
      return "Outstanding";
    }

    if (paidAmount > gstAmount) {
      return "Overpayment";
    }

    if (o?.isPaymentCompleted === true) {
      return "Paid";
    }

    return "Unpaid";
  };

  const getBillingList = async () => {
    const { result, error } = await getBillingByPatient(patientData?._id);

    if (error) {
      if (error?.message === "No data Found") {
        setBillings([]);
      } else {
        toastify(error?.message, "error");
      }
      return;
    } else {
      let tempArr = result?.map((e) => {
        const loc = e?.branch?.location?.find((o) => o._id === e?.location);
        const packages = e?.package?.map((o) => {
          return {
            name: o?.name ? o?.name : o?.package_id?.name,
            qty: o?.package_qty,
            price: o?.package_unit_price,
            discount: o?.package_discount,
            discountType: o?.package_discountType,
            promoDiscountType: o?.promoDiscountType,
            promoDiscountValue: o?.promoDiscountValue,
            promoName: o?.promoName,
            total_price: o?.package_price,
          };
        });
        const product = e?.product?.map((o) => {
          return {
            name: o?.name ? o?.name : o?.product_id?.name,
            qty: o?.product_qty,
            price: o?.product_unit_price,
            discount: o?.product_discount,
            discountType: o?.product_discountType,
            promoDiscountType: o?.promoDiscountType,
            promoDiscountValue: o?.promoDiscountValue,
            promoName: o?.promoName,
            total_price: o?.product_price,
          };
        });
        const treatment = e?.treatment?.map((o) => {
          return {
            name: o?.name ? o?.name : o?.service_id?.name,
            qty: o?.service_qty,
            price: o?.service_unit_price,
            discount: o?.service_discount,
            discountType: o?.service_discountType,
            promoDiscountType: o?.promoDiscountType,
            promoDiscountValue: o?.promoDiscountValue,
            promoName: o?.promoName,
            total_price: o?.service_price,
          };
        });
        const others = e?.others?.map((o) => {
          return {
            name: o?.description,
            qty: 1,
            price: o?.total_price,
            discount: 0,
            total_price: o?.total_price,
          };
        });

        let taxVal = 0;
        let taxs = [];
        e?.tax?.map((tax) => {
          if (taxVal !== 0) {
            taxVal =
              tax.typeValue == "Percentage"
                ? (tax.value / 100) * taxVal
                : tax.value + taxVal;
          } else {
            taxVal =
              tax.typeValue == "Percentage"
                ? (tax.value / 100) * e?.actualAmount
                : tax.value + e?.actualAmount;
          }

          taxs.push({
            ...tax,
            afterTaxValue: taxVal,
          });
        });

        const notesObj = {
          startTime: e?.session?.startTime,
          endTime: e?.session?.endTime,
          staff: e?.session?.staff?.map((o) => o?.username),
          soap: e?.session?.patientSOAP,
          location: loc,
          service: [...packages, ...product, ...treatment, ...others],
          remarks: e?.remarks,
          usePoints: e?.usePoints,
          usePointAmount: e?.usePointAmount ? e?.usePointAmount : 0,
          promo: e?.promo,
          promoType: e?.promoType,
          promoDiscount: e?.promoDiscount,
          promoDiscountType: e?.promoDiscountType,
          paymentAdjustments: e?.paymentAdjustments,
          tax: e?.tax,
          promoDiscount:
            e?.promoType === "Amount"
              ? e?.promo
              : (e?.promo / 100) * e?.actualAmount,
          afterTaxValue: taxVal,
          gstAmount: e?.gst,
          actualAmount: e?.actualAmount,
          totalAmount: e?.totalAmount,
          totalItemAmount: e?.totalItemAmount,
          paymentMethod: e?.paymentMethod?.name,
          installmentRecord: e?.paymentRecord ?? [],
          linkedInvoice: e?.linkedInvoice
        };

        const statusPayment = getStatusPayment(e);
        let classNameBtn = "";
        if (statusPayment === "Paid") {
          classNameBtn = "green-rounded-button";
        } else if (statusPayment === "Outstanding") {
          classNameBtn = "yellow-rounded-button";
        } else if (statusPayment === "Conversion Top Up") {
          classNameBtn = "additional-rounded-button"; 
        } else if (statusPayment === "Conversion") {
          classNameBtn = "conversion-rounded-button"; 
        } else {
          classNameBtn = "red-rounded-button";
        }

        return {
          date: new Date(e.date).toLocaleDateString(),
          notes: notesObj,
          _id: e._id,
          invoiceNo: e.invoiceNo,
          created_at: e?.created_at,
          remarks: e?.remarks,
          promo: e?.promo,
          promoType: e?.promoType,
          usePoints: e?.usePoints,
          actualAmount: e?.actualAmount,
          totalItemAmount: e?.totalItemAmount,
          updated_at: e.updated_at,
          totalAmount: e.totalAmount,
          gstAmount: e?.gst,
          remark: e?.remarkDiscount,
          patient: e?.patient,
          isPaymentInInstallment: e?.isPaymentInInstallment,
          paymentRecord: e?.paymentRecord,
          paymentAdjustments: e?.paymentAdjustments,
          package: [...e?.package],
          others: [...e?.others],
          product: [...e?.product],
          treatment: [...e?.treatment],
          classNameBtn,
          status: statusPayment,
          originalInvoice: e?.originalInvoice ? e?.originalInvoice : null,
          isConversion: e?.isConversion,
          isRefund: e?.isRefund,
          outstandingPaid: e?.outstandingPaid ? e?.outstandingPaid : 0,
          remarkOutstanding: e?.remarkOutstanding ? e?.remarkOutstanding : null
        };
      });
      tempArr = tempArr.reverse();
      const groupedBillings = result.reduce((acc, invoice) => {
      // If invoice has an originalInvoice, skip it because it is already merged elsewhere
      if (invoice.originalInvoice) return acc;

      // Find any child invoice linked to this invoice
      const childInvoice = result.find((inv) => inv.originalInvoice === invoice._id);
 
      // Get status for the parent invoice
  const parentStatus = getStatusPayment(invoice);

  // If child exists, add it as a linkedInvoice in the parent
  if (childInvoice) {
    acc.push({
      ...invoice, // Keep parent invoice data
      status: parentStatus, // Add status for parent
      linkedInvoice: {
        ...childInvoice, // Keep child invoice data
        status: getStatusPayment(childInvoice), // Add status for child
      },
    });
  } else {
    acc.push({
      ...invoice,
      status: parentStatus, // Add status for invoices without a linked invoice
    });
  }

      return acc;
    }, []);
      setBillings(tempArr);
      setOriBillings(groupedBillings.reverse());
    }
    // if (response?.status !== "Fail") {
    // 	// let _response = response?.filter(
    // 	// 	(e) => patientData?._id === e?.patient?._id,
    // 	// );

    // 	setBillings(_response);
    // 	console.log({ _response });
    // } else {

    // }
  };

  useEffect(() => {
    getSetting().then((res) => {
      if (res.status !== "Fail") {
        setSetting(res);
      }
    });
  }, []);

  useEffect(() => {
    authorizedRequests({
      url: "membershipSetting/listOfMembershipSetting",
      method: "get",
    }).then((res) => {
      setUsePointVal(res.result?.data[0]?.rewardPoint ?? 0);
    });
    getBillingList();
  }, []);

  const getLastPaidAmount = (originalInvoiceData) => {
    const paidAmount =
      originalInvoiceData?.paymentRecord && originalInvoiceData?.paymentRecord?.length > 0
        ? originalInvoiceData?.paymentRecord[originalInvoiceData?.paymentRecord?.length - 1].payed
        : 0;

    return paidAmount;
  }

  const getLastRemainingAmount = (originalInvoiceData) => {
    const remainingAmount =
      originalInvoiceData?.paymentRecord && originalInvoiceData?.paymentRecord?.length > 0
        ? originalInvoiceData?.paymentRecord[originalInvoiceData?.paymentRecord?.length - 1].remaining
        : 0;

    return remainingAmount;
  }
  
  const groupedBillings = billings.reduce((acc, invoice) => {
    // If invoice has an originalInvoice, skip it because it is already merged elsewhere
  if (invoice.originalInvoice) return acc;

    // Find any child invoice linked to this invoice
    const childInvoice = billings.find((inv) => inv.originalInvoice === invoice._id);
    const lastPaid = Number(getLastPaidAmount(invoice))
    const childInvoicePayed = Number(childInvoice?.paymentRecord[childInvoice?.paymentRecord.length - 1]?.payed)
    
    const statusPayment = getStatusPayment({
      ...invoice,
      linkedInvoice: childInvoice
    });
    let classNameBtn = "";
    if (statusPayment === "Paid") {
      classNameBtn = "green-rounded-button";
    } else if (statusPayment === "Outstanding") {
      classNameBtn = "yellow-rounded-button";
    } else if (statusPayment === "Conversion Top Up") {
      classNameBtn = "additional-rounded-button"; 
    } else if (statusPayment === "Conversion") {
      classNameBtn = "conversion-rounded-button"; 
    } else {
      classNameBtn = "red-rounded-button";
    }
    // If child exists, add it as a linkedInvoice in the parent
    if (childInvoice) {
      acc.push({
        ...invoice, // Keep parent invoice data
        status: statusPayment,
        classNameBtn : classNameBtn,
        linkedInvoice: childInvoice, // Add child invoice inside it
      });
    } else {
      acc.push(invoice); // If no child exists, push it as normal
    }

    return acc;
  }, []);

  return (
    <div className="billing-data-table">
      <Table bordered>
        <thead
          style={{
            background: "#24566f",
            color: "white",
          }}
        >
          <tr>
            <th style={{width: "100px"}}>Invoice No</th>
            <th>Date</th>
            <th>Total Amount</th>
            <th>Total Amount (GST)</th>
            <th>Paid Amount</th>
            <th>Outstanding</th>
            <th>Remark</th>
            <th>Status</th>
            <th className="">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {groupedBillings?.map((e, index) => (
            <>
              <tr style={{}}>
                <td style={{minWidth: "120px"}}>
                  {e.invoiceNo ? getInvoiceId(e.invoiceNo) : "-"}
                </td>
                <td style={{minWidth: "100px"}}>
                  {new Date(e.date).toLocaleDateString()}
                </td>
                <td style={{minWidth: "110px"}}>
                  {thousandAndDecimalFormat(e.totalAmount, true, 0)}
                </td>

                <td style={{minWidth: "110px"}}>
                  {thousandAndDecimalFormat(e.gstAmount, true, 2)}
                </td>
                <td style={{minWidth: "100px"}}>
                  {thousandAndDecimalFormat(getLastPaidAmount(e), true, 2)}
                </td>
                <td style={{minWidth: "100px"}}>
                  {thousandAndDecimalFormat(calcOustandingAmount(e, "TEST"), true, 2)}
                </td>

                <td style={{minWidth: "200px"}}>
                  <div>{e.remark}</div>
                </td>
                <td>
                  <div className={e.classNameBtn}>{e.status}</div>
                </td>
                <td style={{ minWidth: "140px" , textAlign: "right", verticalAlign: "middle" }}>
                    <div style={{ display: "flex", flex: "row", gap: "20px",alignItems: "center", justifyContent: "flex-end" , minWidth: "20px", marginRight: '25%' }}>
                  {e.status !== "Paid" && (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        let lastPaidAmount = 0;
                        let lastTotalAmount = e.totalAmount;
                        if (e?.paymentRecord?.length > 0) {
                          lastPaidAmount =
                          e?.paymentRecord[e?.paymentRecord?.length - 1]?.payed ?? 0;
                        }

                        navigate("/add-payment", {
                          state: {
                            dataId: e._id,
                            invoiceNo: e.invoiceNo,
                            invoiceDate: new Date(e.date).toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                            }),
                            remarks: e.remarks,
                            promo: e.promo,
                            promoType: e.promoType,
                            usePoints: e.usePoints,
                            usePointAmount: e.usePointAmount ? e.usePointAmount : 0,
                            subtotalAmount: e.actualAmount,
                            totalItemAmount: e.totalItemAmount,
                            totalAmount: e.totalAmount,
                            gstAmount: e.gstAmount,
                            patient: e.patient,
                            isPaymentInInstallment: e.isPaymentInInstallment,
                            isPaymentCompleted: e.isPaymentCompleted,
                            paymentRecord: JSON.parse(JSON.stringify(e.paymentRecord)),
                            lastPaidAmount: lastPaidAmount,
                            lastTotalAmount: lastTotalAmount,
                            paymentAdjustments: e.paymentAdjustments,
                            package: [...e?.package],
                            others: [...e?.others],
                            product: [...e?.product],
                            treatment: [...e?.treatment],
                            isConversion: e?.isConversion,
                            isRefund: e?.isRefund,
                            originalInvoice: e?.originalInvoice,
                            outstandingPaid: e?.linkedInvoice && e?.linkedInvoice?.outstandingPaid
                          },
                        });
                      }}
                    >
                       <i className="fa fa-credit-card"></i>
                    </div>
                  )}
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      if (index === activeIndex) {
                        setActiveIndex();
                        setData();
                      } else {
                        setActiveIndex(index);
                        setData(e);
                      }
                    }}
                  >
                     <i className="fa fa-eye"></i>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedBilling(oriBillings[index]);
                      setShowInvoice(true);
                      console.log("oriBillings[index]", oriBillings[index])
                    }}
                  >
                    <i class="fa fa-print print-color"></i>
                    </div>
                    </div>
                </td>
              </tr>

              {e?.linkedInvoice && 
              <tr style={{
                  backgroundColor: "#ededed", // You can keep the desired color here
                }}>
                <td className="wd-150" style={{paddingLeft: "20px"}}>
                  ↳ {e?.linkedInvoice.invoiceNo ? getInvoicebyId(e?.linkedInvoice.invoiceNo, e?.linkedInvoice?.isConversion, e?.linkedInvoice?.isRefund, e?.linkedInvoice?.originalInvoice) : "-"}
                </td>
                <td className="wd-100">
                  {new Date(e?.linkedInvoice.date).toLocaleDateString()}
                </td>

                <td className="wd-100">
                  {thousandAndDecimalFormat(e?.linkedInvoice.totalAmount, true, 0)}
                </td>

                <td className="wd-100">
                  {thousandAndDecimalFormat(e?.linkedInvoice.gstAmount, true, 2)}
                </td>
                <td className="wd-100">          
                  {thousandAndDecimalFormat(getLastPaidAmount(e?.linkedInvoice), true, 2)}    
                  {
                    (e?.linkedInvoice?.outstandingPaid !== 0) && (
                      <React.Fragment>
                        <br />
                        {thousandAndDecimalFormat(Math.abs(e?.linkedInvoice?.outstandingPaid) * -1)}
                      </React.Fragment>
                    )
                  }
                </td>
                <td colSpan={2}>
                  {e?.linkedInvoice?.remarkOutstanding}
                </td>
                {/* <td className="wd-100">
                  <div>{e?.linkedInvoice.remark}</div>
                </td> */}
                <td className="wd-100">
                  <div className={e?.linkedInvoice.classNameBtn}>{e?.linkedInvoice.status}</div>
                </td>
                <td className="wd-100" style={{ minWidth: "100px" , textAlign: "right", verticalAlign: "middle" }}>
                    <div style={{ display: "flex", flex: "row", gap: "20px",alignItems: "center", justifyContent: "flex-end" , minWidth: "20px", marginRight: '25%' }}>
                    {e?.linkedInvoice.status !== "Paid" && (
                      
                      <div
                      className="cursor-pointer"
                      onClick={() => {
                        let lastPaidAmount = 0;
                        let lastTotalAmount = e?.linkedInvoice.totalAmount;
                        if (e?.linkedInvoice.paymentRecord?.length > 0) {
                          lastPaidAmount =
                          e?.linkedInvoice.paymentRecord[e?.linkedInvoice.paymentRecord?.length - 1]?.payed ?? 0;
                        }

                        navigate("/add-payment", {
                          state: {
                            dataId: e?.linkedInvoice._id,
                            invoiceNo: e?.linkedInvoice.invoiceNo,
                            invoiceDate: new Date(e?.linkedInvoice.date).toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                            }),
                            remarks: e?.linkedInvoice.remarks,
                            promo: e?.linkedInvoice.promo,
                            promoType: e?.linkedInvoice.promoType,
                            usePoints: e?.linkedInvoice.usePoints,
                            usePointAmount: e?.linkedInvoice.usePointAmount ? e?.linkedInvoice.usePointAmount : 0,
                            subtotalAmount: e?.linkedInvoice.actualAmount,
                            totalItemAmount: e?.linkedInvoice.totalItemAmount,
                            totalAmount: e?.linkedInvoice.totalAmount,
                            gstAmount: e?.linkedInvoice.gstAmount,
                            patient: e?.linkedInvoice.patient,
                            isPaymentInInstallment: e?.linkedInvoice.isPaymentInInstallment,
                            isPaymentCompleted: e?.linkedInvoice.isPaymentCompleted,
                            paymentRecord: JSON.parse(JSON.stringify(e?.linkedInvoice.paymentRecord)),
                            lastPaidAmount: lastPaidAmount,
                            lastTotalAmount: lastTotalAmount,
                            paymentAdjustments: e?.linkedInvoice.paymentAdjustments,
                            package: [...e?.linkedInvoice.package],
                            others: [...e?.linkedInvoice.others],
                            product: [...e?.linkedInvoice.product],
                            treatment: [...e?.linkedInvoice.treatment],
                            isConversion: e?.linkedInvoice?.isConversion,
                            isRefund: e?.linkedInvoice?.isRefund,
                            originalInvoice: e?.linkedInvoice?.originalInvoice,
                            outstandingPaid: e?.linkedInvoice?.outstandingPaid,
                            remarkOutstanding: e?.linkedInvoice?.remarkOutstanding,
                            status: e?.linkedInvoice?.status
                          },
                        });
                      }}
                      >
                      <i className="fa fa-credit-card"></i>
                      </div>
                    )}
                  <div
                     className="cursor-pointer"
                    onClick={() => {
                      if (index === activeChildIndex) {
                        setActiveChildIndex();
                        setData();
                      } else {
                        setActiveChildIndex(index);
                        setData(e?.linkedInvoice);
                      }
                    }}
                  >
                    <i class="fa fa-eye"></i>
                  </div>
                  <div
                     className="cursor-pointer"
                    onClick={() => {
                      setSelectedBilling(oriBillings[index].linkedInvoice);
                      setShowInvoice(true);
                    }}
                  >
                    <i class="fa fa-print print-color"></i>
                      </div>
                      </div>
                </td>
              </tr>
              }
              
              {activeIndex === index && (
                <tr>
                  <td colSpan="7">  
                    <NoteRenderer
                      role={"Doctor"}
                      isPatientBilling
                      patientProfile={patientData.patientProfile}
                      updated_at={e?.updated_at}
                      notes_data={e?.notes}
                      date={e.date}
                      pointVal={usePointVal}
                      isBilling={true}
                    />
                  </td>
                </tr>
              )}
              {activeChildIndex === index && (
                <tr>
                  <td colSpan="7">
                    <NoteRenderer
                      role={"Doctor"}
                      isPatientBilling
                      patientProfile={patientData.patientProfile}
                      updated_at={e?.linkedInvoice.updated_at}
                      notes_data={e?.linkedInvoice.notes}
                      date={e?.linkedInvoice.date}
                      pointVal={usePointVal}
                      isBilling={true}
                    />
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </Table>

      {showInvoice && (
        <Modal
          centered
          show={showInvoice}
          onHide={() => {
            setShowInvoice(false);
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Invoice
            </Modal.Title>
          </Modal.Header>
          <div className="pdf-btn">
            <PDFViewer style={{ width: "100%", height: "800px" }}>
              <InvoicePDF
                billing={selectedBilling}
                setting={setting}
                usePointVal={usePointVal}
              />
            </PDFViewer>
          </div>
        </Modal>
      )}
    </div>
  );
}
