import React, { useState, useEffect, useMemo } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { 
	Radio,
	RadioGroup,
	FormControlLabel,
	TextField,

} from '@mui/material';

import { getAllQuestions } from "../../../apis/healthDeclaration";
import { toastify } from "../../../helperFunctions/toastify";
import { authorizedRequests } from "../../../axios/axios-config";

import { SignWrapper, OlWrapper } from "./styled";

function HealthDeclaration() {
	let navigate = useNavigate();
	let params = useParams();
	const { id } = params;
	// const [id, setId] = useState();
	const today = new Date().toLocaleDateString("en-GB", {
		day: "numeric",
		month: "numeric",
		year: "numeric",
	});

	const [resQuestion, setResQuestion] = useState(null);
	const [questions, setQuestions] = useState([]);
	const [edit, setEdit] = useState(null);
	const [haveSigned, setHaveSigned] = useState(false);
	const [patientRef, setPatientRef] = useState({});
	const [staffRef, setStaffRef] = useState({});

	useEffect(() => {
		getQuestions();
	}, []);

	const getSignDate = (type) => {
		if (type === "Patient") {
			return today;
		} else {
			return today;
		}
	}
	const getQuestions = async () => {
		const response = await getAllQuestions();
		if (response.status === "Success") {
			setQuestions(response.data);
		}
	};

	const submitForm = async () => {
		if (haveSigned === false) {
			toastify(
				"Patient need to sign",
				"error",
			);

			return;
		}

		if (edit) {
			const { result, error } = await authorizedRequests({
				url: "usersHealthDeclaration/updateUserHealthDeclarationById",
				method: "post",
				data: {
					id: edit,
					questionData: resQuestion.questionData,
					patientSign: patientRef.toDataURL("image/png"),
					staffSign: staffRef.toDataURL("image/png")
				},
			});
			if (error) {
				toastify(
					error.message ?? "Failed to post questionnaire form",
					"error",
				);
				return;
			}
			toastify("Thank You!", "success");
			return;
		}

		const { result, error } = await authorizedRequests({
			url: "usersHealthDeclaration/createUsersHealthDeclaration",
			method: "post",
			data: {
				questionData: resQuestion.questionData,
				patient: id,
				patientSign: patientRef.toDataURL("image/png"),
				staffSign: staffRef.toDataURL("image/png")
			},
		});
		if (error) {
			toastify(
				error.message ?? "Failed to post questionnaire form",
				"error",
			);
			return;
		}
		toastify("Thank You!", "success");
	};

	const onAnsParentChange = (field, index, value) => {
		setResQuestion(() => {
			const updatedValue = {...resQuestion};
			updatedValue.questionData[index][field] = value;

			return updatedValue;
		})
	}

	const onAnsChildChange = (parentIndex, secondIndex, value) => {
		setResQuestion(() => {
			const updatedValue = {...resQuestion};
			updatedValue.questionData[parentIndex].indicatorAnswer[secondIndex].answer = value;

			return updatedValue;
		})
	}

	useEffect(() => {
		if (questions.length > 0) {
			(async () => {
				const { result, error } = await authorizedRequests({
					url: "usersHealthDeclaration/getUserHealthDeclarationByPatientId",
					method: "post",
					data: {
						patient: id,
					},
				});
				if (error) {
					setEdit();
					setResQuestion({
						patient: id,
						questionData: questions.map((q) => (
							{
								question: q._id,
								enableIndicator: q.enableIndicator,
								remark: "",
								answer: false,
								indicatorAnswer: q.indicators?.map((indicator) => (
									{
										name: indicator,
										answer: false,
									}
								)) ?? []
							}
						))
					});
					return;
				}
	
				setEdit(result.data?._id);
				setResQuestion(result.data);
			})();
		}
	}, [questions]);

	console.log("DEBUG", resQuestion)

	return (
		<div className=" px-5 mb-5 pb-5 white-background">
			<div className="d-flex w-100 justify-content-center p-0 questionnaire-heading my-5 flex-column align-items-center">
				<div className="logo-toggle my-3">
					<img src="/imgs/logo.svg" className="dpImg" alt="" />
				</div>
				<div>Medical History Form</div>
			</div>
			<Container fluid>
				<Row>
					<OlWrapper type="A">
						{
							questions?.map((q, index) => (
								<li key={index}>
									<div>
										<span className="child-item">
											{q.question}
										</span>

										{
											q.enableIndicator === false && (
												<React.Fragment>
													<div className="radio-box">
														<RadioGroup
															row
															aria-labelledby="demo-row-radio-buttons-group-label"
															name="row-radio-buttons-group"
															onChange={e => onAnsParentChange("answer", index, e.target.value)}
															value={resQuestion ? resQuestion.questionData[index].answer : false}
														>
															<FormControlLabel value={true} control={<Radio />} label="Yes" />
															<FormControlLabel value={false} control={<Radio />} label="No" />
														</RadioGroup>
													</div>

													{
														(q.enableRemark === true && resQuestion?.questionData?.[index]?.answer) && (
															<div className="remark-input">
																<TextField 
																	id="outlined-basic" 
																	label="Remark" 
																	variant="outlined" 
																	value={resQuestion ? resQuestion.questionData[index].remark : ""}
																	onChange={e => onAnsParentChange("remark", index, e.target.value)}
																/>
															</div>
														)
													}
												</React.Fragment>
											)
										}
									</div>
									{
										q.enableIndicator === false && (
											<div className="desc-box">
												<p>{q.description}</p>
											</div>
										)
									}

									{
										q.enableIndicator === true && (
											<ol type="1">
												{
													q.indicators?.map((indicator, secondIndex) => (
														<li key={`${index}-${secondIndex}`}>
															<div>
																<span className="child-item">
																	{indicator}
																</span>

																<div className="radio-box">
																	<RadioGroup
																		row
																		aria-labelledby="demo-row-radio-buttons-group-label"
																		name="row-radio-buttons-group"
																		onChange={e => onAnsChildChange(index, secondIndex, e.target.value)}
																		value={resQuestion ? resQuestion.questionData[index].indicatorAnswer[secondIndex]?.answer : false}
																	>
																		<FormControlLabel value={true} control={<Radio />} label="Yes" />
																		<FormControlLabel value={false} control={<Radio />} label="No" />
																	</RadioGroup>
																</div>
															</div>
														</li>
													))
												}
											</ol>
										)
									}
								</li>
							))
						}
					</OlWrapper>
					{/* {
						questions?.map((q, index) => (
							<React.Fragment>
								<StyledTitle>{q.group}</StyledTitle>
								{
									q.questions?.map((question, childIdx) => (
										renderQuestions(question, q.group)
									))
								}
							</React.Fragment>
						))
					} */}
				</Row>
				<Row className="mt-4">
					<Col md={6}>
						<SignWrapper className="patient-signature">
							<div className="text-center p-3 grey header-sign">
								<p>Patient Signature</p>
								<p>{getSignDate("Patient")}</p>
							</div>
							<SignatureCanvas
								penColor="green"
								ref={(ref) => {
									ref?.fromDataURL(
										resQuestion?.patientSign
									);

									setPatientRef(ref);
								}}
								canvasProps={{
									width: 300,
									height: 150,
									className: "sigCanvas",
								}}
								onBegin={() => setHaveSigned(true)}
							/>

						</SignWrapper>
						<div style={{width: 300}}>
							<Button
								className="grey-rounded-button ms-auto mt-3"
								onClick={() => { patientRef.clear() }}
							>
								Clear Box
							</Button>
						</div>
					</Col>
					<Col md={6}>
						<SignWrapper className="patient-signature" style={{marginLeft: "auto"}}>
							<div className="text-center p-3 grey header-sign">
								<p>Staff Signature</p>
								<p>{getSignDate("Staff")}</p>
							</div>
							<SignatureCanvas
								penColor="green"
								ref={(ref) => {
									ref?.fromDataURL(
										resQuestion?.staffSign
									);

									setStaffRef(ref);
								}}
								canvasProps={{
									width: 300,
									height: 150,
									className: "sigCanvas",
								}}
							/>

						</SignWrapper>
						<div style={{width: 300, marginLeft: "auto"}}>
							<Button
								className="grey-rounded-button ms-auto mt-3"
								onClick={() => { staffRef.clear() }}
							>
								Clear Box
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
			<div className="d-flex justify-content-center">
				{questions?.length > 0 && (
					<Button
						className="addItem"
						onClick={() => {
							submitForm();
							// navigate("/patient");
						}}
					>
						Continue
					</Button>
				)}
			</div>
		</div>
	);
}

export default HealthDeclaration;
