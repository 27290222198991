import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  A_SS_Campaign,
  A_AS_AdditionalCost,
  A_AS_Branch,
  A_AS_CategoryCost,
  A_AS_DataTable,
  A_AS_Department,
  A_AS_Drawtool,
  A_AS_KOL,
  A_AS_MembershipSettings,
  A_AS_ProductCategory,
  A_AS_ReminderTemplate,
  A_AS_Supplier,
  A_AS_Tags,
  A_AS_TaxSettings,
  A_AS_UOM,
  I_AS_AdditionalCost,
  I_AS_Branch,
  I_AS_CategoryCost,
  I_AS_DataTable,
  I_AS_Department,
  I_AS_Drawtool,
  I_AS_KOL,
  I_AS_MembershipSettings,
  I_AS_PaperWork,
  I_AS_ProductCategory,
  I_AS_ReminderTemplate,
  I_AS_Supplier,
  I_SS_Campaign,
  I_AS_Tags,
  I_AS_TaxSettings,
  I_AS_UOM,
} from "../../../assets/images";
import { getVersion } from "../../../helperFunctions/utils";

function SidebarAdmin({ sidebar, setSidebar }) {
  const [activePage, setActivePage] = useState("/staff");
  const [paperWork, setPaperWork] = useState(false);
  const [showPaymentTab, setShowPaymentTab] = useState(false);
  const [inventoryMenueItem, setInventoryMenueItem] = useState(false);
  const [generalMenueItem, setGeneralMenueItem] = useState(false);
  const [patientMenueItem, setPatientMenueItem] = useState(false);
  const [templateMenueItem, setTemplateMenueItem] = useState(false);

  window.addEventListener("click", function (e) {
    if (
      document.getElementById("clickbox")?.contains(e.target) ||
      document.getElementById("toggler")?.contains(e.target)
    ) {
    } else {
      let width =
        window.innerWidth > 0 ? window.innerWidth : window.screen.width;
      if (width < 820) {
        setSidebar(false);
      }
    }
  });

  return (
    <div
      id="clickbox"
      className={`custom-scroll sideBar ${
        sidebar ? "openSidebar" : "closeSidebar"
      }`}
      style={{
        display: "flex",          // Use flexbox
        flexDirection: "column",  // Align items in a column
        justifyContent: "space-between",  // Space the items out
        height: "92%",           // Ensure full height
      }}
    >
      <ul>
        <Link
          to="/staff"
          onClick={() => {
            setActivePage("/staff");
            setPaperWork();
          }}
        >
          <li className={activePage === "/staff" ? "active" : ""}>
            <i className="fa-solid fa-user"></i>
            User
          </li>
        </Link>
        <div
          onClick={() => {
            setActivePage("/general-menue");
            setPatientMenueItem();
            setInventoryMenueItem();
            setShowPaymentTab();
            setPaperWork();
            setTemplateMenueItem();
          }}
        >
          <div className="sidebarExtend">
            <div className="w-100">
              <div
                className={`d-flex align-items-center px-3 py-3 ${
                  paperWork && "pb-0"
                } pt-3`}
                onClick={() => {
                  setGeneralMenueItem(generalMenueItem ? null : "q");
                }}
              >
                <img alt="" src={I_AS_Branch} className="me-3" />
                General Setting
                <div className="ms-auto">
                  {generalMenueItem ? (
                    <i class="fa-solid fa-angle-up"></i>
                  ) : (
                    <i class="fa-solid fa-angle-down"></i>
                  )}
                </div>
              </div>
              {generalMenueItem && (
                <div className="py-1 my-2 gray-background">
                  <Link
                    to="/company_setting"
                    onClick={() => {
                      setActivePage("/company_setting");
                      setGeneralMenueItem("company_setting");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        generalMenueItem === "company_setting"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Company Setting
                    </div>
                  </Link>
                  <Link
                    to="/branch"
                    onClick={() => {
                      setActivePage("/branch");
                      setGeneralMenueItem("branch");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        generalMenueItem === "branch"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Branch
                    </div>
                  </Link>
                  <Link
                    to="/departments"
                    onClick={() => {
                      setActivePage("/departments");
                      setGeneralMenueItem("department");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        generalMenueItem === "department"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Department
                    </div>
                  </Link>
                  {/* <Link
                    to="/supplier"
                    onClick={() => {
                      setActivePage("/supplier");
                      setGeneralMenueItem("supplier");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        generalMenueItem === "supplier"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Supplier
                    </div>
                  </Link> */}
                  <Link
                    to="/campaign"
                    onClick={() => {
                      setActivePage("/campaign");
                      setGeneralMenueItem("campaign");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        generalMenueItem === "campaign"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Campaign
                    </div>
                  </Link>
                  <Link
                    to="/tags"
                    onClick={() => {
                      setActivePage("/tags");
                      setGeneralMenueItem("tags");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        generalMenueItem === "tags"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Tags
                    </div>
                  </Link>
                  <Link
                    to="/status_color"
                    onClick={() => {
                      setActivePage("/status_color");
                      setGeneralMenueItem("status_color");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        generalMenueItem === "status_color"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Color Status Appt
                    </div>
                  </Link>
                  <Link
                    to="/holidays"
                    onClick={() => {
                      setActivePage("/holidays");
                      setGeneralMenueItem("holidays");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        generalMenueItem === "holidays"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Holiday
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setActivePage("/paper-work");
            setShowPaymentTab();
            setInventoryMenueItem();
            setGeneralMenueItem();
            setPatientMenueItem();
            setTemplateMenueItem();
          }}
        >
          <div className="sidebarExtend">
            <div className="w-100">
              <div
                className={`d-flex align-items-center px-3 py-3 ${
                  paperWork && "pb-0"
                } pt-3`}
                onClick={() => {
                  setPaperWork(paperWork ? null : "q");
                }}
              >
                <img alt="" src={I_AS_PaperWork} className="me-3" />
                Paper Work
                <div className="ms-auto">
                  {paperWork ? (
                    <i class="fa-solid fa-angle-up"></i>
                  ) : (
                    <i class="fa-solid fa-angle-down"></i>
                  )}
                </div>
              </div>
              {paperWork && (
                <div className="py-1 my-2 gray-background">
                  <Link
                    to="/questionnaire-settings"
                    onClick={() => setPaperWork("questionnaire")}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        paperWork === "questionnaire"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Questionnaire Setting
                    </div>
                  </Link>
                  <Link
                    to="/healthDeclaration-settings"
                    onClick={() => setPaperWork("healthDeclaration")}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        paperWork === "healthDeclaration"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Health Declaration Setting
                    </div>
                  </Link>

                  <Link
                    to="/consent-settings"
                    onClick={() => setPaperWork("consents")}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        paperWork === "consents"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Consents
                    </div>
                  </Link>

                  <Link
                    to="/dot-phrases"
                    onClick={() => setPaperWork("dotPhrases")}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        paperWork === "dotPhrases"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Dot Phrases
                    </div>
                  </Link>
                  <Link
                    to="/patient-prescription-select-data"
                    onClick={() =>
                      setPaperWork("patientprescriptionselectdata")
                    }
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        paperWork === "patientprescriptionselectdata"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Prescription Constants
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setActivePage("/payment");
            setPaperWork();
            setInventoryMenueItem();
            setGeneralMenueItem();
            setPatientMenueItem();
            setTemplateMenueItem();
          }}
        >
          <div className="sidebarExtend">
            <div className="w-100">
              <div
                className={`d-flex align-items-center px-3 py-3 ${
                  paperWork && "pb-0"
                } pt-3`}
                onClick={() => {
                  setShowPaymentTab(showPaymentTab ? null : "q");
                  // setInventoryMenueItem();
                  setPaperWork();
                  // setGeneralMenueItem();
                  // setPatientMenueItem();
                }}
              >
                <img alt="" src={I_AS_CategoryCost} className="me-3" />
                Payment
                <div className="ms-auto">
                  {showPaymentTab ? (
                    <i class="fa-solid fa-angle-up"></i>
                  ) : (
                    <i class="fa-solid fa-angle-down"></i>
                  )}
                </div>
              </div>
              {showPaymentTab && (
                <div className="py-1 my-2 gray-background">
                  <Link
                    to="/tax-setting"
                    onClick={() => {
                      setActivePage("/tax-setting");
                      setPaperWork();
                      setShowPaymentTab("taxSetting");
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        showPaymentTab === "taxSetting"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Tax Setting
                    </div>
                  </Link>
                  <Link
                    to="/category-cost"
                    onClick={() => {
                      setActivePage("/category-cost");
                      setShowPaymentTab("categoryCost");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        showPaymentTab === "categoryCost"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Category Cost
                    </div>
                  </Link>
                  <Link
                    to="/additional-marketing-cost"
                    onClick={() => {
                      setActivePage("/additional-marketing-cost");
                      setShowPaymentTab("additionalMarketingCost");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        showPaymentTab === "additionalMarketingCost"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Additional / Marketing Cost
                    </div>
                  </Link>

                  <Link
                    to="/program"
                    onClick={() => {
                      setActivePage("/program");
                      setShowPaymentTab("program");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        showPaymentTab === "program"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Program Expiration
                    </div>
                  </Link>

                  <Link
                    to="/payment-method"
                    onClick={() => {
                      setActivePage("/payment-method");
                      setShowPaymentTab("payment-method");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        showPaymentTab === "payment-method"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Payment Method
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setActivePage("/inventory");
            setPaperWork();
            setShowPaymentTab();
            setGeneralMenueItem();
            setPatientMenueItem();
            setTemplateMenueItem();
          }}
        >
          <div className="sidebarExtend">
            <div className="w-100">
              <div
                className={`d-flex align-items-center px-3 py-3 ${
                  paperWork && "pb-0"
                } pt-3`}
                onClick={() => {
                  setInventoryMenueItem(inventoryMenueItem ? null : "q");
                  setShowPaymentTab();
                  setPaperWork();
                  setGeneralMenueItem();
                  setPatientMenueItem();
                }}
              >
                <img alt="" src={I_AS_ProductCategory} className="me-3" />
                Inventory
                <div className="ms-auto">
                  {inventoryMenueItem ? (
                    <i class="fa-solid fa-angle-up"></i>
                  ) : (
                    <i class="fa-solid fa-angle-down"></i>
                  )}
                </div>
              </div>
              {inventoryMenueItem && (
                <div className="py-1 my-2 gray-background">
                  <Link
                    to="/product-category"
                    onClick={() => {
                      setActivePage("/product-category");
                      setInventoryMenueItem("productCategory");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        inventoryMenueItem === "productCategory"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Product Category
                    </div>
                  </Link>
                  <Link
                    to="/uom-inventory"
                    onClick={() => {
                      setActivePage("/uom-inventory");
                      setInventoryMenueItem("uomInventory");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        inventoryMenueItem === "uomInventory"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      UOM Inventory
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setActivePage("/patient");
            setGeneralMenueItem();
            setInventoryMenueItem();
            setShowPaymentTab();
            setPaperWork();
            setTemplateMenueItem();
          }}
        >
          <div className="sidebarExtend">
            <div className="w-100">
              <div
                className={`d-flex align-items-center px-3 py-3 ${
                  paperWork && "pb-0"
                } pt-3`}
                onClick={() => {
                  setPatientMenueItem(patientMenueItem ? null : "q");
                }}
              >
                <img alt="" src={I_AS_KOL} className="me-3" />
                Patient
                <div className="ms-auto">
                  {patientMenueItem ? (
                    <i class="fa-solid fa-angle-up"></i>
                  ) : (
                    <i class="fa-solid fa-angle-down"></i>
                  )}
                </div>
              </div>
              {patientMenueItem && (
                <div className="py-1 my-2 gray-background">
                  <Link
                    to="/option-kol"
                    onClick={() => {
                      setActivePage("/option-kol");
                      setPatientMenueItem("optionalKOL");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        patientMenueItem === "optionalKOL"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      KOL/Channel
                    </div>
                  </Link>
                  <Link
                    to="/hear-about-us"
                    onClick={() => {
                      setActivePage("/hear-about-us");
                      setPatientMenueItem("aboutUS");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        patientMenueItem === "aboutUS"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Hear About Us
                    </div>
                  </Link>
                  <Link
                    to="/membership-setting"
                    onClick={() => {
                      setActivePage("/membership-setting");
                      setPatientMenueItem("membership-setting");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        patientMenueItem === "membership-setting"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Membership Setting
                    </div>
                  </Link>
                  <Link
                    to="/expired-setting"
                    onClick={() => {
                      setActivePage("/expired-setting");
                      setPatientMenueItem("expired-setting");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        patientMenueItem === "expired-setting"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Expired Patient
                    </div>
                  </Link>
                  <Link
                    to="/occupation"
                    onClick={() => {
                      setActivePage("/occupation");
                      setPatientMenueItem("occupation");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        patientMenueItem === "occupation"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Occupation
                    </div>
                  </Link>
                  {/* <Link
										to="/drawtool"
										onClick={() => {
											setActivePage("/drawtool");
											setPatientMenueItem("drawtool");
											setPaperWork();
										}}
									>
										<div
											className={`sidebar-subcategory ps-5 py-2 ${patientMenueItem === "drawtool"
												? "white-background"
												: "gray-font"
												}`}
										>
											Drawtool Template
										</div>
									</Link> */}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setActivePage("/template");
            setPaperWork();
            setShowPaymentTab();
            setInventoryMenueItem();
            setGeneralMenueItem();
            setPatientMenueItem();
          }}
        >
          <div className="sidebarExtend">
            <div className="w-100">
              <div
                className={`d-flex align-items-center px-3 py-3 ${
                  paperWork && "pb-0"
                } pt-3`}
                onClick={() => {
                  setTemplateMenueItem(templateMenueItem ? null : "q");
                  setShowPaymentTab();
                  setPaperWork();
                  setGeneralMenueItem();
                  setPatientMenueItem();
                  setInventoryMenueItem();
                }}
              >
                <img alt="" src={I_AS_ProductCategory} className="me-3" />
                Template
                <div className="ms-auto">
                  {templateMenueItem ? (
                    <i class="fa-solid fa-angle-up"></i>
                  ) : (
                    <i class="fa-solid fa-angle-down"></i>
                  )}
                </div>
              </div>
              {templateMenueItem && (
                <div className="py-1 my-2 gray-background">
                  <Link
                    to="/drawtool"
                    onClick={() => {
                      setActivePage("/drawtool");
                      setTemplateMenueItem("drawtoolTemplate");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        templateMenueItem === "drawtoolTemplate"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Drawtool Template
                    </div>
                  </Link>
                  <Link
                    to="/reminder-template"
                    onClick={() => {
                      setActivePage("/reminder-template");
                      setTemplateMenueItem("reminderTemplate");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        templateMenueItem === "reminderTemplate"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Reminder Template
                    </div>
                  </Link>
                  {/* <Link
                    to="/external-drug-template"
                    onClick={() => {
                      setActivePage("/external-drug-template");
                      setTemplateMenueItem("externalTemplate");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        templateMenueItem === "externalTemplate"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      External Drug Template
                    </div>
                  </Link> */}
                  <Link
                    to="/category-report"
                    onClick={() => {
                      setActivePage("/category-report");
                      setTemplateMenueItem("categoryReport");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        templateMenueItem === "categoryReport"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Category Report
                    </div>
                  </Link>
                  <Link
                    to="/data-table-template"
                    onClick={() => {
                      setActivePage("/data-table-template");
                      setTemplateMenueItem("dataTableTemplate");
                      setPaperWork();
                    }}
                  >
                    <div
                      className={`sidebar-subcategory ps-5 py-2 ${
                        templateMenueItem === "dataTableTemplate"
                          ? "white-background"
                          : "gray-font"
                      }`}
                    >
                      Report Template
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <Link
          to="/ipAccess"
          onClick={() => {
            setActivePage("/ipAccess");
            setPaperWork();
          }}
        >
          <li className={activePage === "/ipAccess" ? "active" : ""}>
            <i className="fa-solid fa-server"></i>
            Ip Access Control
          </li>
        </Link>
        {/* <Link
					to="/supplier"
					onClick={() => {
						setActivePage("/supplier");
						setPaperWork();
					}}
				>
					<li className={activePage === "/supplier" ? "active" : ""}>
						{activePage === "/supplier" ? (
							<img alt="" src={A_AS_Supplier} className="me-3" />
						) : (
							<img alt="" src={I_AS_Supplier} className="me-3" />
						)}
						Supplier
					</li>
				</Link> */}

        {/* <Link
					to="/drawtool"
					onClick={() => {
						setActivePage("/drawtool");
						setPaperWork();
					}}
				>
					<li className={activePage === "/drawtool" ? "active" : ""}>
						{activePage === "/drawtool" ? (
							<img alt="" src={A_AS_Drawtool} className="me-3" />
						) : (
							<img alt="" src={I_AS_Drawtool} className="me-3" />
						)}
						Drawtool Template
					</li>
				</Link> */}

        {/* <Link
					to="/tags"
					onClick={() => {
						setActivePage("/tags");
						setGeneralMenueItem();
						setInventoryMenueItem();
						setShowPaymentTab();
						setPatientMenueItem();
						setPaperWork()
					}}
				>
					<li className={activePage === "/tags" ? "active" : ""}>
						{activePage === "/tags" ? (
							<img alt="" src={A_AS_Tags} className="me-3" />
						) : (
							<img alt="" src={I_AS_Tags} className="me-3" />
						)}
						Tags
					</li>
				</Link> */}

        {/* <Link
					to="/generalSetting"
					onClick={() => {
						setActivePage("/generalSetting");
						setPaperWork();
					}}
				>
					<li className={activePage === "/generalSetting" ? "active" : ""}>
						{activePage === "/generalSetting" ? (
							<img alt="" src={A_AS_Tags} className="me-3" />
						) : (
							<img alt="" src={I_AS_Tags} className="me-3" />
						)}
						General Setting
					</li>
				</Link> */}

        {/* <Link
					to="/departments"
					onClick={() => {
						setActivePage("/departments");
						setPaperWork();
					}}
				>
					<li
						className={
							activePage === "/departments" ? "active" : ""
						}
					>
						{activePage === "/departments" ? (
							<img
								alt=""
								src={A_AS_Department}
								className="me-3"
							/>
						) : (
							<img
								alt=""
								src={I_AS_Department}
								className="me-3"
							/>
						)}
						Department
					</li>
				</Link> */}

        {/* <Link
					to="/data-table-template"
					onClick={() => {
						setActivePage("/data-table-template");
						setPaperWork();
						setGeneralMenueItem();
						setInventoryMenueItem();
						setShowPaymentTab();
						setPatientMenueItem()
					}}
				>
					<li
						className={
							activePage === "/data-table-template"
								? "active"
								: ""
						}
					>
						{activePage === "/data-table-template" ? (
							<img alt="" src={A_AS_DataTable} className="me-3" />
						) : (
							<img alt="" src={I_AS_DataTable} className="me-3" />
						)}
						Data Table Template
					</li>
				</Link>

				<Link
					to="/reminder-template"
					onClick={() => {
						setActivePage("/reminder-template");
						setPaperWork();
						setGeneralMenueItem();
						setInventoryMenueItem();
						setShowPaymentTab();
						setPatientMenueItem()
					}}
				>
					<li
						className={
							activePage === "/reminder-template" ? "active" : ""
						}
					>
						{activePage === "/reminder-template" ? (
							<img
								alt=""
								src={A_AS_ReminderTemplate}
								className="me-3"
							/>
						) : (
							<img
								alt=""
								src={I_AS_ReminderTemplate}
								className="me-3"
							/>
						)}
						Reminder Template
					</li>
				</Link> */}
        {/* <Link
					to="/hear-about-us"
					onClick={() => {
						setActivePage("/hear-about-us");
						setPaperWork();
					}}
				>
					<li
						className={
							activePage === "/hear-about-us" ? "active" : ""
						}
					>
						{activePage === "/hear-about-us" ? (
							<i className="fa fa-globe me-3"></i>
						) : (
							<i className="fa fa-globe me-3"></i>
						)}
						Hear About Us
					</li>
				</Link> */}

        {/* <Link
					to="/tax-setting"
					onClick={() => {
						setActivePage("/tax-setting");
						setPaperWork();
					}}
				>
					<li
						className={
							activePage === "/tax-setting" ? "active" : ""
						}
					>
						{activePage === "/tax-setting" ? (
							<img
								alt=""
								src={A_AS_TaxSettings}
								className="me-3"
							/>
						) : (
							<img
								alt=""
								src={I_AS_TaxSettings}
								className="me-3"
							/>
						)}
						Tax Setting
					</li>
				</Link> */}

        {/* <Link
					to="/uom-inventory"
					onClick={() => {
						setActivePage("/uom-inventory");
						setPaperWork();
					}}
				>
					<li
						className={
							activePage === "/uom-inventory" ? "active" : ""
						}
					>
						{activePage === "/uom-inventory" ? (
							<img alt="" src={A_AS_UOM} className="me-3" />
						) : (
							<img alt="" src={I_AS_UOM} className="me-3" />
						)}
						UOM Inventory
					</li>
				</Link> */}

        {/* <Link
					to="/product-category"
					onClick={() => {
						setActivePage("/product-category");
						setPaperWork();
					}}
				>
					<li
						className={
							activePage === "/product-category" ? "active" : ""
						}
					>
						{activePage === "/product-category" ? (
							<img
								alt=""
								src={A_AS_ProductCategory}
								className="me-3"
							/>
						) : (
							<img
								alt=""
								src={I_AS_ProductCategory}
								className="me-3"
							/>
						)}
						Product Category (Inventory)
					</li>
				</Link> */}

        {/* <Link
					to="/category-cost"
					onClick={() => {
						setActivePage("/category-cost");
						setPaperWork();
					}}
				>
					<li
						className={
							activePage === "/category-cost" ? "active" : ""
						}
					>
						{activePage === "/category-cost" ? (
							<img
								alt=""
								src={A_AS_CategoryCost}
								className="me-3"
							/>
						) : (
							<img
								alt=""
								src={I_AS_CategoryCost}
								className="me-3"
							/>
						)}
						Category Cost
					</li>
				</Link> */}

        {/* <Link
					to="/additional-marketing-cost"
					onClick={() => {
						setActivePage("/additional-marketing-cost");
						setPaperWork();
					}}
				>
					<li
						className={
							activePage === "/additional-marketing-cost"
								? "active"
								: ""
						}
					>
						{activePage === "/additional-marketing-cost" ? (
							<img
								alt=""
								src={A_AS_AdditionalCost}
								className="me-3"
							/>
						) : (
							<img
								alt=""
								src={I_AS_AdditionalCost}
								className="me-3"
							/>
						)}
						Additional / Marketing Cost
					</li>
				</Link> */}

        {/* <Link
					to="/option-kol"
					onClick={() => {
						setActivePage("/option-kol");
						setPaperWork();
					}}
				>
					<li
						className={activePage === "/option-kol" ? "active" : ""}
					>
						{activePage === "/option-kol" ? (
							<img alt="" src={A_AS_KOL} className="me-3" />
						) : (
							<img alt="" src={I_AS_KOL} className="me-3" />
						)}
						KOL/Channel
					</li>
				</Link> */}

        {/* <Link
					to="/holidays"
					onClick={() => {
						setActivePage("/holidays");
						setPaperWork();
						setGeneralMenueItem();
						setInventoryMenueItem();
						setShowPaymentTab();
						setPatientMenueItem()
					}}
				>
					<li className={activePage === "/holidays" ? "active" : ""}>
						{activePage === "/holidays" ? (
							<img alt="" src={A_SS_Campaign} className="me-3" />
						) : (
							<img alt="" src={I_SS_Campaign} className="me-3" />
						)}
						Holidays
					</li>
				</Link> */}
      </ul>

      <div style={{ textAlign: "center", color: "white" }}>
        <span>Version {getVersion()}</span>
      </div>
    </div>
  );
}

export default SidebarAdmin;
