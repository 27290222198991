import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { updatePackage } from "../../../../apis/packages";
import {
  getPurchaseList,
  updatePurchaseList,
  migratePurchaseList,
} from "../../../../apis/purchaseList";
import { toastify } from "../../../../helperFunctions/toastify";
import {
  customDateSort,
  customNumberSort,
  customStringSort,
  exportData,
} from "../../../../helperFunctions/utils";
import DeleteModal from "../../../SharedComponents/DeleteModal";

import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function PurchaseList() {
  const navigate = useNavigate();
  const [purchases, setPurchases] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const populateData = async () => {
    const response = await getPurchaseList();
    if (response?.status !== "Fail") {
      setPurchases(response.filter((e) => !e.isDeleted).reverse());
      console.log({ response });
    } else {
      if (response?.message === "No data Found") {
        setPurchases([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const removePurchaseList = async () => {
    setShow(false);
    let response = await updatePurchaseList(deleting, { isDeleted: true });
    if (response?.status !== "Fail") {
      populateData();
      toastify("Receiving Good deleted successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const editPurchases = (e) => {
    navigate("/add-receiving-goods", {
      state: {
        purchase: e,
      },
    });
  };

  const addPurchases = () => {
    navigate("/add-receiving-goods");
  };

  useEffect(() => {
    populateData();
  }, []);

  const getTotalAmount = (prod) => {
    let total = 0;
    prod.forEach((element) => {
      total += element.qty * element.unitCost;
    });
    return total;
  };

  const columns = [
    {
      name: "Order Date",
      selector: (row) =>
        new Date(row.orderDate).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
      wrap: true,
      minWidth: "160px",
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "orderDate", true),
    },
    {
      name: "Receiving Date",
      selector: (row) => row.receivingDate ? new Date(row.receivingDate).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      }) : "-",
      wrap: true,
      minWidth: "160px",
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "receivingDate", true),
    },
    // {
    //   name: "Supplier Name",
    //   selector: (row) => row.name,
    //   wrap: true,
    //   minWidth: "160px",
    // },
    {
      name: "Doc No",
      selector: (row) => row.docNumber,
      wrap: true,
      minWidth: "160px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "docNumber"),
    },
    {
      name: "Total Items",
      selector: (row) => row?.products?.length || "N/A",
      wrap: true,
      minWidth: "160px",
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount?.toLocaleString(),
      wrap: true,
      minWidth: "160px",
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "totalAmount"),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      wrap: true,
      minWidth: "170px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      minWidth: "160px",
    },
  ].reverse();

  const data = (filteredData.length === 0 ? purchases : filteredData)?.map(
    (e, index) => {
      return {
        index: index,
        id: e._id,
        orderDate: e.orderDate,
        receivingDate: e.receivingDate,
        supplier: e.supplier,
        docNumber: e.docNumber,
        totalItems: e.totalItems,
        totalAmount: getTotalAmount(e.products),
        products: e.products,
        isActive: e.isActive,
        isRecived: e.isRecived,
        status:
          e.isRecived === true ? (
            <div className="active-received">Received</div>
          ) : (
            <div className="inactive-received">Ordered</div>
          ),
        action: (
          <div className="d-flex">
            <div
              className="mx-3  cursor-pointer icon-width"
              onClick={() => {
                editPurchases(e);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div
              className="mx-3  cursor-pointer icon-width"
              onClick={() => {
                setDeleting(e._id);
                setShow(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    let formData = new FormData();
    formData.append("csvFile", selectedFile);
    migratePurchaseList(formData).then((res) => {
      populateData();
      toastify(res, "success");
    });
  };

  return (
    <>
      <div className="container p-0 rev-table">
        <div className="mainHeading px-4">
          <h1>Receiving Good</h1>
          <div className="d-flex">
            <Button
              className="addItem me-3"
              onClick={() => {
                const tempDataArr = data?.map((e) => {
                  return {
                    "Order Date": e.orderDate,
                    "Document Number": e.docNumber,
                    "Total Items": e?.products?.length || "N/A",
                    "Total Amount": e.totalAmount,
                    status: e.isRecived ? "Recived" : "Ordered",
                  };
                });
                exportData(tempDataArr, "Receiving Good", "Purchase_List");
              }}
            >
              Export
            </Button>{" "}
            <MuiButton
              className="addItem me-3"
              component="label"
              role={undefined}
              variant="contained"
              sx={{ backgroundColor: "#295381" }}
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Import
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </MuiButton>
            <Button className="addItem" onClick={addPurchases}>
              + Add New
            </Button>
          </div>
        </div>

        <DeleteModal
          content="Confirm Receiving Good Item Deletion?"
          onYes={removePurchaseList}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
}
