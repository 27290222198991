import React, { useState, useEffect } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getInfluencers, updateInfluencer } from "../../../apis/influencers";
import {
  getPatientKolsList,
  updatePatientKol,
} from "../../../apis/patientKols";
import { getPatients } from "../../../apis/patients";
import { permissionTypes } from "../../../helperFunctions/routes";
import { toastify } from "../../../helperFunctions/toastify";
import {
  customDateSort,
  customNumberSort,
  customStringSort,
  exportData,
} from "../../../helperFunctions/utils";
import ComponentWrapepr from "../../SharedComponents/ComponentWrapper";
import DeleteModal from "../../SharedComponents/DeleteModal";

export default function Kol() {
  const navigate = useNavigate();

  const [influencers, setInfluencers] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [objective, setObjective] = useState("");
  const [remarks, setRemarks] = useState("");
  const [type, setType] = useState("");
  const [kolBeingEdit, setKolBeingEdit] = useState();

  const getKolList = async () => {
    const { result, error } = await getPatientKolsList();
    if (error) {
      if (error?.message === "No data Found") {
        setInfluencers([]);
      } else {
        toastify(error?.message, "error");
      }
    } else {
      setInfluencers(result.data);
    }
  };

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const editKol = async (e) => {
    const { result, error } = await updatePatientKol({
      objective,
      remarks,
      kol_type: type,
      dataId: kolBeingEdit,
    });
    if (error) {
      toastify("Failed to update", "error");
      return;
    }
    setKolBeingEdit();
    setRemarks("");
    setObjective("");
    setType("");
    setOpenModal(false);
    getKolList();
    return;
  };

  useEffect(() => {
    getKolList();
  }, []);

  // const addKol = () => {
  // 	navigate("/add-kol");
  // };

  const removeKol = async () => {
    const { result, error } = await updatePatientKol({
      isDeleted: true,
      dataId: kolBeingEdit,
    });
    if (error) {
      toastify("Failed to delete kol", "error");
      return;
    }
    setKolBeingEdit();
    setDeleteModal(false);
    getKolList();
  };

  const columns = [
    {
      name: "Patient Name",
      selector: (row) => row.name,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    // {
    // 	name: "Remarks",
    // 	selector: (row) => <div className="text-center">{row.remarks}</div>,
    // 	wrap: true,
    // 	width: "180px",
    // 	sortable: true,
    // 	sortFunction: (a, b) => customStringSort(a, b, "remarks"),
    // },
    // {
    // 	name: "Type",
    // 	selector: (row) => <div className="text-center">{row.type}</div>,
    // 	wrap: true,
    // 	width: "180px",
    // 	sortable: true,
    // 	sortFunction: (a, b) => customStringSort(a, b, "type"),
    // },
    // {
    // 	name: "Objective",
    // 	selector: (row) => (
    // 		<div className="text-center">{row.objective}</div>
    // 	),
    // 	wrap: true,
    // 	width: "180px",
    // 	sortable: true,
    // 	sortFunction: (a, b) => customStringSort(a, b, "objective"),
    // },
    {
      name: "Treatment Amount",
      selector: (row) => row.spendingAmount?.toLocaleString(),
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "spendingAmount"),
    },
    {
      name: "Revenue Amount",
      selector: (row) => row.revenue?.toLocaleString(),
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "revenue"),
    },
    {
      name: "Join Date",
      selector: (row) =>
        new Date(row.joinDate)?.toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "joinDate"),
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = influencers
    ?.map((e, index) => {
      let spendingAmount = 0;
      let revenueAmount = 0;
      e.billing_details?.map((billing) => {
        if (billing?.billing_id?.paymentRecord?.length > 0) {
          const lastPaymentRecord = billing.billing_id.paymentRecord[billing.billing_id.paymentRecord.length - 1];
          if ((lastPaymentRecord.payed >= 0 && billing.billing_id.totalAmount >= 0) && (lastPaymentRecord.payed > billing.billing_id.totalAmount)) {
            revenueAmount += billing.billing_id?.totalAmount ?? 0;
          } else if ((lastPaymentRecord.payed < 0 && billing.billing_id.totalAmount < 0) && (Math.abs(lastPaymentRecord.payed) > Math.abs(billing.billing_id.totalAmount))) {
            revenueAmount += billing.billing_id?.totalAmount ?? 0;
          } else {
            revenueAmount += lastPaymentRecord.payed;
          }
        }
        
        return false;
      });
      e.kol_billings?.map((billing) => {
        if (billing?.paymentRecord?.length > 0) {
          const lastPaymentRecord = billing.paymentRecord[billing.paymentRecord.length - 1];
          if ((lastPaymentRecord.payed >= 0 && billing?.totalAmount >= 0) && (lastPaymentRecord.payed > billing?.totalAmount)) {
            spendingAmount += billing?.totalAmount ?? 0;
          } else if ((lastPaymentRecord.payed < 0 && billing?.totalAmount < 0) && (Math.abs(lastPaymentRecord.payed) > Math.abs(billing?.totalAmount))) {
            spendingAmount += billing?.totalAmount ?? 0;
          } else {
            spendingAmount += lastPaymentRecord.payed;
          }
        }

        return false;
      });

      return {
        id: e._id,
        index: index,
        name: e?.kol_id?.patientProfile?.fullName,
        objective: e?.objective,
        remarks: e?.remarks,
        revenue: revenueAmount,
        spendingAmount: spendingAmount,
        joinDate: new Date(e.created_at).toLocaleDateString(),
        type: e?.kol_type,
        action: (
          <div className="d-flex">
            {/* <ComponentWrapepr
							moduleName="KOL/Channel"
							permissionType={permissionTypes.UPDATE}
						>
							<div
								className=" cursor-pointer icon-width"
								onClick={() => {
									setKolBeingEdit(e?._id);
									setRemarks(e?.remarks);
									setObjective(e?.objective);
									setType(e?.kol_type);
									setOpenModal(true);

									// editKol(e);
								}}
							>
								<i className="fa fa-pen"></i>
							</div>
						</ComponentWrapepr>
						<ComponentWrapepr
							moduleName="KOL/Channel"
							permissionType={permissionTypes.UPDATE}
						>
							<div
								className="ms-3  cursor-pointer icon-width"
								onClick={() => {
									setKolBeingEdit(e?._id);
									setDeleteModal(true);
								}}
							>
								<i className="fa fa-trash"></i>
							</div>
						</ComponentWrapepr> */}
            <div
              className="ms-3 cursor-pointer icon-width"
              onClick={() => navigate(`/kol-details/${e._id}`)}
            >
              <i class="fa-regular fa-eye"></i>
            </div>
          </div>
        ),
      };
    })
    .reverse();

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>KOL/Channel</h1>
          <div className="d-flex">
          <Button
            className="addItem me-3"
            onClick={() => {
              const tempDataArr = data?.map((o) => {
                return {
                  "Patient Name": o.name,
                  "Join Date": new Date(o.joinDate).toLocaleDateString(),
                  "Revenue Amount": o.revenue,
                  "Treatment Amount": o.spendingAmount,
                };
              });
              exportData(tempDataArr, "KOL");
            }}
          >
            Export
          </Button>
            {/* <Button className="addItem" onClick={addKol}>
							+ Add New
						</Button> */}
          </div>
        </div>

        {/* <DeleteModal
					content="Confirm KOL Deletion?"
					onYes={removeKol}
					onNo={handleClose}
					show={show}
				/> */}

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          highlightOnHover
          pointerOnHover
          conditionalRowStyles={conditionalRowStyles}
          onRowClicked={(e) => {
            navigate(`/kol-details/${e.id}`);
          }}
        />
        <>
          <Modal
            centered
            show={openModal}
            onHide={() => {
              setKolBeingEdit();
              setOpenModal(!openModal);
            }}
          >
            <Modal.Header>
              <div className="d-flex justify-content-between w-100">
                <div>
                  <span className="font-600-24 ">Edit Kol</span>
                </div>
                <i
                  className="fa fa-xmark align-self-center pointer"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  style={{
                    fontSize: "30px",
                  }}
                ></i>
              </div>
            </Modal.Header>
            <Modal.Body className="font-18">
              <div>
                <Form.Group>
                  <Form.Label className="black font-400-20">
                    Objective
                  </Form.Label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      name={"remarks"}
                      as="textarea"
                      rows={2}
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      onChange={(e) => {
                        setObjective(e.target.value);
                      }}
                      value={objective}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black font-400-20">Type</Form.Label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      name={"remarks"}
                      // as="textarea"
                      // rows={2}
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      value={type}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black font-400-20">Remarks</Form.Label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      name={"remarks"}
                      as="textarea"
                      rows={2}
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      onChange={(e) => {
                        setRemarks(e.target.value);
                      }}
                      value={remarks}
                    />
                  </InputGroup>
                </Form.Group>
                <Button className="addItem" onClick={editKol}>
                  Save
                </Button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            centered
            show={deleteModal}
            onHide={() => {
              setKolBeingEdit();
              setDeleteModal(!deleteModal);
            }}
          >
            <Modal.Body className="font-18">
              Confirm Kol Deletion
              <div className="align-self-end m-3 d-flex">
                <Button
                  className="me-3 delete-no ms-auto"
                  onClick={() => {
                    setDeleteModal(!deleteModal);
                  }}
                >
                  No
                </Button>
                <Button
                  className="delete-yes"
                  onClick={() => {
                    removeKol();
                    setDeleteModal(!deleteModal);
                  }}
                >
                  Yes
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      </div>
    </>
  );
}
