import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import { getKolById } from "../../../apis/patientKols";
import { toastify } from "../../../helperFunctions/toastify";
import {
  customDateSort,
  customStringSort,
  getInvoiceId,
} from "../../../helperFunctions/utils";

import { thousandAndDecimalFormat } from "../../../utils/common";

export default function KolDetails() {
  const params = useParams();
  const navigate = useNavigate();

  const [kolData, setKolData] = useState({});

  useEffect(() => {
    (async () => {
      const { result, error } = await getKolById(params.id);
      if (error) {
        toastify(error.message ?? "Failed to get influencer data", "error");
        return;
      }
      // console.log("asdasd", result.data);
      setKolData(result.data);
    })();
  }, []);

  const spending_columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "date"),
    },
    {
      name: "Invoice No",
      selector: (row) => row.invoiceNo,
      wrap: true,
    },
    {
      name: "Amount",
      cell: (row) => <div>{thousandAndDecimalFormat(row.amount, true, 2)}</div>,
      wrap: true,
      sortable: true,
      // sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: "Amount GST",
      //   selector: (row) => row.gst,
      cell: (row) => <div> {thousandAndDecimalFormat(row.gst, true, 2)}</div>,
      wrap: true,
      sortable: true,
      // sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: "Amount Paid",
      cell: (row) => <div>{thousandAndDecimalFormat(row.amountPaid, true, 2)}</div>,
      wrap: true,
      sortable: true,
      // sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
  ];
  const spending_data = kolData?.kol_billings?.map((o, index) => {
    const lastPaymentRecord = o.paymentRecord[o.paymentRecord.length - 1];

    return {
      index: index,
      date: new Date(o?.created_at).toLocaleDateString(),
      invoiceNo: getInvoiceId(o?.invoiceNo),
      amount: o?.totalAmount,
      gst: o?.gst,
      amountPaid: lastPaymentRecord?.payed,
    };
  });

  const revenue_columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "date"),
    },
    {
      name: "Patient Name",
      selector: (row) => row.patientName,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "patientName"),
    },
    {
      name: "Invoice No.",
      selector: (row) => row.invoice,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "invoice"),
    },
    {
      name: "Amount",
      cell: (row) => <div>{thousandAndDecimalFormat(row.amount, true, 2)}</div>,
      wrap: true,
      sortable: true,
      // sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: "Amount GST",
      //   selector: (row) => row.gst,
      cell: (row) => <div> {thousandAndDecimalFormat(row.gst, true, 2)}</div>,
      wrap: true,
      sortable: true,
      // sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: "Amount Paid",
      cell: (row) => <div>{thousandAndDecimalFormat(row.amountPaid, true, 2)}</div>,
      wrap: true,
      sortable: true,
      // sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
  ];
  const revenue_data = kolData?.billing_details
    ?.filter((o) => o.isFirst)
    ?.map((o, index) => {
      const lastPaymentRecord = o.billing_id.paymentRecord[o.billing_id.paymentRecord.length - 1];

      return {
        index: index,
        invoice: getInvoiceId(o?.billing_id?.invoiceNo),
        billing_id: o._id,
        date: new Date(o?.billing_id?.created_at).toLocaleDateString(),
        patientName: o?.billing_id?.patient?.patientProfile?.fullName,
        amount: o?.billing_id?.totalAmount,
        amountPaid: lastPaymentRecord?.payed,
        gst: o?.billing_id?.gst,
      };
    });
  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  const getRevenueAmount = () => {
    let total = 0;

    kolData?.billing_details
      ?.filter((o) => o.isFirst)
      ?.map((billing) => {
        if (billing?.billing_id?.paymentRecord?.length > 0) {
          const lastPaymentRecord = billing.billing_id.paymentRecord[billing.billing_id.paymentRecord.length - 1];
          if ((lastPaymentRecord.payed >= 0 && billing.billing_id.totalAmount >= 0) && (lastPaymentRecord.payed > billing.billing_id.totalAmount)) {
            total += billing.billing_id?.totalAmount ?? 0;
          } else if ((lastPaymentRecord.payed < 0 && billing.billing_id.totalAmount < 0) && (Math.abs(lastPaymentRecord.payed) > Math.abs(billing.billing_id.totalAmount))) {
            total += billing.billing_id?.totalAmount ?? 0;
          } else {
            total += lastPaymentRecord.payed;
          }
        }
      });

    return total;
  };

  const getSpendingAmount = () => {
    let total = 0;

    kolData?.kol_billings?.map((billing) => {
      if (billing?.paymentRecord?.length > 0) {
        const lastPaymentRecord = billing.paymentRecord[billing.paymentRecord.length - 1];
        if (lastPaymentRecord.payed > billing.totalAmount) {
          total += billing?.totalAmount ?? 0;
        } else {
          total += lastPaymentRecord.payed;
        }
      }
    });

    return total;
  };

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4 justify-content-start">
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          ></i>
          <h1 className="ms-3">Influencer Detail</h1>
        </div>
        <div className="kol-body" style={{ background: "white" }}>
          <div className="row px-4 justify-content-between py-3">
            <Col
              md={3}
              className="d-flex justify-content-between my-2 px-3 py-2"
              style={{ background: "#F8F8F8" }}
            >
              <div>
                <b>Username</b>
              </div>
              <div>{kolData?.kol_id?.patientProfile?.fullName}</div>
            </Col>
            <Col
              md={3}
              style={{ background: "#F8F8F8" }}
              className="d-flex justify-content-between my-2 px-3 py-2"
            >
              <div>
                <b>Account Number</b>
              </div>
              <div>{kolData?.kol_id?.accountNumber}</div>
            </Col>
            <Col
              md={3}
              style={{ background: "#F8F8F8" }}
              className="d-flex justify-content-between my-2 px-3 py-2"
            >
              <div>
                <b>Contact No</b>
              </div>
              <div>{kolData?.kol_id?.patientProfile?.contactNo}</div>
            </Col>
          </div>
          <div className="row px-4 justify-content-between py-3">
            <Col
              md={3}
              className="d-flex justify-content-between my-2 px-3 py-2"
              style={{ background: "#F8F8F8" }}
            >
              <div>
                <b>Treatment Amount</b>
              </div>
              <div>{getSpendingAmount()?.toLocaleString()}</div>
            </Col>
            <Col
              md={3}
              style={{ background: "#F8F8F8" }}
              className="d-flex justify-content-between my-2 px-3 py-2"
            >
              <div>
                <b>Revenue Amount</b>
              </div>
              <div>{getRevenueAmount()?.toLocaleString()}</div>
            </Col>
            <Col md={3}></Col>
          </div>
          <div className="font-500-20 grey py-1 px-4">
            <b>Treatment Amount</b>
          </div>
          <div className="mt-3">
            <DataTable
              columns={spending_columns}
              data={spending_data || []}
              pagination
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
            />
          </div>
          <div className="font-500-20 grey py-1 px-4">
            <b>Revenue</b>
          </div>
          <div className="mt-3">
            <DataTable
              columns={revenue_columns}
              data={revenue_data || []}
              pagination
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
            />
          </div>
        </div>
      </div>
    </>
  );
}
