import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getTreatments,
  updateTreatments,
  migrateTreatments,
} from "../../../../apis/treatment";
import { getBranches } from "../../../../apis/branch";
import { toastify } from "../../../../helperFunctions/toastify";
import {
  customNumberSort,
  customStringSort,
  exportData,
} from "../../../../helperFunctions/utils";
import DeleteModal from "../../../SharedComponents/DeleteModal";
import FilterComponent from "../../../SharedComponents/FilterComponent";

import { styled } from "@mui/material/styles";
import { Button as MuiButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import SaveIcon from "@mui/icons-material/Save";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function Treatments() {
  const navigate = useNavigate();
  const location = useLocation();
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  const [treatments, setTreatments] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");

  const [loading, setLoading] = React.useState(false);
  const [firstRun, setFirstRun] = useState(false);

  const handleFileChange = (event) => {
    console.log('File input changed'); // This should log when a file is selected
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      let formData = new FormData();
      formData.append("csvFile", selectedFile);
      console.log('Form data prepared:', formData); // This should log the FormData object
  
      migrateTreatments(formData).then((res) => {
        getTreatmentsList();
        setLoading(false);
        toastify(res, "success");
      });
    } else {
      console.log('No file selected'); // This should log if no file is selected
    }
  };

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const getBranchesMap = async () => {
    const response = await getBranches();
    if (response?.status !== "Fail") {
      return response.reduce((map, branch) => {
        map[branch._id] = {
          name: branch.name,
          locations: branch.location, // assuming location is an array or string
        };
        return map;
      }, {});
    } else {
      toastify(response?.message, "error");
      return {};
    }
  };

  const getTreatmentsList = async () => {
    setLoading(true);
  
    const branchMap = await getBranchesMap(); // Fetch and map branches
    const response = await getTreatments();
    
    if (response?.status !== "Fail") {
      const treatmentsWithNames = response.map(treatment => {
        const branch = branchMap[treatment.branch];
        return {
          ...treatment,
          branchName: branch?.name || "Unknown Branch",
          locationName: branch?.locations?.find(loc => loc._id === treatment.location)?.name || "Unknown Location",
        };
      });
  
      setTreatments(treatmentsWithNames);
    } else {
      if (response?.message === "No data Found") {
        setTreatments([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  
    setLoading(false);
  };

  const getDefaultFilteredData = (values) => {
    let list = [...values];
    const fieldPathname = pathname.replace("/", "");
  
    if (tableSearch[fieldPathname]) {
      if (tableSearch[fieldPathname].Name) {
        list = list.filter(v => 
          v.name?.toLowerCase()?.includes(tableSearch[fieldPathname].Name.toLowerCase())
        );
      }
      if (tableSearch[fieldPathname].Branch) {
        list = list.filter(v => 
          v.branchName?.toLowerCase()?.includes(tableSearch[fieldPathname].Branch.toLowerCase())
        );
      }
      if (tableSearch[fieldPathname].Location) {
        list = list.filter(v => 
          v.locationName?.toLowerCase()?.includes(tableSearch[fieldPathname].Location.toLowerCase())
        );
      }
    }
  
    return list;
  };

  // const getTreatmentsList = async () => {
  //   const response = await getTreatments();
  //   console.log("DEBUG", response, tableSearch);
  //   if (response?.status !== "Fail") {
  //     setTreatments(response.reverse());
  //   } else {
  //     if (response?.message === "No data Found") {
  //       setTreatments([]);
  //     } else {
  //       toastify(response?.message, "error");
  //     }
  //   }
  // };

  // const getDefaultFilteredData = (values) => {
  //   let list = [...values];
  //   const fieldPathname = pathname.replace("/", "");

  //   if (tableSearch[fieldPathname]) {
  //     if (tableSearch[fieldPathname].Name) {
  //       list = values.filter(v => v.name?.toLowerCase()?.includes(tableSearch[fieldPathname].Name.toLowerCase()));
  //     }
  //   }

  //   return list;
  // } 

  const removeTreatment = async () => {
    setShow(false);
    let response = await updateTreatments(deleting, { isDeleted: true });
    if (response?.status !== "Fail") {
      getTreatmentsList();
      setFilteredData((prev) => prev.filter((e) => e._id !== deleting));
      toastify("Treatment deleted successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const editUom = (e) => {
    navigate("/add-inventory-treatments", {
      state: {
        treatment: e,
      },
    });
  };

  const addUom = () => {
    navigate("/add-inventory-treatments");
  };

  useEffect(() => {
    getTreatmentsList();
  }, []); 

  const columns = [
    {
      name: "Code",
      selector: (row) => row.code,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "code"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly28">
          <span className="me-2">Name</span>
          {
            <FilterComponent
              type="search"
              array={treatments}
              setArray={setFilteredData}
              value="Name"
              searchValue={tableSearch[fieldPathname]?.Name}
            />
          }
        </div>
      ),
      selector: (row) => row.name,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: "Treatment Type",
      selector: (row) => row.tagButtons,
    },
    {
      name: "Price",
      selector: (row) => row.price?.toLocaleString(),
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "price"),
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = getDefaultFilteredData(treatments)?.map(
    (e, index) => {
      return {
        id: e._id,
        name: e.name,
        price: e.price,
        code: e.code,
        consents: e.consents,
        branch: e.branchName,
        location: e.locationName,
        index: index,
        description: e.description,
        tag: e?.tag?.name,
        isActive: e?.isActive,
        tagButtons: (
          <div
            className="rounded-button"
            style={{ width: 110, background: e?.tag?.color }}
          >
            {e?.tag?.name?.length > 9
              ? e?.tag?.name?.substring(0, 6) + "..."
              : e?.tag?.name}
          </div>
        ),
        action: (
          <div className="d-flex">
            <div
              className="mx-3  cursor-pointer icon-width"
              onClick={() => {
                editUom(e);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div
              className="mx-3  cursor-pointer icon-width"
              onClick={() => {
                setDeleting(e._id);
                setShow(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>Treatments</h1>
          <div className="d-flex">
            <Button
              className="addItem me-3"
              onClick={() => {
                const tempDataArr = data?.map((e) => {
                  return {
                    Code: e.code?.toString().trim() || "", // Ensure it's a string
                    Name: e.name?.toString().trim() || "",
                    Description: e.description?.toString().trim() || "",
                    Tag: e?.tag?.toString().trim() || "",
                    Price: parseFloat(e.price) || 0, // Ensure it's a number
                    Branch: e.branch?.toString().trim() || "",
                    Location: e.location?.toString().trim() || "", // Ensure it's a string
                  };
                });
                exportData(tempDataArr, "Treatments", "Treatments");
              }}
            >
              Export
              </Button>
            {loading ? (
              <MuiButton
                className="addItem me-3"
                component="label"
                role={undefined}
                variant="contained"
                sx={{ backgroundColor: "#295381" }}
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                disabled
              >
                Import
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              </MuiButton>
            ) : (
              <MuiButton
                className="addItem me-3"
                component="label"
                role={undefined}
                variant="contained"
                sx={{ backgroundColor: "#295381" }}
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Import
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              </MuiButton>
            )}
            <Button className="addItem" onClick={addUom}>
              + Add New
            </Button>
          </div>
        </div>

        <DeleteModal
          content="Confirm Treatment Deletion?"
          onYes={removeTreatment}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
}
