import React, { useEffect, useState, useRef } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import DateInput from "../../SharedComponents/DateInput";
import { PREFERRED_CONTACTS } from "../../../constants";
import SelectComponent from "../../SharedComponents/SelectComponent";
import AsyncSelect from "../../SharedComponents/AsyncSelect";
import {
    hearAboutUs, getPatients
} from "../../../apis/patients";
import { Country } from "country-state-city";
import { getKolOptions } from "../../../apis/optionKol";
import { toastify } from "../../../helperFunctions/toastify";
import { getUOM } from "../../../apis/uom";
import { getProductCategory } from "../../../apis/productCategory";
import { getSuppliers } from "../../../apis/supplier";
import { getCampaigns } from "../../../apis/campaign";
import { getCategoryCost } from "../../../apis/categoryCost";
import { getStaff } from "../../../apis/staff";
import { loadOptionPatients, loadSelectedPatient } from "../../../apis/patients";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setTableSearch } from "../../../redux/slices/commonSlice";

export default function Filters({ id, columns, model, array, setArray, templateDetail, filteredArray, handleExport }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const buttonRef = useRef();

    const { tableSearch } = useSelector((state) => state.commonData);
    const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");


    const getValueRedux = (field, defaultValue) => {
        if (tableSearch[fieldPathname]?.[id]) {
            const selectedField = tableSearch[fieldPathname]?.[id]?.find(f => f.field === field);
            if (selectedField) {
                return selectedField.value ? selectedField.value : defaultValue;
            } else {
                return defaultValue;
            }
        } else {
            return defaultValue;
        }
    }

    const [filters, setFilters] = useState(tableSearch[fieldPathname]?.[`${id}`] ? tableSearch[fieldPathname]?.[`${id}`] : []);
    const [preferredContact, setPreferredContact] = useState();
    const [contactOptions, setContactOptions] = useState(PREFERRED_CONTACTS);
    const [hearingOptions, setHearingOptions] = useState([]);
    const [source, setSource] = useState(getValueRedux("patientProfile.hearAboutUs", null));
    const [country, setCountry] = useState();
    const [countries, setCountries] = useState();
    const [selectedPatient, setSelectedPatient] = useState(getValueRedux("patient", null));
    const [patients, setPatients] = useState([]);
    const [selectedKol, setSelectedKol] = useState();
    const [kolOptions, setKolOptions] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([])
    const [name, setName] = useState(getValueRedux("name", ""));
    const [allUoms, setAllUoms] = useState([]);
    const [uom, setUom] = useState("");
    const [allTypes, setAllTypes] = useState([]);
    const [type, setType] = useState(getValueRedux("type", ""));
    const [allSuppliers, setAllSuppliers] = useState([]);
    const [supplier, setSupplier] = useState();
    const [startDate, setStartDate] = useState(getValueRedux("startDate", null));
    const [endDate, setEndDate] = useState(getValueRedux("endDate", null));
    const [otherStartDate, setOtherStartDate] = useState()
    const [otherEndDate, setOtherEndDate] = useState()
    const [selectedCampaign, setSelectedCampaign] = useState()
    const [campaigns, setCampaigns] = useState([])
    const [selectedCatogoryCost, setSelectedCategoryCost] = useState()
    const [categoryCost, setCategoryCost] = useState([])
    const [selectedSessionStatus, setSelectedSessionStatus] = useState()
    const [sessionStatuses, setSessionStatuses] = useState([{ id: 1, name: 'Completed' }, { id: 2, name: "Pending" }, { id: 3, name: "Rejected" }])
    const [selectedAppointmentStatus, setSelectedAppointmentStatus] = useState()
    const [appointmentStatuses, setAppointmentStatuses] = useState([{ id: 1, name: "Planned" }, { id: 2, name: 'Not Show' }, { id: 3, name: "Confirmed" }, { id: 4, name: "Cancelled" }])
    const [sessionStaff, setSessionStaff] = useState([])
    const [selectedSessionStaff, setSelectedSessionStaff] = useState();
    const [enableDate, setEnableDate] = useState(false);
    const [isMember, setIsMember] = useState(getValueRedux("isMember", false));
    const [isPaymentCompleted, setIsPaymentCompleted] = useState(getValueRedux("isPaymentCompleted", false));

    const onSubmitRedux = (objFilter) => {
		dispatch(setTableSearch({
		  ...tableSearch,
			[fieldPathname]: {
				...tableSearch[fieldPathname],
                [id]: objFilter
			}
		}));
    }

    const filterOptions = columns?.map((o) => { return { ...o, label: o.name, id: o._id, value: o._id } })
    const populateHearAboutUs = async () => {
        const response = await hearAboutUs();
        setHearingOptions(response);
    };
    const populatePatients = async () => {
        const response = await getPatients();
        setPatients(response?.list);
    };
    const populateCampaigns = async () => {
        const response = await getCampaigns();
        setCampaigns(response);
    };
    const populateCategoryCost = async () => {
        const response = await getCategoryCost();
        setCategoryCost(response);
    };
    const populateKolOptions = async () => {
        const response = await getKolOptions();
        if (response?.status !== "Fail") {
            setKolOptions(response);
        }
    };

    const populateProductsdata = async () => {
        const responses = await Promise.all([
            getUOM(),
            getProductCategory(),
            getSuppliers(),
        ]);
        if (responses[0].length) setAllUoms(responses[0]);
        if (responses[1].length) setAllTypes(responses[1]);
        if (responses[2].length) setAllSuppliers(responses[2]);
    };

    const populateSessionStaff = async () => {
        const response = await getStaff();
        setSessionStaff(response);
    };


    useEffect(() => {
        if (model === "patients") {
            populateHearAboutUs();
            const allCountries = Country.getAllCountries();
            setCountries(allCountries)
            populatePatients()
            populateKolOptions()
        }
        if (model === "products") {
            populateProductsdata()
        }
        if (model === "payments") {
            populatePatients()
            populateCampaigns()
            populateCategoryCost()
        }
        if (model === "sessions") {
            populateSessionStaff()
            populatePatients()
        }
        if (model === "appointments") {
            populateSessionStaff()
            populatePatients()
        }
    }, [model])

    useEffect(() => {
        let appliedFilters = [];
        columns.forEach((c) => {
            if (selectedFilters.includes(c._id)) {
                appliedFilters.push(c)
            }
        })
        const matched = []
        for (let filter of filters) {
            for (let appl of appliedFilters) {
                if (filter.field === appl.column) {
                    matched.push(filter)
                }
            }
        }
        setFilters(matched)
    }, [selectedFilters])

    useEffect(() => {
        setFilters(tableSearch[fieldPathname]?.[`${id}`] ? tableSearch[fieldPathname]?.[`${id}`] : []);
        setSessionStaff([]);
        setCategoryCost([]);
        setCampaigns([]);
        setAllSuppliers([]);
        setAllTypes([]);
        setAllUoms([]);
        setKolOptions([]);
        setPatients([]);
        setHearingOptions([]);
        setSelectedPatient(getValueRedux("patient", null));
        setStartDate(getValueRedux("startDate", null));
        setEndDate(getValueRedux("endDate", null));
        setOtherStartDate(null);
        setOtherEndDate(null);
        setType(getValueRedux("type", ""));
        setName(getValueRedux("name", ""));
        setUom(null);
        setSelectedKol(null);
        setSupplier(null);
        setSelectedCategoryCost(null);
        setSelectedSessionStatus(null);
        setSelectedSessionStaff(null);
        setSelectedCampaign(null);
        setIsMember(getValueRedux("isMember", false));
        setSource(getValueRedux("patientProfile.hearAboutUs", null));
        setIsPaymentCompleted(getValueRedux("isPaymentCompleted", false));
    }, [id, columns]);

    const genderRadio = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                Gender
            </Form.Label>
            <Form.Check
                inline
                label="Male"
                type={"radio"}
                name="gender"
                onChange={() => {
                    let field = `patientProfile.gender`;
                    let value = 'Male'
                    let _filters = [...filters].filter(e => e.field !== 'patientProfile.gender')
                    _filters.push({ field, value })
                    setFilters(_filters)
                }}
            />
            <Form.Check
                inline
                label="Female"
                type={"radio"}
                name="gender"
                onChange={() => {
                    let field = `patientProfile.gender`;
                    let value = 'Female'
                    let _filters = [...filters].filter(e => e.field !== 'patientProfile.gender')
                    _filters.push({ field, value })
                    setFilters(_filters)
                }}
            />
            <Form.Check
                inline
                label="Both"
                type={"radio"}
                name="gender"
                onChange={() => {
                    let _filters = [...filters].filter(e => e.field !== 'patientProfile.gender')
                    setFilters(_filters)
                }}
            />
        </Form.Group>
    );
    // const patientIsMember = (
    //     <Form.Group className="col-3">
    //         <Form.Label className="headings2 d-flex color-black">
    //             Member
    //         </Form.Label>
    //         <Form.Check
    //             inline
    //             label="Yes"
    //             type={"radio"}
    //             name="isMember"
    //             checked={isMember}
    //             onChange={() => {
    //                 let field = `isMember`;
    //                 let value = true
    //                 let _filters = [...filters].filter(e => e.field !== 'isMember')
    //                 _filters.push({ field, value })
    //                 setFilters(_filters)
    //             }}
    //         />
    //         <Form.Check
    //             inline
    //             label="No"
    //             type={"radio"}
    //             name="isMember"
    //             checked={!isMember}
    //             onChange={() => {
    //                 let field = `isMember`;
    //                 let value = false
    //                 let _filters = [...filters].filter(e => e.field !== 'isMember')
    //                 _filters.push({ field, value })
    //                 setFilters(_filters)
    //             }}
    //         />
    //         <Form.Check
    //             inline
    //             label="Both"
    //             type={"radio"}
    //             name="isMember"
    //             onChange={() => {
    //                 let _filters = [...filters].filter(e => e.field !== 'isMember')
    //                 setFilters(_filters)
    //             }}
    //         />
    //     </Form.Group>

    // );

    const patientIsMember = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                Member
            </Form.Label>
            <Form.Check
                inline
                label="Yes"
                type={"radio"}
                name="isMember"
                checked={isMember === true}
                onChange={() => {
                    setIsMember(true); // Set isMember to true when "Yes" is clicked
                    let field = 'isMember';
                    let value = true;
                    let _filters = [...filters].filter(e => e.field !== field);
                    _filters.push({ field, value });
                    setFilters(_filters);
                }}
            />
            <Form.Check
                inline
                label="No"
                type={"radio"}
                name="isMember"
                checked={isMember === false}
                onChange={() => {
                    setIsMember(false); // Set isMember to false when "No" is clicked
                    let field = 'isMember';
                    let value = false;
                    let _filters = [...filters].filter(e => e.field !== field);
                    _filters.push({ field, value });
                    setFilters(_filters);
                }}
            />
            <Form.Check
                inline
                label="Both"
                type={"radio"}
                name="isMember"
                checked={isMember === null} // Optionally manage a state for "Both"
                onChange={() => {
                    let _filters = [...filters].filter(e => e.field !== 'isMember');
                    setFilters(_filters); // Clear the filter when "Both" is selected
                    setIsMember(null); // Optionally reset isMember to null or leave it unchanged
                }}
            />
        </Form.Group>
    );

    const prefferedContactComponent = (
        <div className="col-3">
            <Form.Group className="">
                <Form.Label className="headings2 d-flex color-black">
                    Preferred Contact
                </Form.Label>
                <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={preferredContact ?? null}
                    onChange={(e) => {
                        let val = e.value;
                        if (val === "" || val === null) {
                            setPreferredContact();
                            let _filters = [...filters].filter(e => e.field !== 'patientProfile.preferredContact')
                            setFilters(_filters)
                        } else {
                            let field = `patientProfile.preferredContact`;
                            let value = e.value
                            let _filters = [...filters].filter(e => e.field !== 'patientProfile.preferredContact')
                            _filters.push({ field, value })
                            setFilters(_filters)
                            setPreferredContact(e.value);
                        }
                    }}
                    options={
                        contactOptions?.length > 0
                            ? [
                                ...contactOptions?.map((e) => {
                                    return {
                                        value: e,
                                        label: e,
                                    };
                                }),
                            ]
                            : []
                    }
                />
            </Form.Group>
        </div>
    )
    const hearAboutUsComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                How Did You Hear About Us?
            </Form.Label>
            <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={source ?? null}
                onChange={(e) => {
                    let val = e.value;
                    if (val === "" || val === null) {
                        setSource();
                        let _filters = [...filters].filter(e => e.field !== 'patientProfile.hearAboutUs')
                        setFilters(_filters)
                    } else {
                        setSource(e.value);
                        let field = `patientProfile.hearAboutUs`;
                        let value = e.value
                        let _filters = [...filters].filter(e => e.field !== 'patientProfile.hearAboutUs')
                        _filters.push({ field, value })
                        setFilters(_filters)
                    }
                }}
                options={
                    hearingOptions?.length > 0
                        ? [
                            ...hearingOptions?.map((e) => {
                                return {
                                    value: e.name,
                                    label: e.name,
                                };
                            }),
                        ]
                        : []
                }
            />
        </Form.Group>
    )
    const countryComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                Country
            </Form.Label>
            <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={country ? JSON.stringify(country) : null}
                onChange={(e) => {
                    let val = e.value;
                    if (val === "" || val === null) {
                        setCountry();
                        let _filters = [...filters].filter(e => e.field !== 'patientProfile.country.name')
                        setFilters(_filters)
                    } else {
                        setCountry(JSON.parse(val));
                        let field = `patientProfile.country.name`;
                        let name = (JSON.parse(val)?.name)
                        let value = name;
                        let _filters = [...filters].filter(e => e.field !== 'patientProfile.country.name')
                        _filters.push({ field, value })
                        setFilters(_filters)
                    }
                }}
                options={
                    countries?.length > 0
                        ? [
                            ...countries?.map((e) => {
                                return {
                                    value: JSON.stringify({
                                        name: e.name,
                                        isoCode: e.isoCode,
                                    }),
                                    label: e.name,
                                };
                            }),
                        ]
                        : []
                }
            />
        </Form.Group>
    )
    const recommendFromComponent = (data, heading) => (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                {heading ? heading : 'Recommend From'}
            </Form.Label>
            <AsyncSelect 
                value={selectedPatient}
                setValue={(e) => {
                    let val = e._id;
                    if (val === "" || val === null) {
                        setSelectedPatient();
                        let _filters = [...filters].filter(e => e.field !== data)
                        setFilters(_filters)
                    } else {
                        setSelectedPatient(e._id);
                        let field = data;
                        let value = e.patientProfile?.fullName;
                        let _filters = [...filters].filter(e => e.field !== data && e.field !== "patientFullName");
                        _filters.push({ field, value: e._id }, { field: "patientFullName", value });
                        setFilters(_filters)
                    }
                }}
                loadOptions={
                    (search, loadedOptions, { page }) => loadOptionPatients(
                        search, 
                        loadedOptions, 
                        { page }
                    )
                }
                loadSelectedOption={loadSelectedPatient}
            />
            {/* <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={selectedPatient ?? null}
                onChange={(e) => {
                    let val = e.value;
                    if (val === "" || val === null) {
                        setSelectedPatient();
                        let _filters = [...filters].filter(e => e.field !== data)
                        setFilters(_filters)
                    } else {
                        setSelectedPatient(e.value);
                        let field = data;
                        let value = e.value
                        let _filters = [...filters].filter(e => e.field !== data)
                        _filters.push({ field, value })
                        setFilters(_filters)
                    }
                }}
                options={
                    patients.length > 0
                        ? [
                            ...patients?.map((e) => {
                                return {
                                    value: e.patientProfile.fullName,
                                    label: e.patientProfile
                                        .fullName,
                                };
                            }),
                        ]
                        : []
                }
            /> */}
        </Form.Group>
    )
    const patientKolComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">KOL/Channel</Form.Label>
            <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={selectedKol ?? null}
                onChange={(e) => {
                    let val = e.value;
                    if (val === "" || val === null) {
                        setSelectedKol();
                        let _filters = [...filters].filter(e => e.field !== 'patientProfile.kol')
                        setFilters(_filters)
                    } else {
                        setSelectedKol(e.value);
                        let field = `patientProfile.kol`;
                        let value = e.value
                        let _filters = [...filters].filter(e => e.field !== 'patientProfile.kol')
                        _filters.push({ field, value })
                        setFilters(_filters)
                    }
                }}
                options={
                    kolOptions?.length > 0
                        ? [
                            ...kolOptions?.map((e) => {
                                return {
                                    value: e.name,
                                    label: e.name,
                                };
                            }),
                        ]
                        : []
                }
            />
        </Form.Group>
    )
    const productNameComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">Name</Form.Label>
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder="Write..."
                    onChange={(e) => {
                        let val = e.target.value;
                        if (val === "" || val === null) {
                            setName("")
                            let _filters = [...filters].filter(e => e.field !== 'name')
                            setFilters(_filters)
                        } else {
                            setName(e.target.value)
                            let field = `name`;
                            let value = e.target.value
                            let _filters = [...filters].filter(e => e.field !== 'name')
                            _filters.push({ field, value })
                            setFilters(_filters)
                        }
                    }}
                    value={name}
                />
            </InputGroup>
        </Form.Group>
    )
    const productUOMComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                UOM
            </Form.Label>
            <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={uom}
                options={[
                    ...allUoms?.map((o) => {
                        return {
                            value: o.name,
                            label: o.name,
                        };
                    }),
                ]}
                onChange={(e) => {
                    let val = e.value;
                    if (val === "" || val === null) {
                        setUom();
                        let _filters = [...filters].filter(e => e.field !== 'uom')
                        setFilters(_filters)
                    } else {
                        setUom(e.value);
                        let field = `uom`;
                        let value = e.value
                        let _filters = [...filters].filter(e => e.field !== 'uom')
                        _filters.push({ field, value })
                        setFilters(_filters)
                    }

                }}
                name={"uom"}
            />
        </Form.Group>
    )
    const productTypeComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                Type
            </Form.Label>
            <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={type}
                options={[
                    ...allTypes?.map((o) => {
                        return {
                            value: o.name,
                            label: o.name,
                        };
                    }),
                ]}
                onChange={(e) => {
                    let val = e.value;
                    if (val === "" || val === null) {
                        setType();
                        let _filters = [...filters].filter(e => e.field !== 'type')
                        setFilters(_filters)
                    } else {
                        setType(e.value);
                        let field = `type`;
                        let value = e.value
                        let _filters = [...filters].filter(e => e.field !== 'type')
                        _filters.push({ field, value })
                        setFilters(_filters)
                    }

                }}
                name={"type"}
            />
        </Form.Group>
    )
    const datesComponent = (data) => (
        templateDetail?.isDisabledFilterDate === true ? (
            <></>
        ) : (
            <>
                <Form.Group className="col-3">
                    <Form.Label className="headings2 d-flex color-black">
                        Start Date
                    </Form.Label>
                    <DateInput
                        value={startDate}
                        placeholder="Select"
                        onChange={(e) => {
                            let val = e.value;
                            if (val === "" || val === null) {
                                setStartDate();
                                let _filters = [...filters].filter(e => e.field !== data)
                                setFilters(_filters)
                            } else {
                                setStartDate(e);
                                let field = data;
                                let value = e;
                                let _filters = [...filters].filter(e => e.field !== data && e.field !== "startDate")
                                _filters.push({ field, value }, { field: "startDate", value })
                                setFilters(_filters)
                            }
    
                        }}
                    />
                </Form.Group>
                <Form.Group className="col-3">
                    <Form.Label className="headings2 d-flex color-black">
                        End Date
                    </Form.Label>
                    <DateInput
                        value={endDate}
                        placeholder="Select"
                        onChange={(e) => {
                            let val = e.value;
                            if (val === "" || val === null) {
                                setEndDate();
                                let _filters = [...filters].filter(e => e.field !== data)
                                setFilters(_filters)
                            } else {
                                setEndDate(e);
                                let field = data;
                                let value = e;
                                let _filters = [...filters].filter(e => e.field !== data && e.field !== "endDate")
                                _filters.push({ field, value }, { field: "endDate", value })
                                setFilters(_filters)
                            }
                        }}
                    />
                </Form.Group>
            </>
        )
    )
    const otherDatesComponent = (data) => (
        <>
            <Form.Group className="col-3">
                <Form.Label className="headings2 d-flex color-black">
                    Start Date
                </Form.Label>
                <DateInput
                    value={otherStartDate}
                    placeholder="Select"
                    onChange={(e) => {
                        let val = e.value;
                        if (val === "" || val === null) {
                            setOtherStartDate();
                            let _filters = [...filters].filter(e => e.field !== data)
                            setFilters(_filters)
                        } else {
                            setOtherStartDate(e);
                            let field = data;
                            let value = e;
                            let _filters = [...filters].filter(e => e.field !== data)
                            _filters.push({ field, value })
                            setFilters(_filters)
                        }

                    }}
                />
            </Form.Group>
            <Form.Group className="col-3">
                <Form.Label className="headings2 d-flex color-black">
                    End Date
                </Form.Label>
                <DateInput
                    value={otherEndDate}
                    placeholder="Select"
                    onChange={(e) => {
                        let val = e.value;
                        if (val === "" || val === null) {
                            setOtherEndDate();
                            let _filters = [...filters].filter(e => e.field !== data)
                            setFilters(_filters)
                        } else {
                            setOtherEndDate(e);
                            let field = data;
                            let value = e;
                            let _filters = [...filters].filter(e => e.field !== data)
                            _filters.push({ field, value })
                            setFilters(_filters)
                        }
                    }}
                />
            </Form.Group>
        </>
    )
    const productSupplierComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                Supplier
            </Form.Label>
            <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={supplier}
                options={[
                    ...allSuppliers?.map((o) => {
                        return {
                            value: o.name,
                            label: o.name,
                        };
                    }),
                ]}
                onChange={(e) => {
                    let val = e.value;
                    if (val === "" || val === null) {
                        setSupplier();
                        let _filters = [...filters].filter(e => e.field !== 'supplier')
                        setFilters(_filters)
                    } else {
                        setSupplier(e.value);
                        let field = `supplier`;
                        let value = e.value
                        let _filters = [...filters].filter(e => e.field !== 'supplier')
                        _filters.push({ field, value })
                        setFilters(_filters)
                    }

                }}
                name={"supplier"}
            />
        </Form.Group>
    )
    const statusComponent = (data) => (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                Status
            </Form.Label>
            <Form.Check
                inline
                label="True"
                type={"radio"}
                name="status"
                onChange={() => {
                    let field = data;
                    let value = true;
                    let _filters = [...filters].filter(e => e.field !== data)
                    _filters.push({ field, value })
                    setFilters(_filters)
                }}
            />
            <Form.Check
                inline
                label="False"
                type={"radio"}
                name="status"
                onChange={() => {
                    let field = data;
                    let value = false
                    let _filters = [...filters].filter(e => e.field !== data)
                    _filters.push({ field, value })
                    setFilters(_filters)
                }}
            />
            <Form.Check
                inline
                label="Both"
                type={"radio"}
                name="status"
                onChange={() => {
                    let _filters = [...filters].filter(e => e.field !== data)
                    setFilters(_filters)
                }}
            />
        </Form.Group>
    );
    const campaignComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                Campaigns
            </Form.Label>
            <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={selectedCampaign ?? null}
                onChange={(e) => {
                    let val = e.value;
                    if (val === "" || val === null) {
                        setSelectedCampaign();
                        let _filters = [...filters].filter(e => e.field !== 'campaign')
                        setFilters(_filters)
                    } else {
                        setSelectedCampaign(e.value);
                        let field = `campaign`;
                        let value = e.value
                        let _filters = [...filters].filter(e => e.field !== 'campaign')
                        _filters.push({ field, value })
                        setFilters(_filters)
                    }
                }}
                options={
                    campaigns?.length > 0
                        ? [
                            ...campaigns?.map((e) => {
                                return {
                                    value: e.name,
                                    label: e.name,
                                };
                            }),
                        ]
                        : []
                }
            />
        </Form.Group>
    )
    const categoryCostComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                Category Cost
            </Form.Label>
            <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={selectedCatogoryCost ?? null}
                onChange={(e) => {
                    let val = e.value;
                    if (val === "" || val === null) {
                        setSelectedCategoryCost();
                        let _filters = [...filters].filter(e => e.field !== 'categoryCosts')
                        setFilters(_filters)
                    } else {
                        setSelectedCategoryCost(e.value);
                        let field = `categoryCosts`;
                        let value = e.value
                        let _filters = [...filters].filter(e => e.field !== 'categoryCosts')
                        _filters.push({ field, value })
                        setFilters(_filters)
                    }
                }}
                options={
                    categoryCost?.length > 0
                        ? [
                            ...categoryCost?.map((e) => {
                                return {
                                    value: e.name,
                                    label: e.name,
                                };
                            }),
                        ]
                        : []
                }
            />
        </Form.Group>

    )
    const sessionStatusComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                Status
            </Form.Label>
            <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={selectedSessionStatus ?? null}
                onChange={(e) => {
                    let val = e.value;
                    if (val === "" || val === null) {
                        setSelectedSessionStatus();
                        let _filters = [...filters].filter(e => e.field !== 'status')
                        setFilters(_filters)
                    } else {
                        setSelectedSessionStatus(e.value);
                        let field = `status`;
                        let value = e.value
                        let _filters = [...filters].filter(e => e.field !== 'status')
                        _filters.push({ field, value })
                        setFilters(_filters)
                    }
                }}
                options={
                    sessionStatuses?.length > 0
                        ? [
                            ...sessionStatuses?.map((e) => {
                                return {
                                    value: e.name,
                                    label: e.name,
                                };
                            }),
                        ]
                        : []
                }
            />
        </Form.Group>
    )
    const appointmentStatusComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                Status
            </Form.Label>
            <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={selectedAppointmentStatus ?? null}
                onChange={(e) => {
                    let val = e.value;
                    if (val === "" || val === null) {
                        setSelectedAppointmentStatus();
                        let _filters = [...filters].filter(e => e.field !== 'status')
                        setFilters(_filters)
                    } else {
                        setSelectedAppointmentStatus(e.value);
                        let field = `status`;
                        let value = e.value
                        let _filters = [...filters].filter(e => e.field !== 'status')
                        _filters.push({ field, value })
                        setFilters(_filters)
                    }
                }}
                options={
                    appointmentStatuses?.length > 0
                        ? [
                            ...appointmentStatuses?.map((e) => {
                                return {
                                    value: e.name,
                                    label: e.name,
                                };
                            }),
                        ]
                        : []
                }
            />
        </Form.Group>
    )
    const sessionStaffComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                Staff
            </Form.Label>
            <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={selectedSessionStaff ?? null}
                onChange={(e) => {
                    let val = e.value;
                    if (val === "" || val === null) {
                        setSelectedSessionStaff();
                        let _filters = [...filters].filter(e => e.field !== 'staff')
                        setFilters(_filters)
                    } else {
                        setSelectedSessionStaff(e.value);
                        let field = `staff`;
                        let value = e.value
                        let _filters = [...filters].filter(e => e.field !== 'staff')
                        _filters.push({ field, value })
                        setFilters(_filters)
                    }
                }}
                options={
                    sessionStaff?.length > 0
                        ? [
                            ...sessionStaff?.map((e) => {
                                return {
                                    value: e.username,
                                    label: e.username,
                                };
                            }),
                        ]
                        : []
                }
            />
        </Form.Group>
    )

    // const isPaymentCompletedComponent = (
    //     <Form.Group className="col-3">
    //         <Form.Label className="headings2 d-flex color-black">
    //             Is Payment Completed ?
    //         </Form.Label>
    //         <Form.Check
    //             inline
    //             label="Yes"
    //             type={"radio"}
    //             name="isPaymentCompleted"
    //             checked={isPaymentCompleted}
    //             onChange={() => {
    //                 let field = `isPaymentCompleted`;
    //                 let value = true
    //                 let _filters = [...filters].filter(e => e.field !== 'isPaymentCompleted')
    //                 _filters.push({ field, value })
    //                 setFilters(_filters)
    //             }}
    //         />
    //         <Form.Check
    //             inline
    //             label="No"
    //             type={"radio"}
    //             name="isPaymentCompleted"
    //             checked={!isPaymentCompleted}
    //             onChange={() => {
    //                 let field = `isPaymentCompleted`;
    //                 let value = false
    //                 let _filters = [...filters].filter(e => e.field !== 'isPaymentCompleted')
    //                 _filters.push({ field, value })
    //                 setFilters(_filters)
    //             }}
    //         />
    //         <Form.Check
    //             inline
    //             label="Both"
    //             type={"radio"}
    //             name="isPaymentCompleted"
    //             onChange={() => {
    //                 let _filters = [...filters].filter(e => e.field !== 'isPaymentCompleted')
    //                 setFilters(_filters)
    //             }}
    //         />
    //     </Form.Group>

    // );

    const isPaymentCompletedComponent = (
        <Form.Group className="col-3">
            <Form.Label className="headings2 d-flex color-black">
                Is Payment Completed?
            </Form.Label>
            <Form.Check
                inline
                label="Yes"
                type={"radio"}
                name="isPaymentCompleted"
                checked={isPaymentCompleted === true} // Check if isPaymentCompleted is true
                onChange={() => {
                    setIsPaymentCompleted(true); // Update state to true
                    const field = 'isPaymentCompleted';
                    const value = true;
                    const _filters = [...filters].filter(e => e.field !== field); // Remove existing filter
                    _filters.push({ field, value }); // Add new filter
                    setFilters(_filters);
                }}
            />
            <Form.Check
                inline
                label="No"
                type={"radio"}
                name="isPaymentCompleted"
                checked={isPaymentCompleted === false} // Check if isPaymentCompleted is false
                onChange={() => {
                    setIsPaymentCompleted(false); // Update state to false
                    const field = 'isPaymentCompleted';
                    const value = false;
                    const _filters = [...filters].filter(e => e.field !== field); // Remove existing filter
                    _filters.push({ field, value }); // Add new filter
                    setFilters(_filters);
                }}
            />
            <Form.Check
                inline
                label="Both"
                type={"radio"}
                name="isPaymentCompleted"
                checked={isPaymentCompleted === null} // Optionally handle neutral state for "Both"
                onChange={() => {
                    const _filters = [...filters].filter(e => e.field !== 'isPaymentCompleted'); // Remove filter
                    setFilters(_filters); // Clear the filter
                    setIsPaymentCompleted(null); // Optionally reset state for "Both"
                }}
            />
        </Form.Group>
    );
    const handleSubmit = () => {
        const haveDate = checkIfHaveDate();

        let newData = [...array];
        filters.forEach(data => {
            if (data.field === 'isMember') {
                newData = newData.filter(e => e.isMember === data.value)
            }
            if (data.field === 'patientProfile.gender') {
                newData = newData.filter(e => e.patientProfile.gender === data.value)
            }
            if (data.field === 'patientProfile.preferredContact') {
                newData = newData.filter(e => e.patientProfile.preferredContact === data.value)
            }
            if (data.field === 'patientProfile.hearAboutUs') {
                newData = newData.filter(e => e.patientProfile.hearAboutUs?.[0]?.name === data.value)
            }
            if (data.field === 'patientProfile.country.name') {
                newData = newData.filter(e => e.patientProfile.country?.name === data.value)
            }
            if (data.field === 'patientProfile.recommendFrom') {
                newData = newData.filter(e => e.patientProfile.recommendFrom === data.value)
            }
            if (data.field === 'patientProfile.kol') {
                newData = newData.filter(e => e.patientProfile.kol === data.value)
            }
            ///// Products /////
            if (model === "products") {
                if (data.field === 'name') {
                    newData = newData.filter(e => e.name === data.value)
                }
                if (data.field === 'uom') {
                    newData = newData.filter(e => e.uom[0].name === data.value)
                }
                if (data.field === 'type') {
                    newData = newData.filter(e => e.type[0].name === data.value)
                }
                if (data.field === 'supplier') {
                    newData = newData.filter(e => e.supplier[0]?.name === data.value)
                }
                if (data.field === 'expDate') {
                    if (!startDate || !endDate) {
                        toastify(!startDate ? "Please select start date" : "Please select end date", "error")
                    }
                    newData = newData.filter(e => new Date(e.expDate) >= new Date(startDate) && new Date(e.expDate) <= new Date(endDate))
                }
            }
            if (model === "packages") {
                if (data.field === 'effectiveDate') {
                    if (!startDate || !endDate) {
                        toastify(!startDate ? "Please select start date" : "Please select end date", "error")
                    }
                    newData = newData.filter(e => new Date(e.effectiveDate) >= new Date(startDate) && new Date(e.effectiveDate) <= new Date(endDate))
                }
                if (data.field === 'expiredDate') {
                    if (!otherStartDate || !otherEndDate) {
                        toastify(!otherStartDate ? "Please select start date" : "Please select end date", "error")
                    }
                    newData = newData.filter(e => new Date(e.expiredDate) >= new Date(otherStartDate) && new Date(e.expiredDate) <= new Date(otherEndDate))
                }
                if (data.field === 'isActive') {
                    newData = newData.filter(e => e.isActive === data.value)
                }
            }
            //////// Payments   ///////
            if (model === "payments") {
                if (data.field === 'isPayed') {
                    newData = newData.filter(e => e.isPayed === data.value)
                }
                if (data.field === 'isPaymentCompleted') {
                    newData = newData.filter(e => e.isPaymentCompleted === data.value)
                }
                if (data.field === 'recommend') {
                    newData = newData.filter(e => e.recommend === data.value)
                }
                if (data.field === 'patient') {
                    const patientFullName = filters.find(f => f.field === "patientFullName");
                    if (patientFullName?.value) {
                        newData = newData.filter(e => e.patient[0].patientProfile?.fullName === patientFullName?.value)
                    }
                }
                if (data.field === 'campaign') {
                    newData = newData.filter(e => e.campaign[0]?.name === data.value)
                }
                if (data.field === 'categoryCosts') {
                    newData = newData.filter(e => e.categoryCosts?.find(c => c.name === data.value))
                }
            }
            /////// Waiting List /////
            if (model === "waitinglists") {
                if (data.field === 'date') {
                    if (!startDate || !endDate) {
                        toastify(!startDate ? "Please select start date" : "Please select end date", "error")
                    }
                    newData = newData.filter(e => new Date(e.date) >= new Date(startDate) && new Date(e.date) <= new Date(endDate))
                }
            }
            /////// sessions /////
            if (model === "sessions") {
                if (data.field === 'date') {
                    if (!startDate || !endDate) {
                        toastify(!startDate ? "Please select start date" : "Please select end date", "error")
                    }
                    newData = newData.filter(e => new Date(e.date) >= new Date(startDate) && new Date(e.date) <= new Date(endDate))
                }
                if (data.field === 'status') {
                    newData = newData.filter(e => e.status === data.value)
                }
                if (data.field === 'staff') {
                    newData = newData.filter(e => e.staff?.find(c => c.username === data.value))
                }
            }
            /////// Appointments /////
            if (model === "appointments") {
                if (data.field === 'date') {
                    if (!startDate || !endDate) {
                        toastify(!startDate ? "Please select start date" : "Please select end date", "error")
                    }
                    newData = newData.filter(e => new Date(e.date) >= new Date(startDate) && new Date(e.date) <= new Date(endDate))
                }
                if (data.field === 'staff') {
                    newData = newData.filter(e => e.staff?.find(c => c.username === data.value))
                }
                if (data.field === 'patient') {
                    newData = newData.filter(e => e.patient[0].patientProfile.fullName === data.value)
                }
                if (data.field === 'status') {
                    newData = newData.filter(e => e.status === data.value)
                }
            }
            if (haveDate === false) {
                if (startDate && endDate) {
                    newData = newData.filter(e => {
                        const createdDate = new Date(e.created_at);
                        createdDate.setHours(0, 0, 0, 0);

                        return createdDate >= new Date(startDate) && createdDate <= new Date(endDate);
                    })
                }
            }
        })

        setArray(newData)
        if (!newData.length) {
            toastify("No results to show of these filters", "error");
        } else {
            onSubmitRedux(filters);
        }
    }

    const checkIfHaveDate = () => {
        let haveDate = false;

        if (columns.filter(c => 
            (c.column === "expDate" || 
            c.column === "effectiveDate" ||
            c.column === "expiredDate" ||
            c.column === "date") &&
            c.filter === true
        ).length > 0) {
            haveDate = true;
        }

        return haveDate;
    }

    return (
        <React.Fragment>
            <div className="col-12">
                <Row>
                    {
                        !checkIfHaveDate() && (
                            datesComponent("createdAt")
                        )
                    }
                    {/* {
                        filterOptions?.length && filterOptions?
                        <>
                        <Form.Group className=" mt-3 col-5">
                            <Form.Label className="black-label-text">
                                Select Filters
                            </Form.Label>
                            <SelectComponent
                                searchEnabled
                                isNullEnabled
                                array={selectedFilters}
                                options={filterOptions?.length && filterOptions}
                                placeholder="Write..."
                                onChange={(e) => {
                                    setSelectedFilters(e);
                                }}
                                name={"filters"}
                            />
                        </Form.Group>
                        </>:<></>
                    } */}
                        
                        {columns?.map((e) => {
                            if (!e.filter) {
                                return null;
                            }

                            let column = e.column;
                            if (model === "patients") {
                                switch (column) {
                                    case "patientProfile.gender":
                                        return genderRadio;
                                    case "isMember":
                                        return patientIsMember;
                                    case "patientProfile.preferredContact":
                                        return prefferedContactComponent;
                                    case "patientProfile.hearAboutUs":
                                        return hearAboutUsComponent;
                                    case "patientProfile.country.name":
                                        return countryComponent;
                                    case "patientProfile.recommendFrom":
                                        return recommendFromComponent("patientProfile.recommendFrom");
                                    case "patientProfile.kol":
                                        return patientKolComponent;
                                    case "name":
                                        return productNameComponent;
                                    default:
                                        return null;
                                }
                            }
            
                            ////// Products ////
                            if (model === "products") {
                                switch (column) {
                                    case "name":
                                        return productNameComponent;
                                    case "uom":
                                        return productUOMComponent;
                                    case "type":
                                        return productTypeComponent;
                                    case "supplier":
                                        return productSupplierComponent;
                                    case "expDate":
                                        return datesComponent("expDate");
                                    default:
                                        return null;
                                }
                            }
            
                            /////// Package ////
                            if (model === "packages") {
                                switch (column) {
                                    case "effectiveDate":
                                        return datesComponent("effectiveDate");
                                    case "expiredDate":
                                        return otherDatesComponent("expiredDate");
                                    case "isActive":
                                        return statusComponent("isActive");
                                    default:
                                        return null;
                                }
                            }
            
                            //////// Payments /////
                            if (model === "payments") {
                                switch (column) {
                                    case "isPayed":
                                        return statusComponent("isPayed");
                                    case "isPaymentCompleted":
                                        return isPaymentCompletedComponent;
                                    case "recommend":
                                        return recommendFromComponent("recommend");
                                    case "patient":
                                        return recommendFromComponent("patient", "Patient");
                                    case "campaign":
                                        return campaignComponent;
                                    case "categoryCosts":
                                        return categoryCostComponent
                                    default:
                                        return null;
                                }
                            }
                            ////// Waiting List ////
                            if (model === "waitinglists") {
                                switch (column) {
                                    case "date":
                                        return datesComponent("date");
                                    default:
                                        return null;
                                }
                            }
                            ////// Sessions ////
                            if (model === "sessions") {
                                switch (column) {
                                    case "date":
                                        return datesComponent("date");
                                    case "status":
                                        return sessionStatusComponent;
                                    case "staff":
                                        return sessionStaffComponent;
                                    case "appointment":
                                        return recommendFromComponent("appointment", "Patient");
                                    default:
                                        return null;
                                }
                            }
                            ////// Appointments ////
                            if (model === "appointments") {
                                switch (column) {
                                    case "date":
                                        return datesComponent("date");
                                    case "staff":
                                        return sessionStaffComponent;
                                    case "patient":
                                        return recommendFromComponent("patient", "Patient");
                                    case "status":
                                        return appointmentStatusComponent;
                                    default:
                                        return null;
                                }
                            }
            
                        })}
                        {
                        filterOptions?.length && filterOptions?
                        <>
                        </>:<></>
                    }
                </Row>
            </div>

            <div className="mb-3 w-100 d-flex" style={{alignItems: "center"}}>
                <Button id="btn-search-template" className="addItem me-3" onClick={handleSubmit}>Submit</Button>

                <Button
                    className="addItem"
                    onClick={() => handleExport()}
                >
                    Export
                </Button>
            </div>
        </React.Fragment>
    );
}



/*
remaining working
=> patients in sssions
=> tags in appointments
*/