import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { modifyPatientMembership } from "../../../apis/patients";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";
import { setPatientData } from "../../../redux/slices/patientSlice";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import DateInput from "../../SharedComponents/DateInput";

export default function TabMembershipInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const patientData = useSelector((state) => state.patientData.value);
  const numberRegex = new RegExp(`^[0-9]*$`);
  console.log(patientData);
  const [points, setPoints] = useState("");
  const [expiry, setExpiry] = useState();
  const [show, setShow] = useState(false);
  const savePatient = async () => {
    if (!expiry) {
      missingPopup("Expiry Date");
    } else if (!points) {
      missingPopup("Membership Point");
    } else {
      let membershipInfo = {
        points: points,
        pointsExpiry: new Date(expiry),
      };
      const response = await modifyPatientMembership(
        patientData._id,
        membershipInfo
      );
      if (response?.status !== "Fail") {
        dispatch(setPatientData(response));
        toastify("Membership Info added successfully!", "success");
        // navigate("/patient");
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const populateData = () => {
    setPoints(patientData?.points || 0);
    setExpiry(new Date(patientData?.pointsExpiry));
  };

  useEffect(() => {
    if (patientData?.points) populateData();
  }, []);

  const handleClickAway = () => {
    setShow(false);
  };

  return (
    <div>
      <div className="d-flex">
        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Loyalty Points</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="number"
              placeholder="Write..."
              value={parseInt(points)}
              onChange={(e) => {
                const numberValue = parseInt(e.target.value);
                setPoints(numberValue);
              }}
            />
          </InputGroup>
        </Form.Group>

        <span className="col-1" />

        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Expiry Date</Form.Label>
          <DateInput
            value={expiry ? new Date(expiry).toLocaleDateString() : null}
            placeholder="Expiry Date"
            onChange={(e) => {
              const parsedDate = new Date(e);
              const displayFormat = `${parsedDate.getFullYear()}-${(
                parsedDate.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}-${parsedDate
                .getDate()
                .toString()
                .padStart(2, "0")}`;
              setExpiry(displayFormat);
            }}
          />
        </Form.Group>
      </div>

      <Button className="addItem" onClick={savePatient}>
        Save
      </Button>
    </div>
  );
}
