export const thousandAndDecimalFormat = (data, allowDecimal, decimalScale) => {
  if (data == null || data === "") return 0;

  const decimalPoint = allowDecimal ? (decimalScale ? decimalScale : 0) : 0;
  let formattedValue;

  if (isNaN(data)) {
    formattedValue = "0";
  } else {
    const number = Number.parseFloat(data);
    if (decimalScale > 0) {
      formattedValue = number
        .toFixed(decimalScale)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      formattedValue = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  // Remove unnecessary trailing zeros after decimal if allowDecimal is true
  if (allowDecimal && decimalScale > 0) {
    formattedValue = formattedValue
      .replace(/\.0+$/, "")
      .replace(/(\.\d*?)0+$/, "$1");
  }

  return formattedValue;
};
