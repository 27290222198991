import { authorizedRequests } from "../axios/axios-config";
import api from "../axios/axios-config";
import { getInvoiceId } from "../helperFunctions/utils";

export const getBillings = async () => {
  try {
    let response = await api.get(
      "payment/listOfPayments",

      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getPaginateBillings = async (query) => {
  try {
    let response = await api.get("payment/listOfPaginatePayments", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: query,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getPaginateBillingsData = async (query) => {
  console.log("Inside getPaginateBillingsData, Query:", query);
  try {
    let response = await api.get("payment/listOfPaginatePaymentsData", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: query,
    });
    console.log("API Response:", response);
    return response?.data?.data;
  } catch (error) {
    console.error("API Error:", error?.response?.data);
    return error?.response?.data;
  }
};


export const getPaymentByBillingId = async (data) => {
  try {
    const response = await authorizedRequests({
      url: "payment/getPaymentByBillingId",
      method: "post",
      data: data,
    });

    return response?.result?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getReferrals = async () => {
  try {
    let response = await api.get(
      "payment/listReferral",

      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getBillingByPatient = async (id, needMigrate) => {
  const { error, result } = await authorizedRequests({
    url: "payment/listOfPaymentsByPatientId",
    method: "post",
    data: {
      patientId: id,
      needMigrate: needMigrate
    },
  });
  if (error) {
    return { error };
  }
  return { result: result.data };
};

export const getBillingByPatients = async (ids, needMigrate) => {
  const { error, result } = await authorizedRequests({
    url: "payment/listOfPaymentsByPatientIds",
    method: "post",
    data: {
      patientIds: ids,
      needMigrate: needMigrate
    },
  });
  if (error) {
    return { error };
  }
  return { result: result.data };
};

export const getBillingByStaffId = async (id) => {
  const response = await authorizedRequests({
    url: "payment/listOfPaymentsByStaffId",
    method: "post",
    data: {
      staffId: id,
    },
  });
  return response;
};

export const updateBilling = async (data) => {
  const response = await authorizedRequests({
    url: "payment/updatePayment",
    method: "post",
    data,
  });
  return response;
};

export const createTranserProduct = async (data) => {
  const response = await authorizedRequests({
    url: "payment/createTransfer",
    method: "post",
    data,
  });
  return response;
};

export const getTransferByPatientId = async (data) => {
  const response = await authorizedRequests({
    url: "payment/getTransferByPatientId",
    method: "post",
    data,
  });
  return response;
};

export const loadOptionBillings = async (search, loadedOptions, { page, havePaid }) => {
  const limit = 10;

  const params = {
    skip: page ? (page - 1) * limit : 0,
    limit: limit * page,
    invoiceNo: search.replace(/(iv-)/gi, ""),
    enableConversion: true,
    havePaid: havePaid
  };

  const response = await getPaginateBillings(params);
  const options = response?.list?.map((x) => ({
    value: x._id,
    label: getInvoiceId(x.invoiceNo),
    ...x,
  }));

  if (response.status === "Fail") {
    return {
      options: [],
      hasMore: false,
    };
  }

  return {
    options: options,
    hasMore: options.length === limit ? true : false,
    additional: {
      page: page ? page + 1 : 0,
    },
  };
};

export const loadOptionBillingsData = async (search) => {
  console.log("Inside loadOptionBillingsData, Search:", search);

  const params = {
    invoiceNo: search,
    enableConversion: true,
    havePaid: true
  };

  console.log("Params before API call:", params);

  const response = await getPaginateBillingsData(params);

  console.log("Response received:", response);

  if (!response || response.status === "Fail") {
    return {
      invoice: null,
    };
  }

  return response[0];
};


export const loadSelectedBilling = async (value) => {
  const response = await getPaymentByBillingId({ _id: value });

  return {
    label: getInvoiceId(response?.invoiceNo),
    value: response?._id,
    ...response,
  };
};
