import React from "react";
import {
  Page,
  Image,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import _ from "lodash";
import { getInvoicebyId, getInvoiceId } from "../../helperFunctions/utils";

import LogoPNG from "../../assets/images/logo.png";
import { thousandAndDecimalFormat } from "../../utils/common";

const styles = StyleSheet.create({
  page: {
    width: 870,
    height: 1245,
    paddingTop: 35,
    paddingBottom: 35,
    paddingLeft: 50,
    paddingRight: 50,
  },
  title: {
    fontFamily: "Helvetica-Bold",
    fontSize: "11px",
    fontWeight: "bold",
  },
  address: {
    fontSize: "10px",
  },
  taxInvoice: {
    fontSize: "14px",
  },
  logo: {
    width: 160,
    height: 100,
  },
  subtitle: {
    color: "#34556f",
  },
  section: {
    flexGrow: 0,
    flexDirection: "row",
  },
  table: {
    width: "100%",
    borderBottom: "1px solid #34556f",
  },
  text: {
    fontSize: "11px",
    marginBottom: "5px",
    fontFamily: "Helvetica",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: "10px",
  },
  rowContent: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
    alignItems: "center",
    paddingTop: 4,
    paddingBottom: 4,
  },
  rowSubheader: {},
  header: {
    fontSize: "10px",
    backgroundColor: "#989898",
    fontFamily: "Helvetica-Bold",
  },
  subheader: {
    fontSize: "8px",
    backgroundColor: "#eeeeee",
  },
  whiteColor: {
    color: "#ffffff",
  },
  flex: {
    flexGrow: 0,
    flexDirection: "row",
    height: "auto",
  },
  block: {
    display: "block",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  session1: {
    width: "60%",
    marginRight: "20px",
  },
  session2: {
    width: "40%",
  },
  row1: {
    width: "60%",
    marginLeft: "5px",
    marginRight: "5px",
  },
  row2: {
    width: "15%",
    marginLeft: "5px",
    marginRight: "5px",
  },
  row3: {
    width: "10%",
    marginLeft: "5px",
    marginRight: "5px",
  },
  row4: {
    width: "15%",
    marginLeft: "5px",
    marginRight: "5px",
  },
  rowBig: {
    width: "70%",
  },
  rowSmall: {
    width: "40%",
  },
  rowHalf1: {
    width: "65%",
    marginLeft: "5px",
  },
  rowHalf2: {
    width: "35%",
    marginLeft: "5px",
  },
  rowBigger: {
    width: "100%",
    marginLeft: "5px",
  },
  noPdBot: {
    paddingBottom: "0px",
  },
  noBorder: {
    border: "0px",
  },
  textRight: {
    textAlign: "right",
  },
  labelText: {
    width: "60px",
  },
  fullWidthLine: {
    borderBottomWidth: 1,
    borderBottomColor: "#34556f", // Adjust color if needed
    marginTop: 5,
    marginBottom:5,
    width: "100%",
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ billing, setting, usePointVal }) {
  const numberFormatter = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const getDoctorName = () => {
    const selectedStaff = billing?.session?.appointment?.staff?.find(
      (s) => s.department?.name?.toLowerCase() === "doctor"
    );
    if (!selectedStaff) {
      return "-";
    }

    return selectedStaff.username;
  };
  
  const calculateBalancePrice = (totalPrice, balanceQty, totalQty) => {
    const balance_price = (totalPrice / totalQty) * balanceQty;
    return balance_price;
  };

  const packages = billing?.package?.map((o) => {
    return {
      type: "Package",
      name: o?.name ? o?.name : o?.package_id?.name,
      qty: o?.package_qty,
      price: o?.package_unit_price,
      discount: o?.package_discount,
      total_price: o?.package_price,
      discountType: o?.package_discountType,
      promoName: o?.promoName,
      promoDiscountType: o?.promoDiscountType,
      promoDiscountValue: o?.promoDiscountValue,
      remark: o?.package_remark,
      products: o?.package_id?.products?.map((item) => {
        const product_used = o?.products_used?.find(
          (x) => x.id === item?.product?._id
        );
        const finalQty = item.qty * o.package_qty;

        return {
          ...item,
          qty: finalQty,
          total_price:
            product_used?.amount ??
            calculateBalancePrice(
              item.totalPrice,
              item.qty - product_used?.used ?? 0,
              item.qty
            ),
          used: product_used?.used,
          balance:
            finalQty < 0
              ? finalQty + (product_used?.used ?? 0)
              : finalQty - (product_used?.used ?? 0),
        };
      }),
      treatments: o?.package_id?.treatments?.map((item) => {
        const product_used = o?.treatments_used?.find(
          (x) => x.id === item?.treatment?._id
        );
        const finalQty = item.limit * o.package_qty;

        return {
          ...item,
          limit: finalQty,
          total_price:
            product_used?.amount ??
            calculateBalancePrice(
              item.disc_price,
              item.limit - product_used?.used ?? 0,
              item.limit
            ),
          used: product_used?.used,
          balance:
            finalQty < 0
              ? finalQty + (product_used?.used ?? 0)
              : finalQty - (product_used?.used ?? 0),
        };
      }),
    };
  });

  const products = billing?.product?.map((o) => {
    return {
      type: "Product",
      name: o?.name ? o?.name : o?.product_id?.name,
      qty: o?.product_qty,
      price: o?.product_unit_price,
      discount: o?.product_discount,
      total_price: o?.product_price,
      discountType: o?.product_discountType,
      promoName: o?.promoName,
      promoDiscountType: o?.promoDiscountType,
      promoDiscountValue: o?.promoDiscountValue,
      remark: o?.product_remark,
    };
  });
  const treatments = billing?.treatment?.map((o) => {
    return {
      type: "Treatment",
      name: o?.name ? o?.name : o?.service_id?.name,
      qty: o?.service_qty,
      price: o?.service_unit_price,
      discount: o?.service_discount,
      total_price: o?.service_price,
      discountType: o?.service_discountType,
      promoName: o?.promoName,
      promoDiscountType: o?.promoDiscountType,
      promoDiscountValue: o?.promoDiscountValue,
      remark: o?.service_remark,
    };
  });
  const others = billing?.others?.map((o) => {
    return {
      type: "Other",
      name: o?.description,
      qty: 1,
      price: o?.total_price,
      discount: 0,
      total_price: o?.total_price,
    };
  });

  const getPayedAmount = () => {
    if (billing?.paymentRecord?.length > 0) {
      return numberFormatter.format(
        billing?.paymentRecord[billing?.paymentRecord?.length - 1]?.payed
      );
    }

    return 0;
  };

  const getRemainingAmount = () => {
    if (billing?.paymentRecord?.length > 0) {
      return numberFormatter.format(
        billing?.paymentRecord[billing?.paymentRecord?.length - 1]?.remaining
      );
    }

    return 0;
  };

  const getLinkedLastPayedAmount = () => {
    if (billing?.linkedInvoice?.paymentRecord?.length > 0) {
      return numberFormatter.format(
        billing?.linkedInvoice?.paymentRecord[billing?.linkedInvoice?.paymentRecord?.length - 1]?.payed
      );
    }

    return 0;
  }

  const getLinkedLastRemainingAmount = () => {
    if (billing?.linkedInvoice?.paymentRecord?.length > 0) {
      return numberFormatter.format(
        billing?.linkedInvoice?.paymentRecord[billing?.linkedInvoice?.paymentRecord?.length - 1]?.remaining
      );
    }

    return 0;
  }

  const getOutstandingRefund = () => {
    const outstandingPaid = billing?.linkedInvoice?.outstandingPaid ?? 0;
    let remainingAmount = 0;
    if (billing?.paymentRecord?.length > 0) {
      remainingAmount = billing?.paymentRecord[billing?.paymentRecord?.length - 1]?.remaining;
    }

    let linkedLastPayedAmount = 0;
    if (billing?.linkedInvoice?.paymentRecord?.length > 0) {
      linkedLastPayedAmount = billing?.linkedInvoice?.paymentRecord[billing?.linkedInvoice?.paymentRecord?.length - 1]?.payed;
    }

    if (remainingAmount === 0) {
      remainingAmount = billing?.gstAmount;
    }

    const total =  remainingAmount + linkedLastPayedAmount - parseFloat(outstandingPaid);
    if (Math.abs(total) === 0) {
      return 0;
    }
    
    const outstanding = thousandAndDecimalFormat(total, true, 2);

    return outstanding;
  };

  const getOutstandingTopUp = () => {
    let remainingAmount = 0;
    if (billing?.paymentRecord?.length > 0) {
      remainingAmount = billing?.paymentRecord[billing?.paymentRecord?.length - 1]?.remaining;
    }

    let linkedLastRemainingAmount = 0;
    if (billing?.linkedInvoice?.paymentRecord?.length > 0) {
      linkedLastRemainingAmount = billing?.linkedInvoice?.paymentRecord[billing?.linkedInvoice?.paymentRecord?.length - 1]?.remaining;
    }

    const total =  remainingAmount + linkedLastRemainingAmount;
    const outstanding = thousandAndDecimalFormat(Math.abs(total), true, 2);

    return outstanding;
  };

  return (
    <Document>
      <Page break={true} style={styles.page}>
        <View style={{ ...styles.section, marginBottom: "15px" }}>
          <View>
            <Image src={LogoPNG} style={styles.logo} />
          </View>
          <View style={{ textAlign: "right", width: "100%" }}>
            <Text
              style={{ ...styles.text, ...styles.title, marginBottom: "6px" }}
            >
              EPION CLINIC MEDICAL AESTHETICS
            </Text>
            <Text
              style={{
                ...styles.text,
                ...styles.bold,
                fontSize: "11px",
                marginBottom: "6px",
              }}
            >
              Company UEN: {setting?.companyUEN}
            </Text>
            <Text
              style={{
                ...styles.text,
                ...styles.bold,
                fontSize: "11px",
                marginBottom: "6px",
              }}
            >
              GST No: {setting?.companyGST}
            </Text>
            <Text
              style={{ ...styles.text, ...styles.address, marginBottom: "6px" }}
            >
              {setting?.companyAddress}
            </Text>
            <Text style={{ ...styles.text, ...styles.address }}>
              {setting?.companyContactNo} | {setting?.companyEmail}
            </Text>
            <Text style={{ ...styles.text, ...styles.address }}>
              {setting?.companySite}
            </Text>
          </View>
        </View>
        <View style={{ ...styles.section, marginBottom: "10px" }}>
          <View style={styles.session1}>
            <View style={styles.flex}>
              <Text
                style={{ ...styles.text, ...styles.taxInvoice, ...styles.bold }}
              >
                Tax Invoice
              </Text>
            </View>
            <View style={styles.flex}>
              <Text style={{ ...styles.text }}>To :</Text>
              <Text style={styles.text}>{` ${
                billing?.patient?.patientProfile?.fullName ?? "-"
              }`}</Text>
            </View>
            {billing?.patient?.patientProfile?.address && (
              <View style={{ ...styles.flex }}>
                <Text style={styles.text}>
                  {`${
                    billing?.patient?.patientProfile?.country?.name ??
                    "Singapore"
                  }, ${billing?.patient?.patientProfile?.address}`}
                </Text>
              </View>
            )}
          </View>
          <View style={{ ...styles.session2, marginTop: "20px" }}>
            <View style={styles.flex}>
              <Text style={{ ...styles.text, width: "70px" }}>Invoice No </Text>
              <Text style={styles.text}>
                : {getInvoicebyId(billing?.invoiceNo, billing?.isConversion, billing?.isRefund, billing?.originalInvoice)}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text style={{ ...styles.text, width: "70px" }}>
                Invoice Date{" "}
              </Text>
              <Text style={styles.text}>
                :{" "}
                {billing?.date
                  ? moment(billing?.date).format("DD/MM/YYYY")
                  : "-"}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text style={{ ...styles.text, width: "70px" }}>Account No </Text>
              <Text style={styles.text}>
                :{" "}
                {billing?.patient?.accountNumber
                  ? billing?.patient?.accountNumber
                  : "-"}
              </Text>
            </View>
          </View>
        </View>

        <View>
          <Text style={{ ...styles.text, ...styles.bold }}>
            Invoice Details
          </Text>
          <Text style={{ ...styles.text }}>Doctor: {getDoctorName()}</Text>
          <Text style={{ ...styles.text }}>
            Patient:{" "}
            {`${billing?.patient?.patientProfile?.fullName ?? ""} ${
              billing?.patient?.accountNumber ?? "-"
            }`}
          </Text>
        </View>

        <View style={styles.table}>
          <View
            style={{
              ...styles.row,
              ...styles.bold,
              ...styles.header,
            }}
          >
            <Text style={{ ...styles.row1 }}>Description</Text>
            <Text style={{ ...styles.row2 }}>Remark</Text>
            <Text style={{ ...styles.row3, ...styles.textRight }}>Qty</Text>
            <Text style={{ ...styles.row2, ...styles.textRight }}>Promo</Text>
            <Text style={{ ...styles.row4, ...styles.textRight }}>
              Discount
            </Text>
            <Text style={{ ...styles.row4, ...styles.textRight }}>Amount</Text>
          </View>
          {[...packages, ...products, ...treatments, ...others]?.map(
            (item, index) => {
              if (item.type === "Package") {
                return (
                  <View>
                    <View style={styles.rowContent} key={index}>
                      <Text style={{ ...styles.row1 }}>
                        {item.promoName
                          ? `${item.name} (${item.promoName})`
                          : `${item.name}`}
                      </Text>
                      <Text style={{ ...styles.row2 }}>{item.remark}</Text>
                      <Text style={{ ...styles.row3, ...styles.textRight }}>
                        {item.qty}
                      </Text>
                      <Text style={{ ...styles.row2, ...styles.textRight }}>
                        {item.promoDiscountType === "Percentage"
                          ? `${numberFormatter.format(item.promoDiscountValue)}%`
                          : `$${
                              item.promoDiscountValue
                                ? numberFormatter.format(item.promoDiscountValue)
                                : "0"
                            }`}
                      </Text>
                      <Text style={{ ...styles.row4, ...styles.textRight }}>
                        {item.discountType === "Percentage"
                          ? `${numberFormatter.format(item.discount)}%`
                          : `$${item.discount ? numberFormatter.format(item.discount) : "0"}`}
                      </Text>
                      <Text style={{ ...styles.row4, ...styles.textRight }}>
                        $
                        {item.total_price
                          ? Number.isInteger(item.total_price)
                            ? item.total_price.toLocaleString()
                            : item.total_price.toFixed(2).toLocaleString()
                          : "0"}
                        {/* $
                        {item.total_price
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                      </Text>
                    </View>
                  </View>
                );
              } else {
                return (
                  <View style={styles.rowContent} key={index}>
                    <Text style={{ ...styles.row1 }}>
                      {item.promoName
                        ? `${item.name} (${item.promoName})`
                        : `${item.name}`}
                    </Text>
                    <Text style={{ ...styles.row2 }}>{item.remark}</Text>
                    <Text style={{ ...styles.row3, ...styles.textRight }}>
                      {item.qty}
                    </Text>
                    <Text style={{ ...styles.row2, ...styles.textRight }}>
                      {item.promoDiscountType === "Percentage"
                        ? `${numberFormatter.format(item.promoDiscountValue)}%`
                        : `$${
                            item.promoDiscountValue
                              ? numberFormatter.format(item.promoDiscountValue)
                              : "0"
                          }`}
                    </Text>
                    <Text style={{ ...styles.row4, ...styles.textRight }}>
                      {item.discountType === "Percentage"
                        ? `${numberFormatter.format(item.discount)}%`
                        : `$${item.discount ? numberFormatter.format(item.discount) : "0"}`}
                    </Text>
                    <Text style={{ ...styles.row4, ...styles.textRight }}>
                      $
                      {item.total_price
                        ? Number.isInteger(item.total_price)
                          ? item.total_price.toLocaleString()
                          : item.total_price.toFixed(2).toLocaleString()
                        : "0"}
                      {/* {item.total_price
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                    </Text>
                  </View>
                );
              }
            }
          )}
        </View>

        <View style={{ ...styles.row, paddingTop: 0, paddingBottom: 0 }}>
          <View style={{ ...styles.rowBig, width: "60%" }}>
            <Text></Text>
          </View>
          <View style={{ ...styles.rowSmall, width: "40%" }}>
            <View
              style={{ ...styles.row, ...styles.noBorder, ...styles.noPdBot }}
            >
              <Text style={{ ...styles.rowHalf1, ...styles.bold }}>
                Total Items
              </Text>
              <Text
                style={{
                  ...styles.rowHalf2,
                  ...styles.textRight,
                  ...styles.bold,
                }}
              >
                $
                {numberFormatter.format(
                  billing?.totalItemAmount ? billing?.totalItemAmount : 0
                )}
              </Text>
            </View>
            {billing?.paymentAdjustments?.map((adjustment) => (
              <React.Fragment>
                <View
                  style={{
                    ...styles.row,
                    ...styles.noBorder,
                    ...styles.noPdBot,
                  }}
                >
                  <Text style={{ ...styles.rowHalf1 }}>
                    {adjustment.name}{" "}
                    {adjustment?.discountType === "Percentage"
                      ? `(${numberFormatter.format(adjustment.discount)}%)`
                      : ""}
                  </Text>
                  <Text style={{ ...styles.rowHalf2, ...styles.textRight }}>
                    {adjustment.discountType === "Percentage"
                      ? `($${numberFormatter.format(
                          Math.abs(
                            ((adjustment?.discount ?? 0) / 100) *
                              (billing?.totalItemAmount ?? 0)
                          )
                        )})`
                      : `($${numberFormatter.format(
                          Math.abs(adjustment.discount)
                        )})`}
                  </Text>
                </View>
              </React.Fragment>
            ))}
            <View
              style={{
                ...styles.row,
                padding: "0px",
                marginTop: "5px",
                borderTop: "1px solid #ddd",
              }}
            ></View>
            <View
              style={{ ...styles.row, ...styles.noBorder, ...styles.noPdBot }}
            >
              <Text style={{ ...styles.rowHalf1, ...styles.bold }}>
                Sub Total
              </Text>
              <Text
                style={{
                  ...styles.rowHalf2,
                  ...styles.textRight,
                  ...styles.bold,
                }}
              >
                ${numberFormatter.format(billing?.actualAmount)}
              </Text>
            </View>
            {billing?.promo !== 0 && (
              <View
                style={{ ...styles.row, ...styles.noBorder, ...styles.noPdBot }}
              >
                <Text style={styles.rowHalf1}>
                  Discount{" "}
                  {billing?.promoType === "Percentage"
                    ? `(${numberFormatter.format(billing?.promo ?? 0)}%)`
                    : ""}
                </Text>
                <Text style={{ ...styles.rowHalf2, ...styles.textRight }}>
                  {billing?.promoType === "Percentage"
                    ? `($${numberFormatter.format(
                        ((billing?.promo ?? 0) / 100) *
                          (billing?.actualAmount ?? 0)
                      )})`
                    : `($${numberFormatter.format(billing?.promo ?? 0)})`}
                </Text>
              </View>
            )}

            {billing?.usePoints !== 0 && (
              <View
                style={{ ...styles.row, ...styles.noBorder, ...styles.noPdBot }}
              >
                <Text style={styles.rowHalf1}>
                  Used Point {billing?.usePoints ?? 0}
                </Text>
                <Text style={{ ...styles.rowHalf2, ...styles.textRight }}>
                  (${numberFormatter.format(billing?.usePointAmount ?? 0)})
                </Text>
              </View>
            )}
            <View
              style={{
                ...styles.row,
                padding: "0px",
                marginTop: "5px",
                borderTop: "1px solid #ddd",
              }}
            ></View>
            <View
              style={{ ...styles.row, ...styles.noBorder, ...styles.noPdBot }}
            >
              <Text style={{ ...styles.rowHalf1, ...styles.bold }}>GST</Text>
              <Text
                style={{
                  ...styles.rowHalf2,
                  ...styles.textRight,
                  ...styles.bold,
                }}
              >
                $
                {numberFormatter.format(
                  (billing?.gst ?? 0) - (billing?.totalAmount ?? 0)
                )}
              </Text>
            </View>
            <View
              style={{ ...styles.row, ...styles.noBorder, ...styles.noPdBot }}
            >
              <Text style={{ ...styles.rowHalf1, ...styles.bold }}>
                Grant Total
              </Text>
              <Text
                style={{
                  ...styles.rowHalf2,
                  ...styles.textRight,
                  ...styles.bold,
                }}
              >
                ${numberFormatter.format(billing?.gst)}
              </Text>
            </View>
          </View>
        </View>

        {billing?.paymentRecord?.length > 0 && (
          <React.Fragment>
            <View style={{ marginBottom: "0px" }}>
              <Text style={{ ...styles.text, ...styles.bold }}>
                Payment Details
              </Text>
            </View>

            <View style={styles.table}>
              <View
                style={{
                  ...styles.row,
                  ...styles.bold,
                  ...styles.header,
                }}
              >
                <Text style={{ ...styles.row2, width: "30%" }}>Date</Text>
                <Text style={{ ...styles.row1 }}>Mode</Text>
                <Text style={{ ...styles.row2, ...styles.textRight }}>
                  Amount
                </Text>
              </View>

              {[...billing.paymentRecord]?.map((item, index) => (
                <View style={styles.rowContent} key={index}>
                  <Text style={{ ...styles.row2, width: "30%" }}>
                    {moment(item?.date).format("DD/MM/YYYY")}
                  </Text>
                  <Text style={styles.row1}>{item.paymentMethod}</Text>
                  <Text style={{ ...styles.row2, ...styles.textRight }}>
                    ${numberFormatter.format(item.currentPaidAmount)}
                  </Text>
                </View>
              ))}
            </View>

            <View style={{ ...styles.row, paddingBottom: 0, paddingTop: 0 }}>
              <View style={{ ...styles.rowBig, width: "60%" }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.rowSmall, width: "40%" }}>
                <View
                  style={{
                    ...styles.row,
                    ...styles.noBorder,
                    ...styles.noPdBot,
                  }}
                >
                  <Text style={{ ...styles.rowHalf1, ...styles.bold }}>Total Paid</Text>
                  <Text
                    style={{
                      ...styles.rowHalf2,
                      ...styles.textRight,
                      ...styles.bold,
                    }}
                  >
                    ${getPayedAmount()}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ ...styles.row, paddingBottom: 0, paddingTop: 0 }}>
              <View style={{ ...styles.rowBig, width: "60%" }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.rowSmall, width: "40%" }}>
                <View
                  style={{
                    ...styles.row,
                    ...styles.noBorder,
                    ...styles.noPdBot,
                  }}
                >
                  <Text style={{ ...styles.rowHalf1, ...styles.bold }}>Remaining Amount</Text>
                  <Text
                    style={{
                      ...styles.rowHalf2,
                      ...styles.textRight,
                      ...styles.bold,
                    }}
                  >
                    ${getRemainingAmount()}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ ...styles.row, paddingBottom: 0, paddingTop: 0 }}>
              <View style={{ ...styles.rowBig, width: "60%" }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.rowSmall, width: "40%" }}>
                {billing?.isConversion && billing?.linkedInvoice && billing?.linkedInvoice?.status === "Refund" && (
                  <>
                {/* Full-width Horizontal Line */}
                <View style={styles.fullWidthLine} />
                    <View
                      style={{
                        ...styles.row,
                        ...styles.noBorder,
                        ...styles.noPdBot,
                      }}
                    >
                      <Text style={{ ...styles.rowHalf1, ...styles.bold }}>
                        Refunded Amount
                      </Text>
                      <Text
                        style={{
                          ...styles.rowHalf2,
                          ...styles.textRight,
                          ...styles.bold,
                        }}
                      >
                        ${getLinkedLastPayedAmount()}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.row,
                        ...styles.noBorder,
                        ...styles.noPdBot,
                      }}
                    >
                      <Text style={{ ...styles.rowBigger, ...styles.bold }}>
                        Credit Note
                      </Text>
                      <Text
                        style={{
                          ...styles.rowHalf2,
                          ...styles.textRight,
                          ...styles.bold,
                        }}
                      >
                        ${(
                          thousandAndDecimalFormat(billing?.linkedInvoice?.outstandingPaid * -1, true, 2)
                        )}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.row,
                        ...styles.noBorder,
                        ...styles.noPdBot,
                      }}
                    >
                      <Text style={{ ...styles.rowHalf1, ...styles.bold }}>
                        Outstanding
                      </Text>
                      <Text
                        style={{
                          ...styles.rowHalf2,
                          ...styles.textRight,
                          ...styles.bold,
                        }}
                      >
                        ${getOutstandingRefund()}
                      </Text>
                    </View>
                  </>
                )}

{billing?.isConversion && billing?.linkedInvoice && billing?.linkedInvoice?.status === "Conversion Top Up" && (
                  <>
                {/* Full-width Horizontal Line */}
                <View style={styles.fullWidthLine} />
                    <View
                      style={{
                        ...styles.row,
                        ...styles.noBorder,
                        ...styles.noPdBot,
                      }}
                    >
                      <Text style={{ ...styles.rowHalf1, ...styles.bold }}>
                        Top Up Remaining Amount
                      </Text>
                      <Text
                        style={{
                          ...styles.rowHalf2,
                          ...styles.textRight,
                          ...styles.bold,
                        }}
                      >
                        ${getLinkedLastRemainingAmount()}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.row,
                        ...styles.noBorder,
                        ...styles.noPdBot,
                      }}
                    >
                      <Text style={{ ...styles.rowHalf1, ...styles.bold }}>
                        Outstanding
                      </Text>
                      <Text
                        style={{
                          ...styles.rowHalf2,
                          ...styles.textRight,
                          ...styles.bold,
                        }}
                      >
                        ${getOutstandingTopUp()}
                      </Text>
                    </View>
                  </>
                )}
              </View>
            </View>
          </React.Fragment>
        )}

        <View style={{ marginBottom: "10px" }}>
          <Text style={{ ...styles.text, ...styles.bold }}>
            Remarks: {billing?.remarkDiscount}
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.bold }}>
            {setting?.companyInvoiceTemplate}
          </Text>
        </View>
      </Page>
    </Document>
  );
}
