import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { calcTotalWithDisc } from "../../../helperFunctions/utils";
import { ExpandableIcon } from "../../../assets/images/index";

export default function PackagesTable({
  isConversion,
  isEdit,
  sessionInvoices,
  setSessionInvoices,
  setPromo,
  setSubTotal,
  setTotal,
  setTax,
  setUsePoint,
  onRowEdit,
}) {
  console.log("sessionInvoices",sessionInvoices)
	const numberFormatter = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  const [activeIndex, setActiveIndex] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [itemToDel, setItemToDel] = useState();
  const getTotalPrice = (product, e) => {
    const totalPrice = product.total_price * e.qty;
    if (!isConversion) {
      return product.total_price;
    }

    if (isConversion && !e._id) {
      return product.total_price;
    }

    return totalPrice;
  }

  const calculateBalancePrice = (totalPrice, balanceQty, totalQty, packageQty, isExisting) => {
    if (!isConversion) {
      return totalPrice;
    }

    if (isConversion && !isExisting) {
      return totalPrice;
    }
    // if (!isConversion || !isExisting) {
    //   return (totalPrice / totalQty) * balanceQty;
    // }

    const balance_price = (totalPrice / totalQty) * balanceQty;
    return balance_price;
  }

  const calculateTotalItemPackage = (session, isExisting) => {
    let totalItem = 0;
    console.log("session", session)
    session?.treatments?.map((oTreatment) => {
      totalItem += oTreatment.new_price ? Number(oTreatment.new_price) : Number(oTreatment.total_price)
    });
    session?.products?.map((oProduct) => {
      totalItem += oProduct.new_price ? Number(oProduct.new_price) : Number(oProduct.total_price)
    });
    if (!isConversion || !isExisting) {
      return totalItem;
    }

    return Math.abs(totalItem) * -1;
  }

  const calculateTotalPackagePrice = (session, isExisting) => {
    // if (newDiscType === "Percentage") {
    //   const totalPrice = calcTotalWithDisc(
    //     totalItemPrice,
    //     promoDiscountValue,
    //     promoDiscountType
    //   );

    //   const disc_price = totalPrice * (1 - newDisc / 100);
    //   return disc_price;
    // } else {
    //   const disc_price =
    //     calcTotalWithDisc(
    //       totalItemPrice,
    //       promoDiscountValue,
    //       promoDiscountType
    //     );

    //   if (disc_price < 0) {
    //     return disc_price + Number(newDisc);
    //   } else {
    //     return disc_price - Number(newDisc);
    //   }
    // }
    if (session.discountType === "Percentage") {
      const totalPrice = calcTotalWithDisc(
        session.price,
        session.promoDiscountValue,
        session.promoDiscountType
      );
      const disc_price = totalPrice * (1 - Number(session.discount) / 100);

      if (disc_price < 0) {
        return 0;
      }

      if (!isConversion || !isExisting) {
        return disc_price;
      }

      return disc_price * -1;
    } else {
      const disc_price =
        calcTotalWithDisc(
          session.price,
          session.promoDiscountValue,
          session.promoDiscountType
        );
      let total_disc_price = 0;
      if (disc_price < 0) {
        total_disc_price = disc_price + Number(session.discount);
      } else {
        total_disc_price = disc_price - Number(session.discount);
      }

      

      if (!isConversion || !isExisting) {
        if (total_disc_price < 0) {
        return 0;
      }
        return total_disc_price;
      }

      return total_disc_price * -1;
    }
  }

    const calculateTotalPrice = (item, session,) => {
      console.log("session", session)
      const new_price = item.ori_total_price * session.qty
    if (session.discountType == "Amount") {
      const totalPrice = calcTotalWithDisc(
        new_price,
        session.promoDiscountValue,
        session.promoDiscountType
      );
      const validProducts = session.products.filter((product) => product.ori_total_price !== 0);
      const validTreatments = session.treatments.filter((treatment) => treatment.ori_total_price !== 0);

      // Get the total number of valid products and treatments
      const totalItems = validProducts.length + validTreatments.length;
      console.log("totalItems", totalItems)
      
      const discount = session.discount / totalItems
      const new_price_return = totalPrice < 0 ? totalPrice + discount : totalPrice - discount
      return (new_price_return)
    } else if (session.discountType == "Percentage") {
      const totalPrice = calcTotalWithDisc(
        new_price,
        session.promoDiscountValue,
        session.promoDiscountType
      );
      console.log("totalPrice",totalPrice)
      const disc = totalPrice * (session.discount / 100)
      console.log("totalPrice",disc)
      return (new_price - disc)
    } else {
      const totalPrice = calcTotalWithDisc(
        new_price,
        session.promoDiscountValue,
        session.promoDiscountType
      );
      return (totalPrice)
     }
  }

  if (sessionInvoices?.filter((o) => o.type == "PACKAGE")?.length == 0)
    return (
      <div className="w-100 text-center" style={{ padding: "24px" }}>
        There are no records to display
      </div>
    );

  return (
    <>
      <Table>
        <thead
          style={{
            background: "#34556F",
            color: "white",
          }}
        >
          <tr
            style={{
              height: "45px",
            }}
            className={`align-items-center`}
          >
            <th style={{ textAlign: "center", width: "200px" }}>
              <span className="me-2 ps-2">Item Description</span>
            </th>
            <th style={{ textAlign: "center" }}>
              <span className="me-2 ps-2">Quantity</span>
            </th>
            <th style={{ textAlign: "center" }}>
              <span className="me-2">Promo</span>
            </th>
            <th style={{ textAlign: "center" }}>
              <span className="me-2">Discount</span>
            </th>
            {/* <th>Limit</th> */}

            <th style={{ textAlign: "center" }}>Amount</th>
            <th style={{ width: "200px", textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {sessionInvoices.length > 0 &&
            sessionInvoices?.map((e, index) => {
              if (e.type !== "PACKAGE") {
                return <></>;
              }
              return (
                <>
                  <tr
                    style={{
                      backgroundColor: e.isAlreadyPaid ? "#fff9e5" : "inherit",
                    }}
                    // style={
                    // 	e.isAlreadyPaid
                    // 		? { backgroundColor: "#B6ee7a" }
                    // 		: {}
                    // }
                  >
                    <td className="ps-3" style={{ textAlign: "center" }}>
                      {e.isAlreadyPaid ? "Current Package - " : ""}
                      {e.name}
                    </td>
                    <td style={{ textAlign: "center" }}>{Math.abs(e?.qty)}</td>
                    <td style={{ textAlign: "center" }}>
                      {e.promoDiscountType === "Percentage"
                        ? `${e.promoDiscountValue ? e.promoDiscountValue : 0}%`
                        : `$${e.promoDiscountValue ? e.promoDiscountValue : 0}`}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {
                        e.discountType ? (
                          e.discountType === "Percentage"
                            ? `${numberFormatter.format(e.discount)}%`
                            : `$${numberFormatter.format(e.discount)}`
                        ) : (
                          e.package_discountType === "Percentage"
                            ? `${numberFormatter.format(e.discount)}%`
                            : `$${numberFormatter.format(e.discount)}`
                        )
                      }
                    </td>
                    {/* <td className="wd-100">{e.qty}</td> */}
                    <td style={{ textAlign: "center" }}>${numberFormatter.format(Math.abs(e.total_price))}</td>
                    <td style={{ width: "200px" }}>
                      <div className="d-flex ">
                        {
                          
                              <>
                                {isEdit && <div
                                className="cursor-pointer"
                                onClick={() => {
                                  onRowEdit(
                                    index,
                                    e.qty,
                                    e.discount,
                                    e.discountType ? e.discountType : e.package_discountType,
                                    e.price,
                                    e.remark
                                  );
                                }}
                              >
                                <i className="fa fa-pen"></i>
                              </div>}
                              
                            </>
                      
                        }
                      
                        {
                          
                              isEdit &&
                            <div
                              className="ms-3  cursor-pointer icon-width"
                              onClick={() => {
                                setItemToDel(index);
                                setOpenModal(true);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </div>
                          
                        }
                        <div className="ms-2 cursor-pointer icon-width">
                          <img
                            src={ExpandableIcon}
                            className="expand-icon"
                            onClick={(e) => {
                              if (activeIndex == index) {
                                setActiveIndex();
                                return;
                              }
                              setActiveIndex(index);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  {activeIndex === index && (
                    <>
                      <tr>
                        <td
                          colSpan="12"
                          style={{
                            backgroundColor: "#f2f2f2",
                            padding: "20px",
                          }}
                        >
                          <Table>
                            <thead
                              style={{
                                background: "#34556F",
                                color: "white",
                                width: "100%",
                              }}
                            >
                              <tr
                                style={{
                                  height: "45px",
                                }}
                                className="align-items-center
							"
                              >
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  <span className="me-2 ps-2">Type</span>
                                </th>

                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Name
                                </th>
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Quantity
                                </th>
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Used
                                </th>
                                {
                                  (isConversion && !e.isExtra) && (
                                    <th
                                      className="wd-150"
                                      style={{ textAlign: "center" }}
                                    >
                                      Converted
                                    </th>
                                  )
                                }
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Balance
                                </th>
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {e.products?.length > 0 &&
                                e?.products?.map((product, productIndex) => {
                                  const finalQty =
                                    product.qty * (e.qty ? e.qty : 1);

                                  return (
                                    <>
                                      <tr>
                                        <td style={{ textAlign: "center" }}>
                                          Product
                                        </td>

                                        <td style={{ textAlign: "center" }}>
                                          {product.name}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {Math.abs(finalQty)}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <div
                                            className="d-flex"
                                            style={{ justifyContent: "center" }}
                                          >
                                            <div
                                              className="pointer"
                                              onClick={() => {
                                                if (!isEdit) return;
                                                let tempSessions = structuredClone(sessionInvoices); // Ensure deep copy
                                                let tempArr = tempSessions[index]?.products;
                                                if (
                                                  tempArr[productIndex].used ==
                                                  0
                                                )
                                                  return;
                                                tempArr[productIndex].used =
                                                  tempArr[productIndex].used -
                                                  1;

                                                // let balanceQty = (finalQty < 0 ? product.converted * -1 : product.converted);
                                                // if (e.isExtra) {
                                                //   balanceQty = (finalQty < 0
                                                //     ? finalQty + tempArr[productIndex].used
                                                //     : finalQty - tempArr[productIndex].used);
                                                // }

                                                // const product_price = getTotalPrice(product, e);
                                                // const calculatedValue = calculateBalancePrice(product_price, balanceQty, finalQty, e.qty, e._id);

                                                // tempArr[
                                                //   productIndex
                                                // ].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                
                                                // if (isConversion) {
                                                //   const t_price = calculateTotalPrice(product, tempSessions[index])
                                                //   const calculatedValue = calculateBalancePrice(t_price, balanceQty, finalQty, e.qty, e._id);
                                                //   tempArr[productIndex].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                //   const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                //   tempSessions[index].price = totalItemPrice;
                                                //   const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                //   tempSessions[index].total_price = tempSessions[index].price;
                                                // }
                                                tempSessions[index].products =
                                                  [...tempArr];
                                                setSessionInvoices([
                                                  ...tempSessions,
                                                ]);
                                              }}
                                            >
                                              <i
                                                class="fa fa-minus-square"
                                                style={{
                                                  color: "#34556F",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <span className="mx-2">
                                              {product.used}
                                            </span>
                                            <div
                                              className="pointer"
                                              onClick={() => {
                                                if (!isEdit) return;
                                                let tempSessions = structuredClone(sessionInvoices); // Ensure deep copy
                                                let tempArr = tempSessions[index]?.products;

                                                if (
                                                  (tempArr[productIndex].used + tempArr[productIndex].converted) ==
                                                  Math.abs(finalQty)
                                                )
                                                  return;

                                                tempArr[productIndex].used =
                                                  tempArr[productIndex].used +
                                                  1;
                                                
                                                // let balanceQty = (finalQty < 0 ? product.converted * -1 : product.converted);
                                                // if (e.isExtra) {
                                                //   balanceQty = (finalQty < 0
                                                //     ? finalQty + product.used
                                                //     : finalQty - product.used);
                                                // }

                                                // const product_price = getTotalPrice(product, e);
                                                // const calculatedValue = calculateBalancePrice(product_price, balanceQty, finalQty, e.qty, e._id);

                                                // tempArr[
                                                //   productIndex
                                                // ].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);

                                                
                                                // if (isConversion) {
                                                //   const t_price = calculateTotalPrice(product, tempSessions[index])
                                                //   const calculatedValue = calculateBalancePrice(t_price, balanceQty, finalQty, e.qty, e._id);
                                                //   tempArr[productIndex].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                //   const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                //   tempSessions[index].price = totalItemPrice;
                                                //   const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                //   tempSessions[index].total_price = tempSessions[index].price;
                                                // }

                                                tempSessions[index].products =
                                                  [...tempArr];
                                                setSessionInvoices([
                                                  ...tempSessions,
                                                ]);
                                              }}
                                            >
                                              <i
                                                class="fa fa-plus-square"
                                                style={{
                                                  color: "#34556F",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </div>
                                        </td>
                                        {
                                          (isConversion && !e.isExtra) && (
                                            <td style={{ textAlign: "center" }}>
                                              <div
                                                className="d-flex"
                                                style={{ justifyContent: "center" }}
                                              >
                                                <div
                                                  className="pointer"
                                                  onClick={() => {
                                                    if (!isEdit) return;
                                                    let tempSessions = structuredClone(sessionInvoices); // Ensure deep copy
                                                    let tempArr = tempSessions[index]?.products;
                                                    if (
                                                      tempArr[productIndex].converted ==
                                                      0
                                                    )
                                                      return;
                                                    tempArr[productIndex].converted =
                                                      tempArr[productIndex].converted -
                                                      1;
      
                                                    let balanceQty = (finalQty < 0 ? tempArr[productIndex].converted * -1 : tempArr[productIndex].converted);
                                                    if (e.isExtra) {
                                                      balanceQty = (finalQty < 0
                                                        ? finalQty + tempArr[productIndex].converted + tempArr[productIndex].used
                                                        : finalQty - tempArr[productIndex].converted + tempArr[productIndex].used);
                                                    }

                                                    const product_price = getTotalPrice(tempArr[productIndex], e);
                                                    const calculatedValue = calculateBalancePrice(product_price, balanceQty, finalQty, e.qty, e._id);
                                                    console.log("tempArr[productIndex].new_price", tempArr[productIndex].new_price)
                                                    tempArr[
                                                      productIndex
                                                    ].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                    
                                                    if (isConversion && (Math.abs(product.ori_total_price !== 0))) {
                                                      
                                                      const t_price = calculateTotalPrice(product, tempSessions[index])
                                                      console.log("t_price", t_price)
                                                      const calculatedValue = calculateBalancePrice(t_price, balanceQty, finalQty, e.qty, e._id);
                                                      tempArr[productIndex].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                      const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                      tempSessions[index].price = totalItemPrice;
                                                      const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                      tempSessions[index].total_price = tempSessions[index].price;
                                                    }

                                                    tempSessions[index].products =
                                                      [...tempArr];
                                                    setSessionInvoices([
                                                      ...tempSessions,
                                                    ]);
                                                  }}
                                                >
                                                  <i
                                                    class="fa fa-minus-square"
                                                    style={{
                                                      color: "#34556F",
                                                    }}
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                                <span className="mx-2">
                                                  {product.converted}
                                                </span>
                                                <div
                                                  className="pointer"
                                                  onClick={() => {
                                                    if (!isEdit) return;
                                                    let tempSessions = structuredClone(sessionInvoices); // Ensure deep copy
                                                    let tempArr = tempSessions[index]?.products;

                                                    if (
                                                      (tempArr[productIndex].used + tempArr[productIndex].converted) ==
                                                      Math.abs(finalQty)
                                                    )
                                                      return;
                                                    tempArr[productIndex].converted =
                                                      tempArr[productIndex].converted +
                                                      1;
                                                    
                                                    let balanceQty = (finalQty < 0 ? tempArr[productIndex].converted * -1 : tempArr[productIndex].converted);
                                                    if (e.isExtra) {
                                                      balanceQty = (finalQty < 0
                                                        ? finalQty + tempArr[productIndex].converted + tempArr[productIndex].used
                                                        : finalQty - tempArr[productIndex].converted + tempArr[productIndex].used);
                                                    }

                                                    const product_price = getTotalPrice(tempArr[productIndex], e);
                                                    const calculatedValue = calculateBalancePrice(product_price, balanceQty, finalQty, e.qty, e._id);

                                                    tempArr[
                                                      productIndex
                                                    ].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);

                                                    
                                                    if (isConversion && (Math.abs(product.ori_total_price !== 0))) {
                                                      const t_price = calculateTotalPrice(product, tempSessions[index])
                                                      const calculatedValue = calculateBalancePrice(t_price, balanceQty, finalQty, e.qty, e._id);
                                                      tempArr[productIndex].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                      const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                      tempSessions[index].price = totalItemPrice;
                                                      const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                      tempSessions[index].total_price = tempSessions[index].price;
                                                    }

                                                    tempSessions[index].products =
                                                      [...tempArr];
                                                    setSessionInvoices([
                                                      ...tempSessions,
                                                    ]);
                                                  }}
                                                >
                                                  <i
                                                    class="fa fa-plus-square"
                                                    style={{
                                                      color: "#34556F",
                                                    }}
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                              </div>
                                            </td>
                                          )
                                        }
                                        <td style={{ textAlign: "center" }}>
                                          {(() => {
                                              if (isConversion && !e.isExtra) {
                                                let tempSessions = structuredClone(sessionInvoices); // Deep copy
                                                let tempArr = tempSessions[index]?.products || [];
                                                console.log('finalQty', finalQty)
                                                return finalQty < 0
                                                    ? Math.abs(finalQty + tempArr[productIndex]?.used + tempArr[productIndex]?.converted)
                                                    : Math.abs(finalQty - tempArr[productIndex]?.used - tempArr[productIndex]?.converted);
                                                  } else {
                                                      return finalQty < 0
                                                        ? Math.abs(finalQty + product.used)
                                                      : Math.abs(finalQty - product.used);
                                                    }
                                          })()}
                                        </td>
                                          <td style={{ textAlign: "center" }}>
                                          {(() => {
                                            let tempSessions = structuredClone(sessionInvoices); // Deep copy
                                            let tempArr = tempSessions[index]?.products || [];

                                            if (!isConversion || (isConversion && !e._id)) {
                                              return tempArr[productIndex]?.new_price > 0
                                                ? tempArr[productIndex]?.new_price
                                                : tempArr[productIndex]?.new_price ?? tempArr[productIndex]?.total_price;
                                            } else {
                                              return (
                                                <input
                                                  type="number"
                                                  style={{
                                                    width: "80px",
                                                    background: "transparent",
                                                    justifyContent: "center",
                                                    borderRadius: "5px",
                                                  }}
                                                  value={
                                                    tempArr[productIndex]?.new_price > 0
                                                      ? tempArr[productIndex]?.new_price
                                                      : tempArr[productIndex]?.new_price ?? tempArr[productIndex]?.total_price
                                                  }
                                                  onChange={(event) => {
                                                    if (!isEdit) return;

                                                    let tempSessions = structuredClone(sessionInvoices); // Deep copy
                                                    let tempArr = tempSessions[index]?.products || [];

                                                    if (!tempArr[productIndex]) return; // Ensure product exists before updating

                                                    tempArr[productIndex].new_price = event?.target?.value;

                                                    let totalPackagePrice = 0;
                                                    [...tempSessions[index].treatments, ...tempSessions[index].products].map((item) => {
                                                      totalPackagePrice += Number(item.new_price ? item.new_price : item.total_price);
                                                    })

                                                    tempSessions[index].total_price = totalPackagePrice;

                                                    // if (isConversion) {
                                                    //   const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                    //   tempSessions[index].price = totalItemPrice;
                                                    //   const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                    //   tempSessions[index].total_price = totalPackagePrice;
                                                    // }

                                                    tempSessions[index].products = [...tempArr];
                                                    setSessionInvoices([...tempSessions]);
                                                  }}
                                                />
                                              );
                                            }
                                          })()}
                                        </td>

                                      </tr>
                                    </>
                                  );
                                })}
                              {e.treatments?.length > 0 &&
                                e?.treatments?.map((product, productIndex) => {
                                  const finalQty =
                                    product.qty * (e.qty ? e.qty : 1);

                                  return (
                                    <>
                                      <tr>
                                        <td style={{ textAlign: "center" }}>
                                          Treatment
                                        </td>
                                        {/* <td>
																						{
																							product.name
																						}
																					</td> */}
                                        <td style={{ textAlign: "center" }}>
                                          {product.name}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {Math.abs(finalQty)}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <div
                                            className="d-flex"
                                            style={{ justifyContent: "center" }}
                                          >
                                            <div
                                              className="pointer"
                                              onClick={() => {
                                                if (!isEdit) return;
                                                let tempSessions = structuredClone(sessionInvoices); // Ensure deep copy
                                                let tempArr = tempSessions[index]?.treatments;
                                                if (
                                                  tempArr[productIndex].used ==
                                                  0
                                                )
                                                  return;
                                                tempArr[productIndex].used =
                                                  tempArr[productIndex].used -
                                                  1;

                                                // let balanceQty = (finalQty < 0 ? product.converted * -1 : product.converted);
                                                // if (e.isExtra) {
                                                //   balanceQty = (finalQty < 0
                                                //     ? finalQty + product.used
                                                //     : finalQty - product.used);
                                                // }

                                                // const product_price = getTotalPrice(product, e);
                                                // const calculatedValue = calculateBalancePrice(product_price, balanceQty, finalQty, e.qty, e._id);

                                                // tempArr[
                                                //   productIndex
                                                // ].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                
                                                // if (isConversion) {
                                                //   const t_price = calculateTotalPrice(product, tempSessions[index])
                                                //   const calculatedValue = calculateBalancePrice(t_price, balanceQty, finalQty, e.qty, e._id);
                                                //   tempArr[productIndex].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                //   const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                //   tempSessions[index].price = totalItemPrice;

                                                //   const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                //   tempSessions[index].total_price = tempSessions[index].price;
                                                // }

                                                tempSessions[index].treatments =
                                                  [...tempArr];
                                                setSessionInvoices([
                                                  ...tempSessions,
                                                ]);
                                              }}
                                            >
                                              <i
                                                class="fa fa-minus-square"
                                                style={{
                                                  color: "#34556F",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <span className="mx-2">
                                              {product.used}
                                            </span>
                                            <div
                                              className="pointer"
                                              onClick={() => {
                                                if (!isEdit) return;
                                                let tempSessions = structuredClone(sessionInvoices); // Ensure deep copy
                                                let tempArr = tempSessions[index]?.treatments;

                                                if (
                                                  (tempArr[productIndex].converted + tempArr[productIndex].used) ==
                                                  Math.abs(finalQty)
                                                )
                                                  return;
                                                tempArr[productIndex].used =
                                                  tempArr[productIndex].used +
                                                  1;

                                                // let balanceQty = (finalQty < 0 ? product.converted * -1 : product.converted);
                                                // if (e.isExtra) {
                                                //   balanceQty = (finalQty < 0
                                                //     ? finalQty + product.used
                                                //     : finalQty - product.used);
                                                // }

                                                // const product_price = getTotalPrice(product, e);
                                                // const calculatedValue = calculateBalancePrice(product_price, balanceQty, finalQty, e.qty, e._id);

                                                // tempArr[
                                                //   productIndex
                                                // ].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);

                                                
                                                // if (isConversion) {
                                                //   const t_price = calculateTotalPrice(product, tempSessions[index])
                                                //   const calculatedValue = calculateBalancePrice(t_price, balanceQty, finalQty, e.qty, e._id);
                                                //   tempArr[productIndex].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                //   const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                //   tempSessions[index].price = totalItemPrice;
                                                //   const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                //   tempSessions[index].total_price = tempSessions[index].price;
                                                // }

                                                tempSessions[index].treatments =
                                                  [...tempArr];
                                                setSessionInvoices([
                                                  ...tempSessions,
                                                ]);
                                              }}
                                            >
                                              <i
                                                class="fa fa-plus-square"
                                                style={{
                                                  color: "#34556F",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </div>
                                        </td>
                                        {
                                          (isConversion && !e.isExtra) && (
                                            <td style={{ textAlign: "center" }}>
                                              <div
                                                className="d-flex"
                                                style={{ justifyContent: "center" }}
                                              >
                                                <div
                                                  className="pointer"
                                                  onClick={() => {
                                                    if (!isEdit) return;
                                                    let tempSessions = structuredClone(sessionInvoices); // Ensure deep copy
                                                    let tempArr = tempSessions[index]?.treatments;
                                                    if (
                                                      tempArr[productIndex].converted ==
                                                      0
                                                    )
                                                      return;
                                                    tempArr[productIndex].converted =
                                                      tempArr[productIndex].converted -
                                                      1;

                                                    let balanceQty = (finalQty < 0 ? tempArr[productIndex].converted * -1 : tempArr[productIndex].converted);
                                                    if (e.isExtra) {
                                                      balanceQty = (finalQty < 0
                                                        ? finalQty + tempArr[productIndex].converted + tempArr[productIndex].used
                                                        : finalQty - tempArr[productIndex].converted + tempArr[productIndex].used);
                                                    }

                                                    const product_price = getTotalPrice(tempArr[productIndex], e);
                                                    const calculatedValue = calculateBalancePrice(product_price, balanceQty, finalQty, e.qty, e._id);

                                                    tempArr[
                                                      productIndex
                                                    ].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                    
                                                    if (isConversion && (Math.abs(product.ori_total_price !== 0))) {
                                                      const t_price = calculateTotalPrice(product, tempSessions[index])
                                                      const calculatedValue = calculateBalancePrice(t_price, balanceQty, finalQty, e.qty, e._id);
                                                      tempArr[productIndex].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                      const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                      tempSessions[index].price = totalItemPrice;
                                                      const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                      tempSessions[index].total_price = tempSessions[index].price;
                                                    }

                                                    tempSessions[index].treatments =
                                                      [...tempArr];
                                                    setSessionInvoices([
                                                      ...tempSessions,
                                                    ]);
                                                  }}
                                                >
                                                  <i
                                                    class="fa fa-minus-square"
                                                    style={{
                                                      color: "#34556F",
                                                    }}
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                                <span className="mx-2">
                                                  {product.converted}
                                                </span>
                                                <div
                                                  className="pointer"
                                                  onClick={() => {
                                                    if (!isEdit) return;
                                                    let tempSessions = structuredClone(sessionInvoices); // Ensure deep copy
                                                    let tempArr = tempSessions[index]?.treatments;

                                                    if (
                                                      (tempArr[productIndex].used + tempArr[productIndex].converted) ==
                                                      Math.abs(finalQty)
                                                    )
                                                      return;
                                                    tempArr[productIndex].converted =
                                                      tempArr[productIndex].converted +
                                                      1;

                                                    let balanceQty = (finalQty < 0 ? tempArr[productIndex].converted * -1 : tempArr[productIndex].converted);
                                                    if (e.isExtra) {
                                                      balanceQty = (finalQty < 0
                                                        ? finalQty + tempArr[productIndex].converted + tempArr[productIndex].used
                                                        : finalQty - tempArr[productIndex].converted + tempArr[productIndex].used);
                                                    }

                                                    const product_price = getTotalPrice(tempArr[productIndex], e);
                                                    const calculatedValue = calculateBalancePrice(product_price, balanceQty, finalQty, e.qty, e._id);

                                                    tempArr[
                                                      productIndex
                                                    ].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);

                                                    
                                                    if (isConversion && (Math.abs(product.ori_total_price !== 0))) {
                                                      const t_price = calculateTotalPrice(product, tempSessions[index])
                                                      const calculatedValue = calculateBalancePrice(t_price, balanceQty, finalQty, e.qty, e._id);
                                                      tempArr[productIndex].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                      const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                      tempSessions[index].price = totalItemPrice;
                                                      const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                      tempSessions[index].total_price = tempSessions[index].price;
                                                    }

                                                    tempSessions[index].treatments =
                                                      [...tempArr];
                                                    setSessionInvoices([
                                                      ...tempSessions,
                                                    ]);
                                                  }}
                                                >
                                                  <i
                                                    class="fa fa-plus-square"
                                                    style={{
                                                      color: "#34556F",
                                                    }}
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                              </div>
                                            </td>
                                          )
                                        }
                                        <td style={{ textAlign: "center" }}>
                             {(() => {
                                  if (isConversion && !e.isExtra) {
                                    let tempSessions = structuredClone(sessionInvoices); // Deep copy
                                    let tempArr = tempSessions[index]?.treatments || [];
                                    return finalQty < 0
                                        ? Math.abs(finalQty + tempArr[productIndex]?.used + tempArr[productIndex]?.converted)
                                        : Math.abs(finalQty - tempArr[productIndex]?.used - tempArr[productIndex]?.converted);
                                      } else {
                                          return finalQty < 0
                                            ? Math.abs(finalQty + product.used)
                                          : Math.abs(finalQty - product.used);
                                        }
                               })()}
                                        </td>
                                        
                                       <td style={{ textAlign: "center" }}>
  {(() => {
    let tempSessions = structuredClone(sessionInvoices); // Deep copy
    let tempArr = tempSessions[index]?.treatments || [];

    if (!isConversion || (isConversion && !e._id)) {
      return tempArr[productIndex]?.new_price > 0
        ? tempArr[productIndex]?.new_price
        : tempArr[productIndex]?.new_price ?? tempArr[productIndex]?.total_price;
    } else {
      return (
        <input
          type="number"
          style={{
            width: "80px",
            background: "transparent",
            justifyContent: "center",
            borderRadius: "5px",
          }}
          value={
            tempArr[productIndex]?.new_price > 0
              ? tempArr[productIndex]?.new_price
              : tempArr[productIndex]?.new_price ?? tempArr[productIndex]?.total_price
          }
          onChange={(event) => {
            if (!isEdit) return;

            let tempSessions = structuredClone(sessionInvoices); // Deep copy
            let tempArr = tempSessions[index]?.treatments || [];

            if (!tempArr[productIndex]) return;

            tempArr[productIndex].new_price = event?.target?.value;

            let totalPackagePrice = 0;
            [...tempSessions[index].treatments, ...tempSessions[index].products].map((item) => {
              totalPackagePrice += Number(item.new_price ? item.new_price : item.total_price);
            })

            tempSessions[index].total_price = totalPackagePrice;

            // if (isConversion) {
            //   const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
            //   tempSessions[index].price = totalItemPrice;
            //   const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
            //   tempSessions[index].total_price = totalPackagePrice;
            // }

            tempSessions[index].treatments = [...tempArr];
            setSessionInvoices([...tempSessions]);
          }}
        />
      );
    }
  })()}
</td>

                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              );
            })}
        </tbody>
      </Table>
      <Modal
        centered
        show={openModal}
        onHide={() => {
          setItemToDel(null);
          setOpenModal(!openModal);
        }}
      >
        <Modal.Body className="font-18">
          Confirm Item Deletion
          <div className="align-self-end m-3 d-flex">
            <Button
              className="me-3 delete-no ms-auto"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              No
            </Button>
            <Button
              className="delete-yes"
              onClick={() => {
                if (sessionInvoices?.length == 1) {
                  setSubTotal(0);
                  setPromo(0);
                  setTotal(0);
                  setTax(null);
                  setUsePoint(0);
                }
                let tempArr = [...sessionInvoices];
                tempArr = tempArr.filter((k, idx) => idx !== itemToDel);
                setSessionInvoices([...tempArr]);
                setOpenModal(false);
              }}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
