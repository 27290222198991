import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { Modal, Button } from "react-bootstrap";
import PieCharts from "./PieCharts";
import DateInput from "../../SharedComponents/DateInput";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SelectComponent from "../../SharedComponents/SelectComponent";
import { toastify } from "../../../helperFunctions/toastify";
import {
  getMonthFromRange,
  getShortMonth,
  formatDate,
  filterTypes,
  getFilterDates,
} from "../../../helperFunctions/utils";
import { thousandAndDecimalFormat } from "../../../utils/common";

import {
  getComparisonOfPatientsAge,
  getComparisonOfPatientsNationality,
  getComparisonOfPatientsMaterialStatus,
  getComparisonOfPatientsOccupation,
  getComparisonOfPatientsAddress,
  getComparisonOfPatientsGender,
  getComparisonOfPatientsEthnicity,
  getDemographicDetail,
} from "../../../apis/dashboardReports";

import DashboardTable from "./NewDashboardTable";

const DemographicReport = () => {
  const [ageComparison, setAgeComparison] = useState([]);
  const [nationalityComparison, setNationalityComparison] = useState([]);
  const [maritalStatusComparison, setMaritalStatusComparison] = useState([]);
  const [occupationComparison, setOccupationComparison] = useState([]);
  const [addressComparison, setAddressComparison] = useState([]);
  const [genderComparison, setGenderComparison] = useState([]);
  const [ethnicityComparison, setEthnicityComparison] = useState([]);
  const [rangeMonth, setRangeMonth] = useState({
    r1: [],
    r2: [],
  });

  const [show, setShow] = useState(false);
  const [selectedDetailData, setSelectedDetailData] = useState({
    typeName: "",
    type: "",
    tableName: "R",
  });
  const [detailDataResp, setDetailDataResp] = useState([]);
  const [isLoadingDataResp, setIsLoadingDataResp] = useState(false);

  const [range, setRange] = useState({
    start: new Date(new Date().getFullYear(), 0, 1),
    end: new Date(),
  });

  const [filterType, setFilterType] = useState("last6Months");
  const [filterData, setFilterData] = useState({
    date_range: "M",
    ...getFilterDates(filterType),
    type: "R",
  });
  const [inputFilterData, setInputFilterData] = useState({
    date_range: "M",
    ...getFilterDates(filterType),
    type: "R",
  });

  useEffect(() => {
    const firstRange = getMonthFromRange(
      filterData.r1_start_date,
      filterData.r1_end_date
    );
    const secondRange = getMonthFromRange(
      filterData.r2_start_date,
      filterData.r2_end_date
    );

    setRangeMonth({
      r1: firstRange,
      r2: secondRange,
    });
  }, [filterData]);

  const getComparisonOfAge = async () => {
    const response = await getComparisonOfPatientsAge(filterData);

    if (response.status !== "Fail") {
      setAgeComparison(response?.data?.data);
    } else {
      toastify(response?.data?.message, "error");
    }
  };
  const getComparisonOfNationality = async () => {
    const response = await getComparisonOfPatientsNationality(filterData);

    if (response.status !== "Fail") {
      setNationalityComparison(response?.data?.data);
    } else {
      toastify(response?.data?.message, "error");
    }
  };

  const getComparisonOfMaterialStatus = async () => {
    const response = await getComparisonOfPatientsMaterialStatus(filterData);

    if (response.status !== "Fail") {
      setMaritalStatusComparison(response?.data?.data);
    } else {
      toastify(response?.data?.message, "error");
    }
  };

  const getComparisonOfOccupation = async () => {
    const response = await getComparisonOfPatientsOccupation(filterData);

    if (response.status !== "Fail") {
      setOccupationComparison(response?.data?.data);
    } else {
      toastify(response?.data?.message, "error");
    }
  };

  const getComparisonOfAddress = async () => {
    const response = await getComparisonOfPatientsAddress(filterData);

    if (response.status !== "Fail") {
      setAddressComparison(response?.data?.data);
    } else {
      toastify(response?.data?.message, "error");
    }
  };

  const getComparisonOfGender = async () => {
    const response = await getComparisonOfPatientsGender(filterData);

    if (response.status !== "Fail") {
      setGenderComparison(response?.data?.data.slice(0, 2));
    } else {
      toastify(response?.data?.message, "error");
    }
  };
  const getComparisonOfEthnicity = async () => {
    const response = await getComparisonOfPatientsEthnicity();
    if (response.status !== "Fail") {
      setEthnicityComparison(response?.data?.data);
    } else {
      toastify(response?.data?.message, "error");
    }
  };

  useEffect(() => {
    getComparisonOfAge();
    getComparisonOfNationality();
    getComparisonOfMaterialStatus();
    getComparisonOfOccupation();
    getComparisonOfAddress();
    getComparisonOfGender();
    // getComparisonOfEthnicity();
  }, [filterData]);
  const getDemographicDetailFunc = async () => {
    setDetailDataResp([]);
    setIsLoadingDataResp(true);
    const { type, typeName, ...rest } = selectedDetailData;
    const response = await getDemographicDetail({
      ...filterData,
      ...rest,
      date_range: selectedDetailData?.type,
      type: selectedDetailData?.tableName,
    });

    setIsLoadingDataResp(false);
    if (response.status !== "Fail") {
      setDetailDataResp(response?.data?.data);
    } else {
      toastify(response?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (selectedDetailData.type !== "" && selectedDetailData.typeName !== "") {
      getDemographicDetailFunc();
    }
  }, [selectedDetailData]);

  const sumTotalCount = (values) => {
    let total = 0;

    rangeMonth?.r1.map((monthValue) => {
      const yearValue = values.find(
        (item) =>
          item?.year === monthValue?.year &&
          item?.month === monthValue?.month + 1
      );
      total += yearValue?.count?.length ?? 0;

      return false;
    });

    return total;
  };

  const sumTotalCount2 = (values) => {
    let total = 0;
    rangeMonth?.r2.map((monthValue) => {
      const yearValue = values.find(
        (item) =>
          item?.year === monthValue?.year &&
          item?.month === monthValue?.month + 1
      );
      total += yearValue?.count?.length ?? 0;

      return false;
    });

    return total;
  };

  const ageTotal = {
    yearCurrent: Array(6).fill(0),
    yearLast: Array(6).fill(0),
  };

  ageComparison?.forEach((entry) => {
    const periodIndex = parseInt(entry.period) / 10 - 2;

    entry.yearLast.forEach((monthData) => {
      ageTotal.yearLast[periodIndex] += monthData.count?.length;
    });

    entry.yearCurrent.forEach((monthData) => {
      ageTotal.yearCurrent[periodIndex] += monthData.count?.length;
    });
  });

  const totalNationality = {
    labels: nationalityComparison?.map((nationality) => nationality.period),
    yearLastCounts: nationalityComparison?.map((nationality) =>
      sumTotalCount(nationality?.yearLast)
    ),

    yearCurrentCounts: nationalityComparison?.map((nationality) =>
      sumTotalCount2(nationality?.yearCurrent)
    ),
  };

  const totalMaritalStatus = {
    labels: maritalStatusComparison?.map((status) => status.period),
    yearLastCounts: maritalStatusComparison?.map((status) =>
      sumTotalCount(status?.yearLast)
    ),
    yearCurrentCounts: maritalStatusComparison?.map((status) =>
      sumTotalCount2(status?.yearCurrent)
    ),
  };
  const totalOccupation = {
    labels: occupationComparison?.map((item) => item.period),
    yearLastCounts: occupationComparison?.map((item) =>
      sumTotalCount(item?.yearLast)
    ),
    yearCurrentCounts: occupationComparison?.map((item) =>
      sumTotalCount2(item?.yearCurrent)
    ),
  };
  const totalAddress = {
    labels: addressComparison?.map((item) => item.period),
    yearLastCounts: addressComparison?.map((item) =>
      sumTotalCount(item?.yearLast)
    ),
    yearCurrentCounts: addressComparison?.map((item) =>
      sumTotalCount2(item?.yearCurrent)
    ),
  };
  const totalGender = {
    labels: genderComparison?.map((item) => item.period),
    yearLastCounts: genderComparison?.map((item) =>
      sumTotalCount(item?.yearLast)
    ),
    yearCurrentCounts: genderComparison?.map((item) =>
      sumTotalCount2(item?.yearCurrent)
    ),
  };

  const [Ageoptions] = useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["20s", "30s", "40s", "50s", "60s", "70s"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    colors: [
      "#4492CDCC",
      "#F16F33CC",
      "#9B9B9BCC",
      "#FFB628CC",
      "#2D69B9CC",
      "#65A345CC",
    ],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.globals.labels[opts.seriesIndex];
      },
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "12px",
      },
    },

    plotOptions: {
      pie: {
        customScale: 1, // Adjust scale for better appearance
      },
    },
  });
  const [Nationalityoptions, setNationalityoptions] = useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Singapore", "Pakistan", "Indonesia", "Vietnam", "Malaysia"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    colors: ["#4492CDCC", "#F16F33CC", "#9B9B9BCC", "#FFB628CC", "#2D69B9CC"],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.globals.labels[opts.seriesIndex];
      },
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "12px",
      },
    },

    plotOptions: {
      pie: {
        customScale: 1, // Adjust scale for better appearance
      },
    },
  });

  const [MaritalStatusoptions] = useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Married", "Single"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    colors: ["#4492CDCC", "#F16F33CC"],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.globals.labels[opts.seriesIndex];
      },
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "12px",
      },
    },

    plotOptions: {
      pie: {
        customScale: 1, // Adjust scale for better appearance
      },
    },
  });
  const [GenderOptions] = useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Female", "Male"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    colors: ["#4492CDCC", "#F16F33CC"],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.globals.labels[opts.seriesIndex];
      },
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "12px",
      },
    },

    plotOptions: {
      pie: {
        customScale: 1, // Adjust scale for better appearance
      },
    },
  });

  const [occupationoptions, setOccupationoptions] = useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: totalOccupation.labels
      ? totalOccupation.labels
      : ["Architect", "Technician", "Consultant", "Doctor", "Plumber"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    colors: ["#4492CDCC", "#F16F33CC", "#9B9B9BCC", "#FFB628CC", "#2D69B9CC"],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.globals.labels[opts.seriesIndex];
      },
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "12px",
      },
    },

    plotOptions: {
      pie: {
        customScale: 1, // Adjust scale for better appearance
      },
    },
  });

  const [addressOptions, setAddressOptions] = useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: totalAddress.labels ? totalAddress.labels : [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    colors: ["#4492CDCC", "#F16F33CC", "#9B9B9BCC", "#FFB628CC", "#2D69B9CC"],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.globals.labels[opts.seriesIndex];
      },
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "12px",
      },
    },

    plotOptions: {
      pie: {
        customScale: 1, // Adjust scale for better appearance
      },
    },
  });
  const [export_PDF, setexportPDF] = useState(false);

  const handleExportPdf = () => {
    setexportPDF(true);

    setTimeout(() => {
      exportPDF();
    }, 1000);
  };

  useEffect(() => {
    setNationalityoptions({
      ...Nationalityoptions,
      labels: nationalityComparison?.map((c) => c.period),
    });
  }, [nationalityComparison]);

  useEffect(() => {
    setOccupationoptions({
      ...occupationoptions,
      labels: occupationComparison?.map((c) => c.period),
    });
  }, [occupationComparison]);

  useEffect(() => {
    setAddressOptions({
      ...addressComparison,
      labels: addressComparison?.map((c) => c.period),
    });
  }, [addressComparison]);

  const getMonthValue = (monthValues, month) => {
    const monthValue = monthValues.find((m) => m.month === month);
    if (!monthValue) return 0;

    if (!monthValue.count) return 0;

    return monthValue.count;
  };

  const { r1_start_date, r1_end_date, r2_start_date, r2_end_date } =
    inputFilterData;

  function areRangesEqualMonthCount(
    range1StartDate,
    range1EndDate,
    range2StartDate,
    range2EndDate
  ) {
    // Convert string dates to Date objects
    const r1Start = new Date(range1StartDate);
    const r1End = new Date(range1EndDate);
    const r2Start = new Date(range2StartDate);
    const r2End = new Date(range2EndDate);

    // Calculate the month count for both ranges
    const r1MonthCount =
      (r1End.getFullYear() - r1Start.getFullYear()) * 12 +
      (r1End.getMonth() - r1Start.getMonth());
    const r2MonthCount =
      (r2End.getFullYear() - r2Start.getFullYear()) * 12 +
      (r2End.getMonth() - r2Start.getMonth());

    // Check if both ranges have the same number of months
    return r1MonthCount === r2MonthCount;
  }

  const monthSame = areRangesEqualMonthCount(
    r1_start_date,
    r1_end_date,
    r2_start_date,
    r2_end_date
  );

  const handleApplyFilter = () => {
    if (!monthSame) {
      toastify("Both of range need to be match", "error");
    } else {
      setFilterData({
        ...inputFilterData,
      });
    }
  };

  useEffect(() => {
    setInputFilterData((prevFilterData) => ({
      ...prevFilterData,
      ...getFilterDates(filterType),
    }));
  }, [filterType]);
  const exportPDF = () => {
    // have a specific div with an id 'exportArea'
    // that wraps around the elements you want to export.
    const input = document.getElementById("exportArea");
    html2canvas(input, {
      scale: 2,
    }) // scale: 2 to increase the resolution of the screenshot
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.7);

        // Initialize jsPDF
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm", // Set the unit to millimeters
          format: "a4", // Set the page format to A4
        });

        // Calculate the number of pages needed for the screenshot.
        const imgWidth = 180; // A4 size page width in mm
        const pageHeight = 297; // A4 size page height in mm
        const marginTop = 10; // Top margin
        const marginBottom = 10; // Bottom margin
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight + marginTop;

        let position = marginTop;

        // Add image to the first page.
        pdf.addImage(
          imgData,
          "JPEG",
          (pdf.internal.pageSize.width - imgWidth) / 2,
          position,
          imgWidth,
          imgHeight - 37
        );
        heightLeft -= pageHeight;

        // Add new pages if the screenshot spans over multiple pages.
        while (heightLeft >= 0) {
          position = marginTop - pageHeight - marginBottom;
          pdf.addPage();
          pdf.addImage(
            imgData,
            "JPEG",
            (pdf.internal.pageSize.width - imgWidth) / 2,
            position,
            imgWidth,
            imgHeight - 17
          );
          heightLeft -= pageHeight;
        }

        // Save the PDF
        const date = new Date();

        pdf.save(`report_${date}.pdf`);
        setexportPDF(false);
      });
  };

  return (
    <>
      <div className="d-flex flex-wrap gap-3 w-100 px-4 py-4">
        <div className="drop-down-box col-2 me-2">
          <div className="font-400-20 mb-1">Date Presets:</div>
          <SelectComponent
            options={filterTypes}
            selectedValue={filterType}
            singleSelect
            searchEnabled
            nullDisabled
            onChange={(e) => {
              setFilterType(e.value);
              setInputFilterData({ ...inputFilterData, date_range: e.value });
            }}
          />
        </div>
        <div className="range-1">
          <div className="font-400-20 mb-2">Range 1:</div>
          <div className="date-selector-box">
            <DateInput
              value={inputFilterData.r1_start_date}
              placeholder="Start Date"
              onChange={(e) => {
                const parsedDate = new Date(e);
                const formattedDate = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setInputFilterData({
                  ...inputFilterData,
                  r1_start_date: formattedDate,
                });
              }}
            />
            -
            <DateInput
              placeholder="End Date"
              value={inputFilterData.r1_end_date}
              onChange={(e) => {
                const parsedDate = new Date(e);
                const formattedDate = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setInputFilterData({
                  ...inputFilterData,
                  r1_end_date: formattedDate,
                });
              }}
            />
          </div>
        </div>
        <div className="range-2">
          <div className="font-400-20 mb-2">Range 2:</div>
          <div className="date-selector-box">
            <DateInput
              value={inputFilterData.r2_start_date}
              placeholder="Start Date"
              onChange={(e) => {
                const parsedDate = new Date(e);
                const formattedDate = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setInputFilterData({
                  ...inputFilterData,
                  r2_start_date: formattedDate,
                });
              }}
            />
            -
            <DateInput
              placeholder="End Date"
              value={inputFilterData.r2_end_date}
              onChange={(e) => {
                const parsedDate = new Date(e);
                const formattedDate = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setInputFilterData({
                  ...inputFilterData,
                  r2_end_date: formattedDate,
                });
              }}
            />
          </div>
        </div>
        <Button
          className="addItem align-self-end"
          style={{ height: "47px" }}
          onClick={() => handleApplyFilter()}
        >
          Apply
        </Button>
        <Button
          className="addItem align-self-end"
          style={{ height: "47px" }}
          onClick={() => {
            handleExportPdf();
          }}
        >
          Export
        </Button>
      </div>

      <div id="exportArea">
        {/* Age Section */}
        <div className="age-block">
          <div className="demographic-page-block">
            <h1 className="block-heading">Age</h1>
            <div className="graphs-block">
              <div className="graph-item pie-graph">
                <h2 className="year-title">
                  {formatDate(filterData.r1_start_date)} -{" "}
                  {formatDate(filterData.r1_end_date)}
                </h2>
                <PieCharts series={ageTotal?.yearLast} options={Ageoptions} />
              </div>
              <div className="graph-item pie-graph">
                <h2 className="year-title">
                  {formatDate(filterData.r2_start_date)} -{" "}
                  {formatDate(filterData.r2_end_date)}
                </h2>
                <PieCharts
                  series={ageTotal?.yearCurrent}
                  options={Ageoptions}
                />
              </div>
            </div>
          </div>

          <DashboardTable
            name="AGE"
            filterData={filterData}
            rangeMonth={rangeMonth}
            values={ageComparison?.map((item) => ({
              period: item.period,
              r1year: item.yearLast,
              r2year: item.yearCurrent,
            }))}
            firstField="year"
            secondField="year"
            setShow={setShow}
            // setSelectedDetailData={setSelectedDetailData}
            setDetailDataResp={setDetailDataResp}
            export_pdf={export_PDF}
            showPercentage={true}
            showTotal={true}
          />
        </div>
        <div className="age-block">
          <div className="demographic-page-block">
            <h1 className="block-heading">Nationality Top 5</h1>

            <div className="graphs-block">
              <div className="graph-item pie-graph">
                <h2 className="year-title">
                  {formatDate(filterData.r1_start_date)} -{" "}
                  {formatDate(filterData.r1_end_date)}
                </h2>
                <PieCharts
                  series={totalNationality?.yearLastCounts}
                  options={Nationalityoptions}
                />
              </div>
              <div className="graph-item pie-graph">
                <h2 className="year-title">
                  {formatDate(filterData.r2_start_date)} -{" "}
                  {formatDate(filterData.r2_end_date)}
                </h2>
                <PieCharts
                  series={totalNationality?.yearCurrentCounts}
                  options={Nationalityoptions}
                />
              </div>
            </div>
          </div>

          <DashboardTable
            name="NAT"
            filterData={filterData}
            rangeMonth={rangeMonth}
            values={nationalityComparison?.map((item) => ({
              period: item.period,
              r1year: item.yearLast,
              r2year: item.yearCurrent,
            }))}
            firstField="year"
            secondField="year"
            setShow={setShow}
            //setSelectedDetailData={setSelectedDetailData}
            setDetailDataResp={setDetailDataResp}
            showPercentage={true}
            showTotal={true}
          />
        </div>
        <div className="age-block">
          <div className="demographic-page-block">
            <h1 className="block-heading">Marital Status</h1>
            <div className="graphs-block">
              <div className="graph-item pie-graph">
                <h2 className="year-title">
                  {formatDate(filterData.r1_start_date)} -{" "}
                  {formatDate(filterData.r1_end_date)}
                </h2>
                <PieCharts
                  series={totalMaritalStatus.yearLastCounts}
                  options={MaritalStatusoptions}
                />
              </div>
              <div className="graph-item pie-graph">
                <h2 className="year-title">
                  {formatDate(filterData.r2_start_date)} -{" "}
                  {formatDate(filterData.r2_end_date)}
                </h2>
                <PieCharts
                  series={totalMaritalStatus.yearCurrentCounts}
                  options={MaritalStatusoptions}
                />
              </div>
            </div>
          </div>

          <DashboardTable
            name="M_S"
            filterData={filterData}
            rangeMonth={rangeMonth}
            values={maritalStatusComparison?.map((item) => ({
              period: item.period,
              r1year: item.yearLast,
              r2year: item.yearCurrent,
            }))}
            firstField="year"
            secondField="year"
            setShow={setShow}
            // setSelectedDetailData={setSelectedDetailData}
            setDetailDataResp={setDetailDataResp}
            showPercentage={true}
            showTotal={true}
          />
        </div>
        <div className="age-block">
          <div className="demographic-page-block">
            <h1 className="block-heading">Occupation Top 5</h1>
            <div className="graphs-block">
              <div className="graph-item pie-graph">
                <h2 className="year-title">
                  {formatDate(filterData.r1_start_date)} -{" "}
                  {formatDate(filterData.r1_end_date)}
                </h2>
                <PieCharts
                  series={totalOccupation.yearLastCounts}
                  options={occupationoptions}
                />
              </div>
              <div className="graph-item pie-graph">
                <h2 className="year-title">
                  {formatDate(filterData.r2_start_date)} -{" "}
                  {formatDate(filterData.r2_end_date)}
                </h2>
                <PieCharts
                  series={totalOccupation.yearCurrentCounts}
                  options={occupationoptions}
                />
              </div>
            </div>
          </div>

          <DashboardTable
            name="OCC"
            filterData={filterData}
            rangeMonth={rangeMonth}
            values={occupationComparison?.map((item) => ({
              period: item.period,
              r1year: item.yearLast,
              r2year: item.yearCurrent,
            }))}
            firstField="year"
            secondField="year"
            setShow={setShow}
            //setSelectedDetailData={setSelectedDetailData}
            setDetailDataResp={setDetailDataResp}
            showPercentage={true}
            showTotal={true}
          />
        </div>
        <div className="age-block">
          <div className="demographic-page-block">
            <h1 className="block-heading">Address</h1>
            <div className="graphs-block">
              <div className="graph-item pie-graph">
                <h2 className="year-title">
                  {formatDate(filterData.r1_start_date)} -{" "}
                  {formatDate(filterData.r1_end_date)}
                </h2>
                <PieCharts
                  series={totalAddress.yearLastCounts}
                  options={addressOptions}
                />
              </div>
              <div className="graph-item pie-graph">
                <h2 className="year-title">
                  {formatDate(filterData.r2_start_date)} -{" "}
                  {formatDate(filterData.r2_end_date)}
                </h2>
                <PieCharts
                  series={totalAddress.yearCurrentCounts}
                  options={addressOptions}
                />
              </div>
            </div>
          </div>

          <DashboardTable
            name="ADD"
            filterData={filterData}
            rangeMonth={rangeMonth}
            values={addressComparison?.map((item) => ({
              period: item.period,
              r1year: item.yearLast,
              r2year: item.yearCurrent,
            }))}
            firstField="year"
            secondField="year"
            setShow={setShow}
            //setSelectedDetailData={setSelectedDetailData}
            setDetailDataResp={setDetailDataResp}
            showPercentage={true}
            showTotal={true}
          />
        </div>
        <div className="age-block">
          <div className="demographic-page-block">
            <h1 className="block-heading">Gender</h1>
            <div className="graphs-block">
              <div className="graph-item pie-graph">
                <h2 className="year-title">
                  {formatDate(filterData.r1_start_date)} -{" "}
                  {formatDate(filterData.r1_end_date)}
                </h2>
                <PieCharts
                  series={totalGender.yearLastCounts}
                  options={GenderOptions}
                />
              </div>
              <div className="graph-item pie-graph">
                <h2 className="year-title">
                  {formatDate(filterData.r2_start_date)} -{" "}
                  {formatDate(filterData.r2_end_date)}
                </h2>
                <PieCharts
                  series={totalGender.yearCurrentCounts}
                  options={GenderOptions}
                />
              </div>
            </div>
          </div>
          <DashboardTable
            name="GEN"
            filterData={filterData}
            rangeMonth={rangeMonth}
            values={genderComparison?.map((item) => ({
              period: item.period,
              r1year: item.yearLast,
              r2year: item.yearCurrent,
            }))}
            firstField="year"
            secondField="year"
            setShow={setShow}
            setDetailDataResp={setDetailDataResp}
            showPercentage={true}
            showTotal={true}
          /> 
        </div>
        <Modal
          className=""
          size="lg"
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
        >
          <Modal.Header closeButton className="pb-0" style={{ borderWidth: 0 }}>
            <div
              className="d-flex w-100 justify-content-center p-0 alert-heading"
              style={{ fontSize: "1rem" }}
            >
              {selectedDetailData.typeName.charAt(0).toUpperCase() +
                selectedDetailData.typeName.slice(1)}{" "}
              From: {filterData.r1_start_date} - {filterData.r1_end_date}
              &nbsp;&nbsp;To:&nbsp;&nbsp;
              {filterData.r2_start_date} - {filterData.r2_end_date}
            </div>
          </Modal.Header>
          <Modal.Body style={{ overflowY: "scroll", maxHeight: 400 }}>
            <div className="mode-table-block">
              <div className="table-header">
                <div className="table-col">
                  <h3 className="col-title">Date</h3>
                </div>
                <div className="table-col">
                  <h3 className="col-title">Patient Name</h3>
                </div>
                <div className="table-col">
                  <h3 className="col-title">Source/Recommend By</h3>
                </div>
                <div className="table-col">
                  <h3 className="col-title">How Did You Hear About Us</h3>
                </div>
              </div>
              {!isLoadingDataResp ? (
                <>
                  {detailDataResp?.map((item, index) => (
                    <div key={index} className="table-rows">
                      <div className="table-col">
                        <h3 className="col-title">
                          {formatDate(item.created_at)}
                        </h3>
                      </div>
                      <div className="table-col">
                        <h3 className="col-title">{item.fullName || "-"}</h3>
                      </div>
                      <div className="table-col">
                        <h3 className="col-title">
                          {item.recommendFrom || "-"}
                        </h3>
                      </div>
                      <div className="table-col">
                        <h3 className="col-title">
                          {" "}
                          {item.hearAboutUs || "-"}
                        </h3>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <span className="d-flex justify-content-center p-2">
                  <CircularProgress />
                </span>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default DemographicReport;
